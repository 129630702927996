import React, { useState } from "react";
import Sidebar from "./componentes/Sidebar.js";
import Topbar from "./componentes/Topbar.js";
import { Link } from "react-router-dom";
import axios, { formToJSON } from 'axios';
import DataTable from "react-data-table-component";

function Empresas() {

  const url2 = 'https://devms.bluehand.com.mx/backend/api/v1/api/get-all-companies';
  const [id, setId] = useState('');
  const [master] = useState(
    '$2a$12$n1ifj4zomb1OS0sn/lW8COTSd5cV7lKGsgcdbC.ZBtVnAFFGt0DEV.ie'
  );
  const [listas, setLista] = useState([]);
  const [widgets, setWidgets] = useState('');
  const [estilos, setEstilos] = useState('');

  const listaPost = async () => {
    var f = new FormData();
    f.append('master_key', master);
    var dataa = formToJSON(f);
    await axios
      .post(url2, dataa)
      .then((response) => {
        const data = response.data.data;

        setLista(data);
        //navigate("/Reporte");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  listaPost();

  const columns = [
    {
        name: 'ID',
        selector: row => row.id,
    },
    {
        name: 'Nombre Empresa',
        selector: row => row.trade_name,
    },
    {
        name: 'Cantidad de Empleados',
        selector: row => row.employees,
    },
    {
        name: 'Teléfono',
        selector: row => row.phone,
    },
];
  
  return (
    <div id="wrapper">
      <Sidebar />
      <div id="content-wrapper" className="d-flex flex-column">
        <div id="content">
          <Topbar />
          <div className="container-fluid">
            <div className="d-sm-flex align-items-center justify-content-between mb-4">
              <h1 className="h3 mb-0 text-gray-800">Empresas</h1>
              <Link to="/Tabla" className="d-sm-inline-block btn btn-sm btn-primary shadow-sm">
                <i className="fas fa-plus fa-sm text-white-50"></i> Agregar Empresa
              </Link>
            </div>

            <div className="card shadow mb-4">
              <div className="card-header py-3">
              </div>
              <div className="card-body">
                <div className="table-responsive">
                    <DataTable
                    columns={columns}
                    data={listas}
                    pagination
                />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Empresas;
