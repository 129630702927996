import React, { useState, useEffect } from 'react';
import SidebarE from '../componentes/SidebarE.js';
import { Link, useParams, useLocation, useNavigate } from 'react-router-dom';
import TopbarE from '../componentes/TopbarE.js';
import axios, {formToJSON} from 'axios';
import { Navigate } from 'react-router-dom';
import SweetAlert2 from "react-sweetalert2";
import NotFound from '../NotFound.js';

function NOM037() {

  let {id} = useParams();
  let l = localStorage;
//let usu = l.getItem("id_usuario");
    let id_cuestionario = 1;
    let usu = l.getItem("id_usuario");

  //let {estado} =useParams();
    let state = useLocation();

    const url = 'https://ms.bluehand.com.mx/backend2/api/v1/traernom';
    const url2 = "https://ms.bluehand.com.mx/backend2/api/v1/contestarnom";
  const [master] = useState(
    '$2a$12$n1ifj4zomb1OS0sn/lW8COTSd5cV7lKGsgcdbC.ZBtVnAFFGt0.ie'
  );
  const [widgets, setWidgets] = useState('');
  const [aiVisible, setAiVisible] = useState(true);
  const [terminado, setTerminado] = useState(false);  
  const estado = l.getItem("esto");
  const [isChecked, setIsChecked] = useState(false);
  const [isChecked2, setIsChecked2] = useState(false);
  const [isChecked3, setIsChecked3] = useState(false);
  const [mensaje, setMensaje] = useState(false); 
  const [array, setArray] = useState([]);
  const [swalProps, setSwalProps] = useState({});
  const [id_sec, setIdSecc] = useState('');

  const navigate = useNavigate();
  
  const peticionPost = async () => {
    var f = new FormData();
    f.append('master_key', master);
    f.append('seccion', id);
    var dataa = formToJSON(f);
    await axios
      .post(url, dataa)
      .then((response) => {
        const data = response.data.data;
        const secc = response.data.seccion;
        setWidgets(data);   
        setIdSecc(secc);
        debugger     
        //navigate("/Reporte");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if(widgets == ""){
      peticionPost();
    }
  }, []);

  const respuestas =async () => { 
    debugger
    await axios
    .post(url2, array)
    .then((response) => {
      debugger
      const r = response.result_code;
      console.log(r) ;

      showSwal();
      //navigate("/Reporte");
    });
    
  }

  const [imagenActual, setImagenActual] = React.useState(0);
	const cantidad = widgets?.length;

  function handleChange(event) {
    setIsChecked(event.target.value);
  }

  function handleChange2(event) {
    setIsChecked2(event.target.value);
  }

  function handleChange3(event) {
    setIsChecked3(event.target.value);
  }

  const pasar = async () => {
    navigate(`/Cuestionarios`);
  };

  function showSwal() {
    setSwalProps({
      show: true,
      icon: "success",
      title: "Completado",
      text: "Gracias por participar en la encuesta es muy valioso para poder ayudarte y mejorar nuestro ambiente de trabajo",
      confirmButtonText: "Salir",
      preConfirm: () => {
        pasar();
      },
    });
  }

  const arr = []

  const formRef = React.useRef(null);

	const siguienteImagen = (e) => {
   e.preventDefault();
   
   if(formRef.current.seccion.value == 1){

    if(isChecked == false && isChecked2 == false && isChecked3 == false){
      setMensaje(true);
    }

    if(formRef.current.hiddenInput2.value == 34){
      setAiVisible(false)
      setTerminado(true)
    }

    if(isChecked != false && formRef.current.check.value == 1 && formRef.current.hiddenInput2.value != 35){
      const obj = {'id_pregunta': formRef.current.hiddenInput.value,'num_pregunta': formRef.current.hiddenInput2.value, 'respuesta': formRef.current.check.value, 'seccion': formRef.current.seccion.value, 'id_usuario': usu, "id_seccion": id_sec}
      setMensaje(false);
      array.push(obj)
      setImagenActual(imagenActual === cantidad - 1 ? 0 : imagenActual + 1);
      setIsChecked(false)
      }else{
        if(isChecked != false && formRef.current.check.value == 1 && formRef.current.hiddenInput2.value == 35){
          const obj = {'id_pregunta': formRef.current.hiddenInput.value,'num_pregunta': formRef.current.hiddenInput2.value, 'respuesta': formRef.current.check.value, 'seccion': formRef.current.seccion.value, 'id_usuario': usu, "id_seccion": id_sec}
        setMensaje(false);
        array.push(obj)
        setIsChecked(false)
          respuestas();
        }
        else{
          if(isChecked2 != false && formRef.current.check2.value == 2 && formRef.current.hiddenInput2.value != 35){
            const obj = {'id_pregunta': formRef.current.hiddenInput.value,'num_pregunta': formRef.current.hiddenInput2.value, 'respuesta': formRef.current.check2.value, 'seccion': formRef.current.seccion.value, 'id_usuario': usu, "id_seccion": id_sec}
            setMensaje(false);
            array.push(obj)
            setImagenActual(imagenActual === cantidad - 1 ? 0 : imagenActual + 1);
    
            setIsChecked2(false)
          } else {

            if(isChecked2 != false && formRef.current.check2.value == 2 && formRef.current.hiddenInput2.value == 35){
              const obj = {'id_pregunta': formRef.current.hiddenInput.value,'num_pregunta': formRef.current.hiddenInput2.value, 'respuesta': formRef.current.check2.value, 'seccion': formRef.current.seccion.value, 'id_usuario': usu, "id_seccion": id_sec}
            setMensaje(false);
            array.push(obj)
              respuestas();
              setIsChecked2(false);
            }else{
              if(isChecked3 != false && formRef.current.check3.value == 3 && formRef.current.hiddenInput2.value != 35){
                const obj = {'id_pregunta': formRef.current.hiddenInput.value,'num_pregunta': formRef.current.hiddenInput2.value, 'respuesta': formRef.current.check3.value, 'seccion': formRef.current.seccion.value, 'id_usuario': usu, "id_seccion": id_sec}
                setMensaje(false);
                array.push(obj)
                setImagenActual(imagenActual === cantidad - 1 ? 0 : imagenActual + 1);
        
                setIsChecked3(false)
              }else{
                if(isChecked3 != false && formRef.current.check3.value == 3 && formRef.current.hiddenInput2.value == 35){
                  const obj = {'id_pregunta': formRef.current.hiddenInput.value,'num_pregunta': formRef.current.hiddenInput2.value, 'respuesta': formRef.current.check3.value, 'seccion': formRef.current.seccion.value, 'id_usuario': usu, "id_seccion": id_sec}
                setMensaje(false);
                array.push(obj)
                  respuestas();
                  setIsChecked3(false);
                }
              }
            }
          }
        }
      }
    }
   else{
      if(formRef.current.seccion.value == 2){
        if(isChecked == false && isChecked2 == false && isChecked3 == false){
          setMensaje(true);
        }
    
        if(formRef.current.hiddenInput2.value == 60){
          setAiVisible(false)
          setTerminado(true)
        }
    
        if(isChecked != false && formRef.current.check.value == 1 && formRef.current.hiddenInput2.value != 61){
          const obj = {'id_pregunta': formRef.current.hiddenInput.value,'num_pregunta': formRef.current.hiddenInput2.value, 'respuesta': formRef.current.check.value, 'seccion': formRef.current.seccion.value, 'id_usuario': usu, "id_seccion": id_sec}
          setMensaje(false);
          array.push(obj)
          setImagenActual(imagenActual === cantidad - 1 ? 0 : imagenActual + 1);
          setIsChecked(false)
          }else{
            if(isChecked != false && formRef.current.check.value == 1 && formRef.current.hiddenInput2.value == 61){
              const obj = {'id_pregunta': formRef.current.hiddenInput.value,'num_pregunta': formRef.current.hiddenInput2.value, 'respuesta': formRef.current.check.value, 'seccion': formRef.current.seccion.value, 'id_usuario': usu, "id_seccion": id_sec}
            setMensaje(false);
            array.push(obj)
            setIsChecked(false)
              respuestas();
            }
            else{
              if(isChecked2 != false && formRef.current.check2.value == 2 && formRef.current.hiddenInput2.value != 61){
                const obj = {'id_pregunta': formRef.current.hiddenInput.value,'num_pregunta': formRef.current.hiddenInput2.value, 'respuesta': formRef.current.check2.value, 'seccion': formRef.current.seccion.value, 'id_usuario': usu, "id_seccion": id_sec}
                setMensaje(false);
                array.push(obj)
                setImagenActual(imagenActual === cantidad - 1 ? 0 : imagenActual + 1);
        
                setIsChecked2(false)
              } else {
    
                if(isChecked2 != false && formRef.current.check2.value == 2 && formRef.current.hiddenInput2.value == 61){
                  const obj = {'id_pregunta': formRef.current.hiddenInput.value,'num_pregunta': formRef.current.hiddenInput2.value, 'respuesta': formRef.current.check2.value, 'seccion': formRef.current.seccion.value, 'id_usuario': usu, "id_seccion": id_sec}
                setMensaje(false);
                array.push(obj)
                  respuestas();
                  setIsChecked2(false);
                }else{
                  if(isChecked3 != false && formRef.current.check3.value == 3 && formRef.current.hiddenInput2.value != 61){
                    const obj = {'id_pregunta': formRef.current.hiddenInput.value,'num_pregunta': formRef.current.hiddenInput2.value, 'respuesta': formRef.current.check3.value, 'seccion': formRef.current.seccion.value, 'id_usuario': usu, "id_seccion": id_sec}
                    setMensaje(false);
                    array.push(obj)
                    setImagenActual(imagenActual === cantidad - 1 ? 0 : imagenActual + 1);
            
                    setIsChecked3(false)
                  }else{
                    if(isChecked3 != false && formRef.current.check3.value == 3 && formRef.current.hiddenInput2.value == 61){
                      const obj = {'id_pregunta': formRef.current.hiddenInput.value,'num_pregunta': formRef.current.hiddenInput2.value, 'respuesta': formRef.current.check3.value, 'seccion': formRef.current.seccion.value, 'id_usuario': usu, "id_seccion": id_sec}
                    setMensaje(false);
                    array.push(obj)
                      respuestas();
                      setIsChecked3(false);
                    }
                  }
                }
              }
            }
          }
        }
      }

	};

	const anteriorImagen = () => {
		setImagenActual(imagenActual === 0 ? cantidad - 1 : imagenActual - 1);
	};

  if (
    l.getItem("id_privilegios") != undefined &&
    l.getItem("id_privilegios") == 6
  ) {

  return (
    <div id="wrapper">
      <SidebarE />
      <div id="content-wrapper" className="d-flex flex-column">
        <div id="content">
          <TopbarE />
          <div className="container-fluid">
            <div className="d-sm-flex align-items-center justify-content-between mb-4">
              
              <h4>NOM 037</h4>
              
            </div>

            <div className='row'>
              <div className='col-sm-12'>
              
              {Array.isArray(widgets)
        ? widgets.map((element, index) => {
          return(
          <div
          className={
            imagenActual === index
              ? `slide active`
              : "slide"
          }>
          {imagenActual === index && (
            <>
            
            <div key={index} className="col-sm-12 card shadow mb-4">
              <div className="card-header py-3">
                <h6 className="m-1 font-weight-bold text-primary">
                   NOM 037 - {element.nombre_seccion} <br />
                   {element.descripcion}
                </h6>
              </div>
              <div className="card-body">
                <div className='row'>
                  <p>{element.num_pregunta}.- {element.pregunta}</p>
                  <form onSubmit={siguienteImagen} ref={formRef}>
                  <div className='col-lg-12'>
                  <input name="hiddenInput" value={element.id_pregunta} type="hidden" />
                  <input name="hiddenInput2" value={element.num_pregunta} type="hidden" />
                  <input name="seccion" value={element.num_seccion} type="hidden" />
                <input name='check' value={1} type='checkbox' onChange={handleChange} checked={isChecked}></input> Si
                <br/>
                <input name='check2' value={2} type='checkbox' onChange={handleChange2} checked={isChecked2}></input> No
                <br/>
                <input name='check3' value={3} type='checkbox' onChange={handleChange3} checked={isChecked3}></input> No Aplica
                  </div>
                
                <span>
                  <br/>
                  <div className='row mx-auto'>
                  <div className='col-sm-12 mx-auto' style={{display : aiVisible ? 'flex' : 'none'}}>
                <button type='submit' className="d-sm-inline-block btn btn-sm btn-primary shadow-sm">
                  Siguiente <i className="fas fa-arrow-right fa-sm text-white-50"></i></button>
                  </div>
                  </div>

                  <div className='mx-auto' style={{display : terminado ? 'flex' : 'none'}}>
                <button type='submit' className="d-sm-inline-block btn btn-sm btn-primary shadow-sm">
                  Finalizar <i className="fas fa-check fa-sm text-white-50"></i></button>
                  </div>
                  <pre>{JSON.stringify(array, null, 2)}</pre>
              </span>
              </form>
              </div>
              </div>
            </div>
            </>
          )}
        </div>);
          })
        : null}
              </div>
              </div>
              <div className='col-sm-12 mx-auto text-danger' style={{display : mensaje ? 'flex' : 'none'}}>
                Favor de seleccionar una respuesta.
                </div>

                <SweetAlert2 {...swalProps} />

            &nbsp;&nbsp;&nbsp;&nbsp;
            </div>
          </div>
        </div>
      </div>    
  )
} else {
  return <NotFound />;
}
}

export default NOM037