import React, { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import Sidebar from "./componentes/Sidebar";
import Topbar from "./componentes/Topbar";
import { Link, useNavigate } from "react-router-dom";
import axios, { formToJSON } from "axios";
import ClipLoader from "react-spinners/ClipLoader";
import {
  RadialBarChart,
  RadialBar,
  Legend,
  BarChart,
  Bar,
  Rectangle,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

function Home() {

  const navigate = useNavigate();
  const location = useLocation();
  const l        = localStorage;
  const lp        = localStorage.getItem("id_privilegios");
  const url      = "https://ms.bluehand.com.mx/backend/api/v1/docglobalv2";
  const master   = "$2a$12$n1ifj4zomb1OS0sn/lW8COTSd5cV7lKGsgcdbC.ZBtVnAFFGt0.ie"

  const [listas, setLista]        = useState([]);
  const [seccion1, setSeccion1]   = useState([]);
  const [seccion2, setSeccion2]   = useState([]);
  const [seccion3, setSeccion3]   = useState([]);
  const [aiVisible, setAiVisible] = useState(true);
  const [graf, setGraf]           = useState(false);


  useEffect(() => {
    if (lp !== undefined) {
      switch (parseInt(lp)) {
        case 1:
          break;
        case 2:
        case 3:
          window.location.href = '/HomeCliente';
          break;
        case 4:
        case 5:
          window.location.href = '/HomeUsuario';
          break;
        case 6:
          window.location.href = '/Cuestionarios';
          break;
        default:
          window.location.href = '/404';
          break;
      }
    } else {
      window.location.href = '/404';
    }
  },[l]);

  useEffect(() => {
    if(listas == ""){
      if(location.pathname == '/Home'){  
      listaPost();
      }
    }
  }, []);


  const listaPost = async () => {

    var formData = new FormData();
    formData.append("master_key", master);
    formData.append("id_empresa", l.getItem('id_empresa'));
    var dataa = formToJSON(formData);

    await axios
      .post(url, dataa)
      .then((response) => {
        //debugger
        const data = response.data.data;
        const r = response.data.result_code;
        //mensaje
        const sec1 = data.periodo_actual_consulta_general_cuestionario_1//.numero_seccion_1;
        const sec2 = data.periodo_actual_consulta_general_cuestionario_1//.numero_seccion_2;
        const sec3 = data.periodo_actual_consulta_general_cuestionario_1//.numero_seccion_3;

        setLista(data);
        setSeccion1(sec1);
        setSeccion2(sec2);
        setSeccion3(sec3);
        setAiVisible(false);
        setGraf(true);
      })
      .catch((error) => { 
        console.log(error);
      });
  };

  function num(x) {
    return Number.parseFloat(x).toFixed(2);
  }

  const promedioNul = num(listas.promedio_nullo_x);
  const promedioBajo = num(listas.promedio_bajo_x);
  const promedioMedio = num(listas.promedio_medio_x);
  const promedioAlto = num(listas.promedio_alto_x);
  const promedioMuyAlto = listas.promedio_muy_alto_x;

  const data = [
      {
        name: "Nulo",
        uv: promedioNul,
        fill: "#00B0F0",
      },
      {
        name: "Bajo",
        uv: promedioBajo,
        fill: "#92d050",
      },
      {
        name: "Medio",
        uv: promedioMedio,
        fill: "#ffd966",
      },
      {
        name: "Alto",
        uv: promedioAlto,
        fill: "#F4B084",
      },
      {
        name: "Muy Alto",
        uv: promedioMuyAlto,
        pv: 4800,
        fill: "#C00000",
    },
  ];
  
  const style = {
    top: '50%',
    right: 0,
    transform: 'translate(0, -50%)',
    lineHeight: '24px',
  };

  const seccion_1 = [
    {
      name: seccion1.categoria,
      nulo: seccion1.nullo,
      bajo: seccion1.bajo,
      medio: seccion1.medio,
      alto: seccion1.alto,
      muy_alto: seccion1.muy_alto,
      amt: 2400,
    },
  ];

  const seccion_2 = [
    {
      name: seccion2.categoria,
      nulo: seccion2.nullo,
      bajo: seccion2.bajo,
      medio: seccion2.medio,
      alto: seccion2.alto,
      muy_alto: seccion2.muy_alto,
      amt: 2400,
    },
  ];

  const seccion_3 = [
    {
      name: seccion3.categoria,
      nulo: seccion3.nullo,
      bajo: seccion3.bajo,
      medio: seccion3.medio,
      alto: seccion3.alto,
      muy_alto: seccion3.muy_alto,
      amt: 2400,
    },
  ];

  return (
    <div id="wrapper">
      <Sidebar />
      <div id="content-wrapper" className="d-flex flex-column">
        <div id="content">
          <Topbar />
          <div className="container-fluid">
            <h1 className="h3 mb-0 text-gray-800"> Información General</h1>
            <br />
            <br />
            <div className="" style={{display : aiVisible ? 'flex' : 'none'}}>
            <ClipLoader
                  
                  size={150}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                  className="mx-auto"
                />
            </div>
                
            <div className="card-body" style={{display : graf ? 'flex' : 'none'}}>
            <div className="row">
              <div className="col-sm-6">
                <BarChart
                  width={500}
                  height={300}
                  data={seccion_1}
                  margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey="nulo" fill="#00B0F0" />
                  <Bar dataKey="bajo" fill="#92d050" />
                  <Bar dataKey="medio" fill="#ffd966" />
                  <Bar dataKey="alto" fill="#F4B084" />
                  <Bar dataKey="muy_alto" fill="#C00000" />
                </BarChart>
                <p className="text-center">
                </p>
              </div>

              <div className="col-sm-6">
                <BarChart
                  width={500}
                  height={300}
                  data={seccion_2}
                  margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey="nulo" fill="#00B0F0" />
                  <Bar dataKey="bajo" fill="#92d050" />
                  <Bar dataKey="medio" fill="#ffd966" />
                  <Bar dataKey="alto" fill="#F4B084" />
                  <Bar dataKey="muy_alto" fill="#C00000" />
                </BarChart>
                <p className="text-center">
                </p>
              </div>

              <div className="col-sm-6">
                <BarChart
                  width={500}
                  height={300}
                  data={seccion_3}
                  margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey="nulo" fill="#00B0F0" />
                  <Bar dataKey="bajo" fill="#92d050" />
                  <Bar dataKey="medio" fill="#ffd966" />
                  <Bar dataKey="alto" fill="#F4B084" />
                  <Bar dataKey="muy_alto" fill="#C00000" />
                </BarChart>
                <p className="text-center">
                </p>
              </div>

              <div className="col-sm-6">
                <RadialBarChart
                  width={500}
                  height={300}
                  cx={150}
                  cy={150}
                  innerRadius={20}
                  outerRadius={140}
                  barSize={20}
                  data={data}
                  startAngle={180}
                  endAngle={0}
                >
                  <RadialBar
                    minAngle={15}
                    label={{ position: "insideStart", fill: "#000" }}
                    background
                    clockWise={true}
                    dataKey="uv"
                  />
                  <Legend
                    iconSize={10}
                    width={120}
                    height={140}
                    layout="vertical"
                    verticalAlign="middle"
                    wrapperStyle={style}
                  />
                  <Tooltip />
                </RadialBarChart>
                <p className="text-center">
                  Total de Trabajadores según el Riesgo del ambiente
                </p>
              </div>
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
