import React from 'react';
import { Link } from 'react-router-dom';
import medi from "../../estilos/img/medd.png"

function SidebarC() {

  return (
    <div className='bg-gradient-info'>
        <div className='sticky-top'>
        <ul className="navbar-nav bg-gradient-info sidebar sidebar-dark accordion" id="accordionSidebar">


            <Link className="sidebar-brand d-flex align-items-center justify-content-center" to="/Home">
                <div className="sidebar-brand-icon">
                <img width={50} src={medi}/>
                </div>
                <div className="sidebar-brand-text mx-3">medismart</div>
            </Link>

            <li className="nav-item active">
                <Link to="/Home" className="nav-link">
                <i className="fas fa-fw fa-tachometer-alt"></i>
                    <span>Dashboard</span>
                </Link>
            </li>

            <div className="sidebar-heading">
                Encuestas
            </div>

            <li className="nav-item">
                <Link to="/MisEmpresas" className="nav-link">
                <i className="fas fa-fw fa-cog"></i>
                    <span>Mis Empresas</span>
                    </Link>

            </li>

            <div className="sidebar-heading">
                Periodos
            </div>

            <li className="nav-item">
                <Link to="/MisPeriodos" className="nav-link">
                <i className="fas fa-fw fa-cog"></i>
                    <span>Periodos / Reportes</span>
                    </Link>

            </li>

            <div className="sidebar-heading">
                Reportes
            </div>

            <li className="nav-item">
                <Link to="/ReporteEmpresa" className="nav-link">
                <i className="fas fa-fw fa-cog"></i>
                    <span>Reporte por Empresa</span>
                    </Link>

            </li>
{/*
            <div className="sidebar-heading">
                Áreas de Trabajo
            </div>

            <li className="nav-item">
                <Link to="/AreasTrabajo" className="nav-link">
                <i className="fas fa-fw fa-wrench"></i>
                    <span>Consultar Áreas</span>
                    </Link>
            </li>

            <div className="sidebar-heading">
                Puestos
            </div>

            <li className="nav-item">
                <Link to="/Puestos" className="nav-link">
                <i className="fas fa-fw fa-wrench"></i>
                    <span>Consultar Puestos</span>
                    </Link>
            </li>

            <div className="sidebar-heading">
                Usuarios
            </div>

            <li className="nav-item">
                <Link to="/Usuarios" className="nav-link">
                    <i className="fas fa-fw fa-chart-area"></i>
                    <span>Consultar Usuarios</span></Link>
            </li>

            <div className="sidebar-heading">
                Reportes
            </div>

            <li className="nav-item">
                <Link to="/UltimoReporte" className="nav-link" href="tables.html">
                <i className="fas fa-fw fa-table"></i>
                    <span>Último Generado</span>
                </Link>
            </li>

            <li className="nav-item">
                <Link to="/PorPeriodo" className="nav-link" href="tables.html">
                <i className="fas fa-fw fa-table"></i>
                    <span>Por Periodo</span>
                </Link>
            </li>

            <li className="nav-item">
                <Link to="/" className="nav-link" href="tables.html">
                <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2"></i>
                    <span>Cerrar Sesión</span>
                </Link>
            </li>
  */}
            

        </ul>
        </div>
        </div>
  )
}

export default SidebarC;