import React, { useState, useEffect, CSSProperties } from "react";
import SidebarC from "../componentes/SiderbarC.js";
import TopbarC from "../componentes/TopbarC.js";
import { Link } from "react-router-dom";
import axios, { formToJSON } from "axios";
import DataTable from "react-data-table-component";
import ClipLoader from "react-spinners/ClipLoader";
import NotFound from "../NotFound";
import { useParams } from 'react-router-dom';

function AsignarEncuestaArea() {

  /*const override: CSSProperties = {
    display: "block",
    margin: "10 auto",
    borderColor: "blue",
  };*/

  let l = localStorage;

  let {id} =useParams();

  console.log(id);
  //console.log(l.getItem("id_empresa"));

  //const empresa = l.getItem("id_empresa");

  //DE MOMENTO HARDCODEADO PARA QUE NO TARDE TANTO LA INFO Y SEA DE MEDISMART
  const empresa = "119";

  const url = "https://ms.bluehand.com.mx/backend2/api/v1/get_periodos_actual";

  const url2 =
    "https://ms.bluehand.com.mx/backend2/api/v1/asignarcues";
  const [master] = useState(
    "$2a$12$n1ifj4zomb1OS0sn/lW8COTSd5cV7lKGsgcdbC.ZBtVnAFFGt0.ie"
  );
  const [listas, setLista] = useState([]);
  const [periodos, setPeriodos] = useState([]);
  const [listap, setListaP] = useState([]);
  const [fechaI, setFechaI] = useState([]);
  const [cuestionario, setCuestionario] = useState([]);
  const [aiVisible, setAiVisible] = useState(true);
  const [tableEmp, setTablaEmp] = useState(false);

  const periodoPost = async () => {
    var f = new FormData();
    f.append("master_key", master);
    f.append("id_empresa", id);
    var dataa = formToJSON(f);
    await axios
      .post(url, dataa)
      .then((response) => {
        const data = response.data.data;
        const r = response.data.result_code;
        //mensaje
        const mess = response.data.message;
        if (r === "1") {
          setListaP(data);
        }
        if (r === "0") {
          alert(mess);
          return;
        }
      })
      .catch((error) => { 
        console.log(error);
      });
  };
  
  const listaPost = async () => {
    var f = new FormData();
    debugger
    f.append("master_key", master);
    f.append("id_empresa", id);
    f.append("id_periodo", periodos);
    f.append("fecha_de_inicio", fechaI);
    f.append("tipo_cuestionario", cuestionario);
    var dataa = formToJSON(f);
    await axios
      .post(url2, dataa)
      .then((response) => {
        debugger
        const data = response.data.data;
        const r = response.data.result_code;
        //mensaje
        const mess = response.data.message;
        if (r === "1") {          
          console.log(response);
        }
        if (r === "0") {
          alert(mess);
          return;
        }
      })
      .catch((error) => { 
        console.log(error);
      });
  };

  const columns = [
    {
      name: "ID",
      selector: (row) => row.id_hijo,
      grow: 1,
      center: true
    },
    {
      name: "Nombre Empresa",
      selector: (row) => row.trade_name,
      grow: 1,
      center: true
    },
    {
      name: "Cantidad de Empleados",
      selector: (row) => row.employees,
      sortable: true,
      grow: 1.5,
      center: true
    },
    {
      name: "Teléfono",
      selector: (row) => row.phone,
      grow: 0.8,
      center: true
    },
    {
      name: "Acciones",
      button: true,
      cell: (row) => (
        <>
          <Link to={`/DetalleEmpresaC/${row.id_hijo}`} className="btn btn-dark btn-circle btn-sm">
            <i className="fas fa-pen"></i>
          </Link>
          &nbsp;
          <Link className="btn btn-danger btn-circle btn-sm">
            <i className="fas fa-trash"></i>
          </Link>
        </>
      ),
    },
    {
      name: "Detalles",
      button: true,
      cell: (row) => (
        <>
          <Link to={`/AsignarEncuestas/${row.id_hijo}`} className="btn btn-info btn-circle btn-sm">
            <i className="fas fa-clipboard"></i>
          </Link>
          &nbsp;
          <Link to={`/MisUsuarios/${row.id_hijo}`} className="btn btn-success btn-circle btn-sm">
            <i className="fas fa-user"></i>
          </Link>
          &nbsp;
          <Link to={`/AreasEmpresa/${row.id_hijo}`} className="btn btn-secondary btn-circle btn-sm">
            <i className="fas fa-eye"></i>
          </Link>
        </>
      ),
    },
  ];

  const customStyles = {
    headCells: {
      style: {
        backgroundColor: "rgba(249, 250, 251, 1)",
        textTransform: "uppercase",
        color: "rgba(107, 114, 128, 1))",
        letterSpacing: "0.05em",
        fontSize: "0.85rem",
        fontWeight: "1000",
        lineHeight: "1rem",
        padding: "0.5rem"
      },
    },
    cells: {
      style: {
        paddingTop: "0.5rem",
        paddingBottom: "0.5rem",
        overflowX: "unset",
        
      },
    },
    subHeader: {
      style: {
        backgroundColor: "rgba(249, 250, 251, 1)",
        textTransform: "uppercase",
        color: "rgba(107, 114, 128, 1))",
        padding: "15rem",
      },
    },
  };

  useEffect(() => {
    if(listas == ""){
      periodoPost();
    }
  }, []);

  function handleSubmit(event) {
    event.preventDefault();
  listaPost();
  }

  function handleChangeF(event) {
    setFechaI(event.target.value);
  }

  function handleChangeC(event) {
    setCuestionario(event.target.value);
  }

  if (l.getItem("id_privilegios") != undefined && l.getItem("id_privilegios") == 2 ) {
  return (
    <div id="wrapper">
      <SidebarC />
      <div id="content-wrapper" className="d-flex flex-column">
        <div id="content">
          <TopbarC />
          <div className="container-fluid">
            <div className="d-sm-flex align-items-center justify-content-between mb-4">
              <Link
                to="/MisEmpresas"
                className="text-white d-sm-inline-block btn btn-sm shadow-sm"
                style={{backgroundColor: "#1fad7a"}}
              >
                <i className="fas fa-arrow-left fa-sm text-white-50"></i> Regresar
              </Link>
            </div>
            <form className="user" onSubmit={handleSubmit}>
            <div className="card shadow mb-4">
                <div className="card-header py-3">
                <h6 className="m-0 font-weight-bold text-primary">Asignación de Encuestas</h6>
              </div>
              <div className="card-body">

                <div className="row">
                  <div className="form-group col-lg-4">
                  <select
                    id="comboEmpresa"
                    name="empresaId"
                    className="form-control"
                    style={{borderRadius: 40, height: 49}}
                    onChange={(e) => setPeriodos(e.target.value)}
                  >
                    <option value="value1">
                      --- Selecciona una opcion ---
                    </option>
                    {listap.map((lista) => {
                      return (
                        <option key={lista.id_periodo} value={lista.id_periodo}>
                          {lista.nombre_periodo}
                        </option>
                      );
                    })}
                  </select>
                  </div>
                  <div className="form-group col-lg-4">
                    <input
                        type="date"
                        className="form-control form-control-user"
                        id="exampleInputPassword"
                        placeholder="Telefono"
                        onChange={handleChangeF}
                    />
                  </div>
                  <br/>
                  <div className="form-group col-lg-4">
                  <select className="form-control" onChange={handleChangeC} style={{borderRadius: 40, height: 49}}>
                        <option value={1}>
                            NOM 035
                        </option>
                        <option value={2}>
                            NOM 036
                        </option>
                        <option value={3}>
                            NOM 037
                        </option>
                    </select>
                  </div>
                  <div className="form-group col-lg-12 text-center">
                  <button
                          type="submit"
                          className="text-white btn btn-user"
                          style={{backgroundColor: "#1fad7a"}}
                        >
                          Asignar
                        </button>
                  </div>
                </div>
                </div>
                </div>
              </form>
            
          </div>
        </div>
      </div>
    </div>
  );
  } else{
    return(
    <NotFound />
    )
  }
}

export default AsignarEncuestaArea;
