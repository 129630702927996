import React,{ useState, useEffect } from "react";
import SidebarE from "../componentes/SidebarE";
import TopbarE from "../componentes/TopbarE";
import { Link } from "react-router-dom";
import axios, {formToJSON} from 'axios';
import { useParams } from 'react-router-dom';
import NotFound from "../NotFound";

function PerfilE() {

    let {id} =useParams();

  const url = 'https://ms.bluehand.com.mx/backend/api/v1/api/get-all-users-by-id';
  const [master] = useState(
    '$2a$12$n1ifj4zomb1OS0sn/lW8COTSd5cV7lKGsgcdbC.ZBtVnAFFGt0.ie'
  );
  const [widgets, setWidgets] = useState('');

  const peticionPost = async () => {
    var f = new FormData();
    f.append('master_key', master);
    f.append('id_usuario', id);
    var dataa = formToJSON(f);
    await axios
      .post(url, dataa)
      .then((response) => {
        const data = response.data.data;
        setWidgets(data);
        //navigate("/Reporte");
        console.log(widgets);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if(widgets == ""){
      peticionPost();
    }
  }, []);

  let l = localStorage;

  if (l.getItem("id_privilegios") != undefined && l.getItem("id_privilegios") == 6 ) {
  return (
    <div id="wrapper">
      <SidebarE />
      <div id="content-wrapper" className="d-flex flex-column">
        <div id="content">
          <TopbarE />
          <div className="container-fluid">
            <div className="d-sm-flex align-items-center justify-content-between mb-4">
            
            </div>
            <h1 className="h3 mb-0 text-gray-800">Mi Perfil</h1>

            <br />

            <div className="container-fluid">
              <form className="user">
                <div className="row">
                  <div className="form-group col-lg-6">
                    <label>Nombre</label>
                    <input
                      type="text"
                      className="form-control form-control-user"
                      id="nombreUsuario"
                      value={widgets.nombre}
                    />
                  </div>
                  <div className="form-group col-lg-6">
                  <label>Apellido Paterno</label>
                    <input
                      type="text"
                      className="form-control form-control-user"
                      id="apellidoPaterno"
                      value={widgets.apellido_paterno}
                    />
                  </div>
                  <br />
                  <div className="form-group col-lg-6">
                  <label>Apellido Materno</label>
                    <input
                      type="text"
                      className="form-control form-control-user"
                      id="exampleInputEmail"
                      aria-describedby="emailHelp"
                      value={widgets.apellido_materno}
                    />
                  </div>
                  <div className="form-group col-lg-6">
                  <label>Fecha Nacimiento</label>
                    <input
                      type="text"
                      className="form-control form-control-user"
                      id="exampleInputPassword"
                      value={widgets.fecha_nacimiento}
                    />
                  </div>
                  <div className="form-group col-lg-6">
                  <label>Estado Civil</label>
                    <input
                      type="text"
                      className="form-control form-control-user"
                      id="exampleInputPassword"
                      value={widgets.estado_civil}
                    />
                  </div>
                  <div className="form-group col-lg-6">
                  <label>Tipo Contrato</label>
                    <input
                      type="text"
                      className="form-control form-control-user"
                      id="exampleInputPassword"
                      value={widgets.tipo_contrato}
                    />
                  </div>
                  <div className="form-group col-lg-3">
                  <label>Experiencia Laboral</label>
                    <input
                      type="text"
                      className="form-control form-control-user"
                      id="exampleInputPassword"
                      value={widgets.experiencia_laboral}
                    />
                  </div>
                  <div className="form-group col-lg-3">
                  <label>Tiempo en el Puesto</label>
                    <input
                      type="text"
                      className="form-control form-control-user"
                      id="exampleInputPassword"
                      value={widgets.tiempo_en_puesto}
                    />
                  </div>
                  <div className="form-group col-lg-3">
                  <label>Tiempo en la Empresa</label>
                    <input
                      type="text"
                      className="form-control form-control-user"
                      id="exampleInputPassword"
                      value={widgets.tiempo_en_empresa}
                    />
                  </div>
                  <div className="form-group col-lg-3">
                  <label>Tipo de Jornada</label>
                    <input
                      type="text"
                      className="form-control form-control-user"
                      id="exampleInputPassword"
                      value={widgets.tipo_jornada}
                    />
                  </div>
                  <div className="form-group col-lg-2">
                  <label>Rotación de Turno</label>
                    <input
                      type="text"
                      className="form-control form-control-user"
                      id="exampleInputPassword"
                      value={widgets.rotacion_turno}
                    />
                  </div>                  
                  <div className="form-group col-lg-3">
                  <label>CURP</label>
                    <input
                      type="text"
                      className="form-control form-control-user"
                      id="exampleInputPassword"
                      value={widgets.curp}
                    />
                  </div>
                  <div className="form-group col-lg-3">
                  <label>Teléfono</label>
                    <input
                      type="text"
                      className="form-control form-control-user"
                      id="exampleInputPassword"
                      value={widgets.telefono}
                    />
                  </div>
                  <div className="form-group col-lg-3">
                  <label>Email</label>
                    <input
                      type="text"
                      className="form-control form-control-user"
                      id="exampleInputPassword"
                      value={widgets.email}
                    />
                  </div>
                  <div className="form-group col-lg-1">
                  <label>Estatus</label>
                    <input
                      type="text"
                      className="form-control form-control-user"
                      id="exampleInputPassword"
                      value={widgets.status}
                    />
                  </div>
                  <div className="form-group col-lg-12 text-center">
                  <Link
                    to="/Empresas"
                    className="btn btn-primary btn-user btn-sm"
                  >
                    Guardar
                  </Link>
                  </div>
                </div>
                
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
else{
  return(
  <NotFound />
  )
}
}

export default PerfilE;
