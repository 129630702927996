import React, { useState } from 'react';
import axios, { formToJSON } from 'axios';
import { Link } from 'react-router-dom';
import Sidebar from './componentes/Sidebar';
import Topbar from './componentes/Topbar';

function PorPeriodo() {
    const url = 'https://ms.bluehand.com.mx/backend/api/v1/api/docglobal_by_periodo';
  const url2 = 'https://ms.bluehand.com.mx/backend/api/v1/api/get-all-companies';
  const [id, setId] = useState('');
  const [master] = useState(
    '$2a$12$n1ifj4zomb1OS0sn/lW8COTSd5cV7lKGsgcdbC.ZBtVnAFFGt0.ie'
  );
  const [periodo] = useState('98')
  const [listas, setLista] = useState([]);
  const [widgets, setWidgets] = useState('');
  const [estilos, setEstilos] = useState('');

  const listaPost = async () => {
    var f = new FormData();
    f.append('master_key', master);
    f.append('id_periodo', periodo )
    var dataa = formToJSON(f);
    await axios
      .post(url2, dataa)
      .then((response) => {
        const data = response.data.data;

        setLista(data);
        //navigate("/Reporte");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  listaPost();

  return (
    <div id="wrapper">
         <Sidebar />
         <div id="content-wrapper" className="d-flex flex-column">

            <div id="content">

                <Topbar />
                <div className="container-fluid">
                <div className="d-sm-flex align-items-center justify-content-between mb-4">
                        <h1 className="h3 mb-0 text-gray-800">Reporte por Periodo</h1>
                </div>

                <div className='row col-lg-6'>
                 <div>
                     <div className="form">
                         <div id="">
                         <h4>Generar Reporte</h4>
                         </div>
                         <br />
                          <select
                         id="comboEmpresa"
                         name="empresaId"
                         className="form-control text-black"
                         onChange={(e) => setId(e.target.value)}
                         >
                          {listas.map((lista) => {
                            return (
                            <option key={lista.id} value={lista.id}>
                              {lista.trade_name}{lista.id}
                              </option>
                              );
                              })}
                              </select>
                              <br/>
                         <Link  className="d-sm-inline-block btn btn-sm btn-primary shadow-sm" to={`Prueba/${id}`}>
                         <i className="fas fa-search fa-sm"></i>
                         &nbsp;
                          Buscar
                          </Link>
                         
                     </div>
                 </div>
             </div>

                </div>


            </div>
            </div>
        </div>
  )
}

export default PorPeriodo