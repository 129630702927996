import React, {useState} from "react";
import SidebarC from "../componentes/SiderbarC";
import TopbarC from "../componentes/TopbarC";
import { Link } from "react-router-dom";
import NotFound from "../NotFound";
import { useNavigate } from "react-router-dom";
import axios, { formToJSON } from "axios";
import { useParams } from 'react-router-dom'; 

function AgregarPuestos() {

  let l = localStorage;

  let info = useParams();

  const navigate = useNavigate();

  const [puesto, setPuesto] = useState([]);

  const url = "https://ms.bluehand.com.mx/backend/api/v1/api/createPosition";
  const [master] = useState(
    "$2a$12$n1ifj4zomb1OS0sn/lW8COTSd5cV7lKGsgcdbC.ZBtVnAFFGt0.ie"
  );

  const peticionPost = async () => {
    var f = new FormData();
    f.append("master_key", master);
    f.append("nombre_puesto", puesto);
    f.append("id_area", info.id);
    var dataa = formToJSON(f);
    
    await axios
      .post(url, dataa)
      .then((response) => {
        debugger
        const r = response.data.result_code;
        if (r == "1") {
          navigate(`/MisPuestos/${info.id}/${info.id_emp}`)
        } else {
          if (r == "2") {
            console.log("nono")
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  function handleInputChange(event) {
    setPuesto(event.target.value);
  }

  function handleSubmit(event) {
    event.preventDefault();
  peticionPost();
  }

  if (l.getItem("id_privilegios") != undefined && l.getItem("id_privilegios") == 2) {
  return (
    <div id="wrapper">
      <SidebarC />
      <div id="content-wrapper" className="d-flex flex-column">
        <div id="content">
          <TopbarC />
          <div className="container-fluid">
            <div className="d-sm-flex align-items-center justify-content-between mb-4">
              <Link
                to={`/MisPuestos/${info.id}/${info.id_emp}`}
                className="d-sm-inline-block btn btn-sm text-white shadow-sm"
                style={{backgroundColor: "#1fad7a", border: "#1fad7a"}}
              >
                <i className="fas fa-arrow-left fa-sm text-white-50"></i> Regresar
              </Link>
            </div>
            <h1 className="h3 mb-0 text-gray-800">Alta de Puestos</h1>

            <br />

            <div className="container-fluid">
            <form className="user" onSubmit={handleSubmit}>
                <div className="row">
                  <div className="form-group col-lg-6">
                    <input
                      type="text"
                      className="form-control form-control-user"
                      id="exampleInputEmail"
                      aria-describedby="emailHelp"
                      placeholder="Nombre del Puesto"
                      onChange={handleInputChange}
                    />
                  </div>

                  <div className="form-group col-lg-12 text-center">
                  <button
                    type="submit"
                    className="btn btn-c btn-user btn-sm"
                    style={{border: "#380079"}}
                  >
                    Guardar
                  </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );}
  else {
    return(
      <NotFound />
    )
  }
}

export default AgregarPuestos;
