import React, { useState, useEffect } from "react";
import SidebarE from "../componentes/SidebarE.js";
import { Link, useParams, useLocation, useNavigate } from "react-router-dom";
import TopbarE from "../componentes/TopbarE.js";
import axios, { formToJSON } from "axios";
import { Navigate } from "react-router-dom";
import SweetAlert2 from "react-sweetalert2";
import NotFound from "../NotFound.js";

function Seccion3() {
  let { id } = useParams();
  let l = localStorage;
  //let usu = l.getItem("id_usuario");
  let id_cuestionario = 1;
  let usu = l.getItem("id_usuario");

  //let {estado} =useParams();
  let state = useLocation();

  const url = "https://ms.bluehand.com.mx/backend/api/v1/traernom35";
  const url2 = "https://ms.bluehand.com.mx/backend/api/v1/contestarnom35";
  const [master] = useState(
    "$2a$12$n1ifj4zomb1OS0sn/lW8COTSd5cV7lKGsgcdbC.ZBtVnAFFGt0.ie"
  );
  const [widgets, setWidgets] = useState("");
  const [aiVisible, setAiVisible] = useState(true);
  const [terminado, setTerminado] = useState(false);
  const estado = l.getItem("esto");
  const [mensaje, setMensaje] = useState(false);
  const [array, setArray] = useState([]);
  const [swalProps, setSwalProps] = useState({});
  const [resp, setResp] = useState();
  const [prueba, setPrueba] = useState();
  const [validacion, setValidacion] = useState();

  const navigate = useNavigate();

  const peticionPost = async () => {
    var f = new FormData();
    f.append("master_key", master);
    f.append("cuestionario", id);
    var dataa = formToJSON(f);
    await axios
      .post(url, dataa)
      .then((response) => {
        debugger;
        const data = response.data.data[2];
        const preguntas = response.data.data[2].questions;
        setWidgets(data);
        setPrueba(preguntas);
        debugger;
        //navigate("/Reporte");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  console.log(prueba);
  useEffect(() => {
    if (widgets == "") {
      peticionPost();
    }
  }, []);

  const respuestas = async () => {
    debugger;
    await axios.post(url2, array).then((response) => {
      debugger;
      const r = response.data.result_code;

      const mess = response.data.message;

      console.log(r);

      if(r == "1"){
        navigate(`/Seccion4/${id}`);
      }
      else{
        alert(mess);
      }

      //navigate("/Reporte");
    });
  };

  const respuestasC2 = async () => {
    debugger;
    await axios.post(url2, array).then((response) => {
      debugger;
      const r = response.data.result_code;

      const mess = response.data.message;

      console.log(r);

      if(r == "1"){
        if(mess == "Se agrego correctamente las respuetas de la seccion 7"){
        navigate(`/Seccion4/${id}`);
        }else{
          alert(mess);
        }
      }

      //navigate("/Reporte");
    });
  };


  const respuestasC3 = async () => {
    debugger;
    await axios.post(url2, array).then((response) => {
      debugger;
      const r = response.data.result_code;

      const mess = response.data.message;

      console.log(r);

      if(r == "1"){
        if(mess == "Se agrego correctamente las respuetas de la seccion 11"){
        navigate(`/Seccion4/${id}`);
        }else{
          alert(mess);
        }
      }

      //navigate("/Reporte");
    });
  };

  const [imagenActual, setImagenActual] = React.useState(0);
  const cantidad = widgets?.length;

  const pasar = async () => {
    navigate(`/Cuestionarios`);
  };

  function showSwal() {
    setSwalProps({
      show: true,
      icon: "success",
      title: "Completado",
      text: "Gracias por participar en la encuesta es muy valioso para poder ayudarte y mejorar nuestro ambiente de trabajo",
      confirmButtonText: "Salir",
      preConfirm: () => {
        pasar();
      },
    });
  }

  const arr = [];

  const formRef = React.useRef(null);

  const siguienteImagen = (e) => {
    e.preventDefault();
    debugger

    if(id == 1){

        if (resp == "-1" || resp == undefined) {
            setMensaje(true);
          }
          else {
              const obj = {
                  id_pregunta: formRef.current.hiddenInput.value,
                  num_pregunta: formRef.current.hiddenInput2.value,
                  respuesta: resp,
                  id_seccion: formRef.current.seccion.value,
                  id_cuestionario: formRef.current.hiddenInput3.value,
                  id_usuario: usu,
                };
                array.push(obj);
                setResp();
                setValidacion(true);
                setMensaje(false);
      
                setImagenActual(imagenActual === cantidad - 1 ? 0 : imagenActual + 1);
      
                if(formRef.current.hiddenInput2.value == 7){
                  respuestas();
                }
          }
    }

    else {
        if(id == 2){
            if (resp == "-1" || resp == undefined) {
                setMensaje(true);
              }
              else {
                  const obj = {
                      id_pregunta: formRef.current.hiddenInput.value,
                      num_pregunta: formRef.current.hiddenInput2.value,
                      id_cuestionario: formRef.current.hiddenInput3.value,
                      respuesta: resp,
                      id_seccion: formRef.current.seccion.value,
                      id_usuario: usu,
                    };
                    array.push(obj);
                    setResp();
                    setValidacion(true);
                    setMensaje(false);
          
                    setImagenActual(imagenActual === cantidad - 1 ? 0 : imagenActual + 1);
          
                    if(formRef.current.hiddenInput2.value == 17){
                      respuestasC2();
                    }
              }
        }
    }

    if(id == 3){
      if (resp == "-1" || resp == undefined) {
        setMensaje(true);
      }
      else {
          const obj = {
              id_pregunta: formRef.current.hiddenInput.value,
              num_pregunta: formRef.current.hiddenInput2.value,
              id_cuestionario: formRef.current.hiddenInput3.value,
              respuesta: resp,
              id_seccion: formRef.current.seccion.value,
              id_usuario: usu,
            };
            array.push(obj);
            setResp();
            setValidacion(true);
            setMensaje(false);
  
            setImagenActual(imagenActual === cantidad - 1 ? 0 : imagenActual + 1);
  
            if(formRef.current.hiddenInput2.value == 22){
              respuestasC3();
            }
      }
    }
    
  };

  const validar = () => {
    debugger
    if(validacion == true){
  
      navigate(`/Seccion2/${id}`);

    }else{
      showSwal();
     }
  };

  const anteriorImagen = () => {
    setImagenActual(imagenActual === 0 ? cantidad - 1 : imagenActual - 1);
  };

  if (
    l.getItem("id_privilegios") != undefined &&
    l.getItem("id_privilegios") == 6
  ) {
    return (
      <div id="wrapper">
        <SidebarE />
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <TopbarE />
            <div className="container-fluid">
              <div className="d-sm-flex align-items-center justify-content-between mb-4">
                <h4>NOM 035</h4>
              </div>
              <div className="row">
                <div className="col-sm-12">
                  {Array.isArray(prueba)
                    ? prueba.map((element, index) => {
                        return (
                          <div
                            className={
                              imagenActual === index ? `slide active` : "slide"
                            }
                          >
                            {imagenActual === index && (
                              <>
                                <div
                                  key={index}
                                  className="col-sm-12 card shadow mb-4"
                                >
                                  <div className="card-header py-3">
                                    <h6 className="m-1 font-weight-bold text-primary">
                                    Sección {widgets.section_number} - {widgets.name} <br />
                                      {widgets.description}
                                    </h6>
                                  </div>
                                  <div className="card-body">
                                    <div className="row">
                                      <form onSubmit={siguienteImagen}>
                                        <p>
                                          {element.question}
                                        </p>
                                        <form
                                          className="col-lg-3"
                                          ref={formRef}
                                        >
                                          <input
                                            name="hiddenInput"
                                            value={element.question_id}
                                            type="hidden"
                                          />
                                          <input
                                            name="hiddenInput2"
                                            value={element.question_number}
                                            type="hidden"
                                          />
                                          <input
                                            name="hiddenInput3"
                                            value={widgets.questionnaire_id
                                            }
                                            type="hidden"
                                          />
                                          <input
                                            name="seccion"
                                            value={widgets.id_seccion}
                                            type="hidden"
                                          />
                                          <select
                                            name="respuestaP"
                                            className="form-control text-black"
                                            onChange={(e) =>
                                              setResp(e.target.value)
                                            }
                                          >
                                            <option value={"-1"}>
                                              --- Selecciona una opción ---
                                            </option>
                                            {element.options.map((lista) => {
                                              return (
                                                <option
                                                  key={lista.value}
                                                  value={lista.value}
                                                >
                                                  {lista.question_option}
                                                </option>
                                              );
                                            })}
                                          </select>
                                        </form>
                                        <br />
                                        <span>
                                          <br />
                                          <div className="row mx-auto">
                                            <div
                                              className="col-sm-12 mx-auto"
                                              style={{
                                                display: aiVisible
                                                  ? "flex"
                                                  : "none",
                                              }}
                                            >
                                              <button
                                                type="submit"
                                                className="d-sm-inline-block btn btn-sm btn-primary shadow-sm"
                                              >
                                                Siguiente{" "}
                                                <i className="fas fa-arrow-right fa-sm text-white-50"></i>
                                              </button>
                                            </div>
                                          </div>
                                          <div
                                            className="mx-auto"
                                            style={{
                                              display: terminado
                                                ? "flex"
                                                : "none",
                                            }}
                                          >
                                            <button
                                              type="submit"
                                              className="d-sm-inline-block btn btn-sm btn-primary shadow-sm"
                                            >
                                              Finalizar{" "}
                                              <i className="fas fa-check fa-sm text-white-50"></i>
                                            </button>
                                          </div>
                                        </span>
                                      </form>
                                    </div>
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        );
                      })
                    : null}
                </div>
              </div>
              <div
                className="col-sm-12 mx-auto text-danger"
                style={{ display: mensaje ? "flex" : "none" }}
              >
                Favor de seleccionar una respuesta.
              </div>
              <SweetAlert2 {...swalProps} />
              &nbsp;&nbsp;&nbsp;&nbsp;
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return <NotFound />;
  }
}

export default Seccion3;
