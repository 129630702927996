import React, { useState, useEffect, CSSProperties } from "react";
import SidebarC from "../componentes/SiderbarC.js";
import TopbarC from "../componentes/TopbarC.js";
import { Link } from "react-router-dom";
import axios, { formToJSON } from "axios";
import DataTable from "react-data-table-component";
import ClipLoader from "react-spinners/ClipLoader";
import NotFound from "../NotFound";
import { useParams } from 'react-router-dom';
import { CSVLink } from "react-csv";

function DetallePeriodo() {

  /*const override: CSSProperties = {
    display: "block",
    margin: "10 auto",
    borderColor: "blue",
  };*/

  let l = localStorage;

  let {id} =useParams();

  console.log(id);
  //console.log(l.getItem("id_empresa"));

  //const empresa = l.getItem("id_empresa");

  //DE MOMENTO HARDCODEADO PARA QUE NO TARDE TANTO LA INFO Y SEA DE MEDISMART
  const empresa = "119";

  const url = "https://ms.bluehand.com.mx/backend2/api/v1/detalleperiodo";

  const url2 =
    "https://ms.bluehand.com.mx/backend2/api/v1/asignarcues";

    const url3 = "https://ms.bluehand.com.mx/backend2/api/v1/cuestionariosperiodo";
    const url4 = "https://ms.bluehand.com.mx/backend2/api/v1/api/actualiza-periodo";

  const [master] = useState(
    "$2a$12$n1ifj4zomb1OS0sn/lW8COTSd5cV7lKGsgcdbC.ZBtVnAFFGt0.ie"
  );
  const [listas, setLista] = useState([]);
  const [listap, setListaP] = useState([]);
  const [fechaI, setFechaI] = useState([]);
  const [cuestionario, setCuestionario] = useState([]);
  const [aiVisible, setAiVisible] = useState(false);
  const [aiVisible2, setAiVisible2] = useState(false);
  const [aiVisible3, setAiVisible3] = useState(false);
  const [tableEmp, setTablaEmp] = useState(false);
  const [encuesta, setEncuesta] = useState('');

  const [periodo, setPeriodo] = useState('');
  const [fecha_inicio, setFecha_Inicio] = useState('');
  const [fecha_final, setFecha_Fin] = useState('');

  const [search, SetSearch]= useState('');
  const [filter, setFilter]= useState([]);

  const periodoPost = async () => {
    var f = new FormData();
    debugger
    f.append("master_key", master);
    f.append("id_periodo", id);
    var dataa = formToJSON(f);
    await axios
      .post(url, dataa)
      .then((response) => {
        debugger
        const data = response.data.data[0];
        const r = response.data.result_code;
        //mensaje
        const mess = response.data.message;
        if (r === "1") {
          setListaP(data);
          setPeriodo(data.nombre_periodo);
          setFecha_Inicio(data.periodo_inicio);
          setFecha_Fin(data.periodo_fin);

          if(data.dass == 1){
            setEncuesta("NOM 035")
          }
          else{
            if(data.nom36 == 1){
                setEncuesta("NOM 036")
            }else{
                if(data.nom37 == 1){
                    setEncuesta("NOM 037")
                }
            }
          }
        }
        if (r === "0") {
          alert(mess);
          return;
        }
      })
      .catch((error) => { 
        console.log(error);
      });
  };


  const modificarP = async () => {
    var f = new FormData();
    debugger
    f.append("master_key", master);
    f.append("id_periodo", id);
    f.append("fecha_inicio", fecha_inicio);
    f.append("fecha_fin", fecha_final);
    f.append("nombre", periodo);
    var dataa = formToJSON(f);
    await axios
      .post(url4, dataa)
      .then((response) => {
        debugger
        const data = response.data.data[0];
        const r = response.data.result_code;
        //mensaje
        const mess = response.data.message;
        if (r === "1") {
          alert(mess)
        }
        if (r === "0") {
          alert(mess);
          return;
        }
      })
      .catch((error) => { 
        console.log(error);
      });
  };
  
  const listaPost = async () => {
    var f = new FormData();
    debugger
    f.append("master_key", master);
    f.append("id_periodo", id);
    var dataa = formToJSON(f);
    await axios
      .post(url3, dataa)
      .then((response) => {
        debugger
        const data = response.data.data.user;
        const r = response.data.result_code;
        
        setLista(data);
        setFilter(data);
        setAiVisible(true)
        setTablaEmp(true)
        //mensaje
        const mess = response.data.message;
        if (r === "1") {          
          console.log(response);
        }
        if (r === "0") {
          alert(mess);
          return;
        }
      })
      .catch((error) => { 
        console.log(error);
      });
  };

  useEffect(()=>{
    debugger
    const result= listas.filter((item)=>{
     return item.nombre.toLowerCase().match(search.toLocaleLowerCase());
    });
    setFilter(result);
},[search]);

console.log(listas)

const columns = [
  {
    name: "CURP",
    grow: 1.5,
    selector: (row) => row.curp,
  },
  {
    name: "Nombre",
    grow: 1.5,
    selector: (row) => row.nombre,
  },
  {
    name: "Cuestionario",
    grow: 1.5,
    selector: (row) => row.cuestionario,
  },
  {
    name: "Estado",
    grow: 1.5,
    selector: (row) => row.estado,
  },
  
];

const customStyles = {
  headCells: {
    style: {
      backgroundColor: "rgba(249, 250, 251, 1)",
      textTransform: "uppercase",
      color: "rgba(107, 114, 128, 1))",
      letterSpacing: "0.05em",
      fontSize: "0.85rem",
      fontWeight: "1000",
      lineHeight: "1rem",
    },
  },
  cells: {
    style: {
      paddingTop: "0.5rem",
      paddingBottom: "0.5rem",
      overflowX: "unset",
    },
  },
  subHeader: {
    style: {
      backgroundColor: "rgba(249, 250, 251, 1)",
      textTransform: "uppercase",
      color: "rgba(107, 114, 128, 1))",
      padding: 16,
    },
  },
};

  useEffect(() => {
    if(listas == ""){
      periodoPost();
    }
  }, []);

  function handleSubmit(event) {
    event.preventDefault();
  modificarP();
  }

  if (l.getItem("id_privilegios") != undefined && l.getItem("id_privilegios") == 2 ) {
  return (
    <div id="wrapper">
      <SidebarC />
      <div id="content-wrapper" className="d-flex flex-column">
        <div id="content">
          <TopbarC />
          <div className="container-fluid">
            <div className="d-sm-flex align-items-center justify-content-between mb-4">
              <Link
                to="/MisEmpresas"
                className="text-white d-sm-inline-block btn btn-sm shadow-sm"
                style={{backgroundColor: "#1fad7a"}}
              >
                <i className="fas fa-arrow-left fa-sm text-white-50"></i> Regresar
              </Link>

              <button
                className="text-white d-sm-inline-block btn btn-sm shadow-sm"
                style={{backgroundColor: "#1fad7a"}}
              >
               <i className="fas fa-clipboard-check"></i> Finalizar Periodo
              </button>
            </div>
            
            <div className="card shadow mb-4">
                <div className="card-header py-3">
                <h6 className="m-0 font-weight-bold text-primary">Información del Periodo</h6>
              </div>
              <div className="card-body">

                <div className="row">
                  <div className="form-group col-lg-4">
                  <p>Nombre del periodo:  {listap.nombre_periodo}</p>
                  </div>
                  <div className="form-group col-lg-4">
                  <p>Fecha Inicio:  {listap.periodo_inicio}</p>
                  </div>
                  <div className="form-group col-lg-4">
                  <p>Fecha Fin:  {listap.periodo_fin}</p>
                  </div>
                  <div className="form-group col-lg-4">
                  <p>Cantidad de usuarios asignados:  {listap.num_inscritos} 
                  <button className="btn" onClick={listaPost}>
                  <i className="fas fa-eye"></i>
                  </button>
                  </p>
                  </div>
                  <div className="form-group col-lg-4">
                  <p>Cuestionario Asignado: {encuesta}</p>
                  </div>
                  <div className="form-group col-lg-12 text-center">
                  <button
                          
                          className="text-white btn btn-user"
                          style={{backgroundColor: "#1fad7a"}}
                          onClick={() => {
                            setAiVisible3(true);
                          }}
                        >
                          <i className="fas fa-pen"></i> Editar
                        </button>
                  </div>
                </div>
                </div>
                </div>


                <div className="card shadow mb-4" style={{display : aiVisible ? 'flex' : 'none'}}>
              <div className="card-header py-3">
                <div className="row">
                  <div className="col-sm-6">
                  <h6 className="m-0 font-weight-bold text-primary">Usuarios</h6>
                  </div>
                  <div className="col-sm-5 text-right">
                  <CSVLink data={listas}><button style={{backgroundColor: "#380079", border: "#380079"}} className="btn btn-primary">Exportar a CSV</button></CSVLink>
                  </div>
                  <div className="col-sm-1 text-right">
                  <button style={{backgroundColor: "#7B53E0", border: "#7B53E0"}} className="btn btn-info btn-circle btn-sm" onClick={() => setAiVisible(false)}>X</button>
                  </div>
                </div>
              </div>
              <div className="" style={{display : aiVisible2 ? 'flex' : 'none'}}>
              <ClipLoader
                    
                    size={150}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                    className="mx-auto"
                  />
              </div>
                  
              <div className="card-body" style={{display : tableEmp ? 'flex' : 'none'}}>
                <div className="table-responsive">
                  <DataTable
                  noDataComponent="Sin Resultados..."
                    responsive
                    striped
                    columns={columns}
                    data={filter}
                    pagination
                    customStyles={customStyles}
                    subHeader
             subHeaderComponent={
                <input type="text"
                className="w-25 form-control"
                placeholder="Buscar..."
                value={ search}
                onChange={(e)=>SetSearch(e.target.value)}
                
                />
             }
             subHeaderAlign="right"
                  />
                </div>
              </div>
                </div>


                <div className="card shadow mb-4" style={{display : aiVisible3 ? 'flex' : 'none'}}>
              <div className="card-header py-3">
                <div className="row">
                  <div className="col-sm-6">
                  <h6 className="m-0 font-weight-bold text-primary">Editar Periodo</h6>
                  </div>
                  <div className="col-sm-6 text-right">
                  <button style={{backgroundColor: "#7B53E0", border: "#7B53E0"}} className="btn btn-info btn-circle btn-sm" onClick={() => setAiVisible3(false)}>X</button>
                  </div>
                </div>
              </div>


              <form className="user" onSubmit={handleSubmit}>
            <div className="card shadow mb-4">
              <div className="card-body">

                <div className="row">
                <div className="form-group col-lg-4">
                  <label>Periodo</label>
                    <input
                        type="text"
                        className="form-control form-control-user"
                        id="exampleInputPassword"
                        value={periodo}
                        onChange={(e) => { setPeriodo(e.target.value)}}
                    />
                  </div>
                <div className="form-group col-lg-4">
                <label>Fecha Inicio</label>
                    <input
                        type="date"
                        className="form-control form-control-user"
                        id="exampleInputPassword"
                        value={fecha_inicio}
                        onChange={(e) => { setFecha_Inicio(e.target.value)}}
                    />
                  </div>
                  <div className="form-group col-lg-4">
                  <label>Fecha Fin</label>
                    <input
                        type="date"
                        className="form-control form-control-user"
                        id="exampleInputPassword"
                        value={fecha_final}
                        onChange={(e) => { setFecha_Fin(e.target.value)}}
                    />
                  </div>
                  <br/>
                  <div className="form-group col-lg-12 text-center">
                  <button
                          type="submit"
                          className="text-white btn btn-user"
                          style={{backgroundColor: "#1fad7a"}}
                        >
                          Guardar
                        </button>
                  </div>
                </div>
                </div>
                </div>
              </form>
              
                  
              
                </div>
              
            
          </div>
        </div>
      </div>
    </div>
  );
  } else{
    return(
    <NotFound />
    )
  }
}

export default DetallePeriodo;
