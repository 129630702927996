import React, { useState, useEffect } from "react";
import SidebarE from "../componentes/SidebarE";
import { Link, useParams } from "react-router-dom";
import TopbarE from "../componentes/TopbarE";
import { useNavigate } from "react-router-dom";
import axios, { formToJSON } from "axios";
import AreasdeEmpresa from "./AreasdeEmpresa";
import asp from '../../estilos/img/aspirante.png'
import offi from '../../estilos/img/homeoffice.png'
import SinCuestionario from "./SinCuestionario";
import CuestionariosNom035 from "./CuestionariosNom035";
import Swal from "sweetalert2";

function Cuestionarios() {

  const [aiVisible, setAiVisible] = useState(false);
  const [but, setBut] = useState(true);
  const navigate = useNavigate();
  const [username, setUsername] = useState([]);
  const [message, setMessage] = useState(false);
  const url = "https://ms.bluehand.com.mx/backend/api/v1/estado37";
  const [master] = useState(
    "$2a$12$n1ifj4zomb1OS0sn/lW8COTSd5cV7lKGsgcdbC.ZBtVnAFFGt0.ie"
  );
  const [swalProps, setSwalProps] = useState({});

  let l = localStorage;

  const usuario = l.getItem("id_usuario");

  function showSwal() {
    Swal.fire({
      icon: "info",
        title: "Completado",
        text: "Cuestionario realizado anteriormente, favor de avisar a sus superiores",
        confirmButtonText: "Salir"
    });
  }

  const peticionPost = async () => {
    var f = new FormData();
    f.append("master_key", master);
    f.append("id_usuario", usuario);
    var dataa = formToJSON(f);
    await axios
      .post(url, dataa)
      .then((response) => {
        debugger;
        const r = response.data.result_code;
        const m = response.data.message;
        if(r == 2){
          setMessage(m);
          showSwal();
        }else{
          navigate("/NOM037/15")
        }
        debugger;
        //navigate("/Reporte");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const peticionPost2 = async () => {
    var f = new FormData();
    f.append("master_key", master);
    f.append("id_usuario", usuario);
    var dataa = formToJSON(f);
    await axios
      .post(url, dataa)
      .then((response) => {
        debugger;
        const r = response.data.result_code;
        const m = response.data.message;
        if(r == 2){
          setMessage(m);
          showSwal();
        }else{
          navigate("/NOM037/16")
        }
        debugger;
        //navigate("/Reporte");
      })
      .catch((error) => {
        console.log(error);
      });
  };


debugger
  if (
    l.getItem("id_privilegios") != undefined &&
    l.getItem("id_privilegios") == 6 &&
    l.getItem("cuestionario036") == 0 &&
    l.getItem("cuestionario037") == 1
  ) {
    return (
      <div id="wrapper">
        <SidebarE />
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <TopbarE />
            <div className="container-fluid">
              <div className="d-sm-flex align-items-center justify-content-between mb-4">
                <h4>Bienvenido</h4>
              </div>
              <div className="row mx-auto">   
              <div className="col-sm-5 card shadow mb-4">
                  <div className="card-header py-3">
                    <h6 className="m-1 font-weight-bold text-primary">
                      NOM 037
                    </h6>

                    <h6 className="m-1 font-weight-bold text-primary">
                      {l.getItem("periodo_inicio")} / {l.getItem("periodo_fin")}
                    </h6>
                  </div>
                  <div className="text-center card-body">
                    <p>Preguntas para el trabajador que puede ser aspirante a realizar teletrabajo</p>
                    <img width={"50%"} src={asp}/>
                    <br/><br/>
                    <div className="text-center">
                    <button onClick={peticionPost} className="btn text-white" style={{backgroundColor: '#f05514'}}>Responder</button>
                  </div>
                  </div>
                </div>

                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                <div className="col-sm-5 card shadow mb-4">
                  <div className="card-header py-3">
                    <h6 className="m-1 font-weight-bold text-primary">
                      NOM 037
                    </h6>
                    <h6 className="m-1 font-weight-bold text-primary">
                      {l.getItem("periodo_inicio")} / {l.getItem("periodo_fin")}
                    </h6>
                  </div>
                  <div className="text-center card-body">
                    <p>Preguntas para cuando la persona trabajadora ya realiza teletrabajo</p>
                    <img width={"50%"} src={offi}/>
                    <br/><br/>
                    <div className="text-center">
                  <button onClick={peticionPost2} className="btn text-white" style={{backgroundColor: '#f05514'}}>Responder</button>
                  </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    if (l.getItem("id_privilegios") != undefined &&
    l.getItem("id_privilegios") == 6 &&
    l.getItem("cuestionario036") == 1 &&
    l.getItem("cuestionario037") == 0
    ){
      
      return <AreasdeEmpresa />;
      
    }
    else{
      if (l.getItem("id_privilegios") != undefined &&
    l.getItem("id_privilegios") == 6 &&
    l.getItem("cuestionario036") == 0 &&
    l.getItem("cuestionario037") == 0 &&
    l.getItem("cuestionario035") == 1){
      
      return <CuestionariosNom035 />;
      
    }else{
      if (l.getItem("id_privilegios") != undefined &&
    l.getItem("id_privilegios") == 6 &&
    l.getItem("sincuestionario") == 1){
      
      return <SinCuestionario />;
      
    }

    }
    }
  }
}

export default Cuestionarios;
