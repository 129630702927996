import React from "react";
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';

function SidebarE() {
  const l = localStorage;
    const navigate = useNavigate();


    const salir = async () => {
        l.removeItem("id_privilegios");
        l.removeItem("id_empresa");
        l.removeItem("est");
        l.removeItem("nombre");
        l.removeItem("apellido_materno");
        l.removeItem("apellido_paterno");

        navigate('/');
      };

  return (
    <div className="bg-gradient-danger">
      <div className="sticky-top">
      <ul
        className="navbar-nav bg-gradient-danger sidebar sidebar-dark accordion"
        id="accordionSidebar"
      >
        <Link
          className="sidebar-brand d-flex align-items-center justify-content-center"
          to="/Home"
        >
          <div className="sidebar-brand-icon rotate-n-15">
            <i className="fas fa-laugh-wink"></i>
          </div>
          <div className="sidebar-brand-text mx-3">BH Consulting</div>
        </Link>

        <li className="nav-item active">
          <Link to="/Home" className="nav-link">
            <i className="fas fa-fw fa-tachometer-alt"></i>
            <span>Dashboard</span>
          </Link>
        </li>

        <div className="sidebar-heading">Encuestas</div>

        <li className="nav-item">
          <Link to="/BuzonQuejas" className="nav-link">
            <i className="fas fa-voicemail fa-cog"></i>
            <span>Buzón Anónimo</span>
          </Link>

          <Link to="/NOM035" className="nav-link">
            <i className="fas fa-voicemail fa-cog"></i>
            <span>Prueba</span>
          </Link>

            <button onClick={salir} className="nav-link" style={{backgroundColor: 'transparent', border: 0}}>
              <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray"></i>
            <span className="text-white">Cerrar Sesión</span>
            </button>
          
        </li>                          
        {/*
            <div className="sidebar-heading">
                Áreas de Trabajo
            </div>

            <li className="nav-item">
                <Link to="/AreasTrabajo" className="nav-link">
                <i className="fas fa-fw fa-wrench"></i>
                    <span>Consultar Áreas</span>
                    </Link>
            </li>

            <div className="sidebar-heading">
                Puestos
            </div>

            <li className="nav-item">
                <Link to="/Puestos" className="nav-link">
                <i className="fas fa-fw fa-wrench"></i>
                    <span>Consultar Puestos</span>
                    </Link>
            </li>

            <div className="sidebar-heading">
                Usuarios
            </div>

            <li className="nav-item">
                <Link to="/Usuarios" className="nav-link">
                    <i className="fas fa-fw fa-chart-area"></i>
                    <span>Consultar Usuarios</span></Link>
            </li>

            <div className="sidebar-heading">
                Reportes
            </div>

            <li className="nav-item">
                <Link to="/UltimoReporte" className="nav-link" href="tables.html">
                <i className="fas fa-fw fa-table"></i>
                    <span>Último Generado</span>
                </Link>
            </li>

            <li className="nav-item">
                <Link to="/PorPeriodo" className="nav-link" href="tables.html">
                <i className="fas fa-fw fa-table"></i>
                    <span>Por Periodo</span>
                </Link>
            </li>

            <li className="nav-item">
                <Link to="/" className="nav-link" href="tables.html">
                <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2"></i>
                    <span>Cerrar Sesión</span>
                </Link>
            </li>
  */}
      </ul>
      </div>
    </div>
  );
}

export default SidebarE;
