import React, { useState, useEffect } from 'react';
import axios, { formToJSON } from 'axios';
import { Link } from 'react-router-dom';
import Sidebar from './componentes/Sidebar';
import Topbar from './componentes/Topbar';
import { useParams, useNavigate } from 'react-router-dom';
import SweetAlert2 from "react-sweetalert2";
import NotFound from './NotFound';

function EncuestasPuestos() {
    const [show, setShow] = useState(true);
    const cuestionarios = useParams();

    const url = 'https://ms.bluehand.com.mx/backend/api/v1/api/get-periodos-por-empresa';
    const urlcuestionario = 'https://ms.bluehand.com.mx/backend/api/v1/api/porcentaje-respondido-por-puesto';

  const [master] = useState(
    '$2a$12$n1ifj4zomb1OS0sn/lW8COTSd5cV7lKGsgcdbC.ZBtVnAFFGt0.ie'
  );

  const [listas, setLista] = useState([]);
  const [periodo, setPeriodo] = useState([]);
  const [cuestionario, setCuestionario] = useState([]);
  const [sweet, setSweet] = useState(false);
  const [cues, setCuest] = useState(false);
  const [search, setSearch] = useState(true);
  const [swalProps, setSwalProps] = useState({});
  const [s, setS] = useState({});
  const navigate = useNavigate();

  const listaPost = async () => {
    var f = new FormData();
    f.append('master_key', master);
    f.append("id_empresa", cuestionarios.id_emp);
    var dataa = formToJSON(f);
    await axios
      .post(url, dataa)
      .then((response) => {
        const data = response.data.data;

        const m = response.data.message

        if (m === "La empresa no tiene ningun periodo") {
          setSweet(true);
          setCuest(false);
          setSearch(false);
          debugger;
          showSwal();
        }
        if (m != "La empresa no tiene ningun periodo") {
          setLista(data);
          setSweet(false);
          setCuest(true);
          setSearch(false);
          return;
        }

        setLista(data);

        //navigate("/Reporte");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getCuestionario = async () =>{
    var f = new FormData();
    f.append('master_key', master);
    f.append("id_empresa", cuestionarios.id_emp);
    f.append("id_periodo", periodo);
    f.append("id_puesto", cuestionarios.id)
    
    var dataa = formToJSON(f);
    console.log(dataa);
    await axios
      .post(urlcuestionario, dataa)
      .then((response) => {
        const data = response.data.data;

        setCuestionario(data);
      })
      .catch((error) => {
        console.log(error);
      }); 
  }

  useEffect(() => {
    if(listas == ""){
      listaPost();
    }
  }, []);

  const pasar = async () => {
    navigate(`/Empresas`);
  };
  const [inputValue, setInputValue] = useState('')

  function showSwal() {
    setSwalProps({
      show: true,
      icon: "error",
      title: "Oops...",
      text: "No se tiene Periodos",
      preConfirm: () => {
        pasar();
      },
    });
  }

  let l = localStorage;

  if (l.getItem("id_privilegios") != undefined && l.getItem("id_privilegios") == 1) {

    return (
        <div id="wrapper">
             <Sidebar />
             <div id="content-wrapper" className="d-flex flex-column">
    
                <div id="content">
    
                    <Topbar />
                    <div className="container-fluid">
                    <div className="d-sm-flex align-items-center justify-content-between mb-4">
                            <h1 className="h3 mb-0 text-gray-800">Buscar Cuestionarios</h1>
                    </div>
    
                    <div className='row col-lg-6'>
                     <div>
                         <div className="form">
                             <div id="">
                             <h4>Selecciona el periodo</h4>
                             </div>
                             <br />
                             <div style={{ display: search ? "flex" : "none" }}>
                    Buscando Periodos...
                  </div>
                  <div style={{ display: cues ? "flex" : "none" }}>
                             <select
                             id="comboEmpresa"
                             name="empresaId"
                             className="form-control text-black"
                             onChange={(e) => setPeriodo(e.target.value)}
                             >
                              <option value="value1">--- Selecciona una opcion ---</option>
                              {listas.map((lista) => {
                            return (
                            <option key={lista.id_periodo} value={lista.id_periodo}>
                              {lista.nombre_periodo} {lista.id_periodo}
                              </option>
                              );
                              })}
                                  </select>
                                  <br/>
                                  </div>
                                  <br/>

                                  <button
                                    type="button"
                                    className="d-sm-inline-block btn btn-sm btn-primary shadow-sm"
                                    onClick={() => {
                                    setShow(!show);
                                    getCuestionario()
                                    }}
                                >
                                    Mostrar {show ? 'Cuestionario' : 'Cuestionario'}
                                </button>
                                <br/><br/>
                                <div style={{ display: sweet ? "flex" : "none" }}>
                    <SweetAlert2 {...swalProps} />
                  </div>
                             
                         </div>
                     </div>
                     
                 <div id="wrapper">
                     <div id="content-wrapper" className="d-flex flex-column">
                        <div id="content">
                        </div>
                    </div>
                </div>
                    </div>
                    <div>
                    
                                    {show ? (
                        <div style={{ color: 'red' }}></div>
                    ) : (
                        <div className="card shadow mb-4">
                            <div className="card-header py-3">
                                <h6 className="m-0 font-weight-bold text-primary">
                                DataTables Example
                                </h6>
                            </div>
                            <div className="card-body">
                                <p>Cuestionarios solicitados: {cuestionario.cuestionarios_solicitados}</p>
                                <p>Cuestionarios respondidos: {cuestionario.cuestionarios_respondidos}</p>
                                <p>Porcentaje respondido: {cuestionario.porcentaje_respondidos} %</p>
                                <p>Porcentaje no respondido: {cuestionario.porcentaje_no_respondidos} %</p>
                                
                                <p>Fecha final del periodo: {cuestionario.fecha_fin_periodo}</p>
                                
                                <p>Cantidad de cuestionarios solicitados "Cuestionario 1": {cuestionario.cantidad_cuestionarios_1}</p>
                                <p>Cantidad de cuestionarios contestados: {cuestionario.contestados_cuestionarios_1}</p>
                                <p>Porcentaje: {cuestionario.porcentaje_contestado_1} %</p>

                                <p>Cantidad de cuestionarios solicitados "Cuestionario 2": {cuestionario.cantidad_cuestionarios_2}</p>
                                <p>Cantidad de cuestionarios contestados: {cuestionario.contestados_cuestionarios_2}</p>
                                <p>Porcentaje: {cuestionario.porcentaje_contestado_2} %</p>

                                <p>Cantidad de cuestionarios solicitados "Cuestionario 3": {cuestionario.cantidad_cuestionarios_3}</p>
                                <p>Cantidad de cuestionarios contestados: {cuestionario.contestados_cuestionarios_3}</p>
                                <p>Porcentaje: {cuestionario.porcentaje_contestado_3} %</p>
                             </div>
                            
                            </div>
                    )}
                    </div>
                    </div>
    
    
                </div>
                </div>
            </div>
      )
} else {
  return(
    <NotFound />
  )
}
}

export default EncuestasPuestos;