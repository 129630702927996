import React,{ useState, useEffect } from "react";
import SidebarU from "../componentes/SidebarU.js";
import TopbarU from "../componentes/TopbarU.js";
import { Link } from "react-router-dom";
import axios, {formToJSON} from 'axios';
import DataTable from "react-data-table-component";
import ClipLoader from "react-spinners/ClipLoader";
import NotFound from "../NotFound.js";
import { CSVLink } from "react-csv";

function Areas() {
  let l = localStorage;

  const privilegio = l.getItem("id_privilegios");

  const url = 'https://ms.bluehand.com.mx/backend/api/v1/api/get-all-areas-by-id';
  const [master] = useState(
    '$2a$12$n1ifj4zomb1OS0sn/lW8COTSd5cV7lKGsgcdbC.ZBtVnAFFGt0.ie'
  );
  const [widgets, setWidgets] = useState([]);
  const [aiVisible, setAiVisible] = useState(true);
  const [tableEmp, setTablaEmp] = useState(false);
  const [aiVisible2, setAiVisible2] = useState(true);

  const [search, SetSearch]= useState('');
  const [filter, setFilter]= useState([]);

  const peticionPost = async () => {
    var f = new FormData();
    f.append('master_key', master);
    f.append('id_empresa', empresa);
    var dataa = formToJSON(f);
    await axios
      .post(url, dataa)
      .then((response) => {
        const data = response.data.data;
        const r = response.data.result_code;
        //mensaje
        const mess = response.data.message;

        if (r == 1) {
          setWidgets(data);
          setFilter(data);
          setAiVisible(false);
          setTablaEmp(true)

          if(privilegio == 5){
            setAiVisible2(false);
          }

        }
        if (r === 0) {
          alert(mess);
          return;
        }
        //navigate("/Reporte");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const columns = [
    {
      name: "ID",
      selector: (row) => row.id_area,
    },
    {
      name: "Nombre Área",
      selector: (row) => row.nombre_area,
    },
    {
      name: "Acciones",
      button: true,
      cell: (row) => (
        <>
        <div style={{ display: aiVisible2 ? "flex" : "none" }}>
          <Link className="btn btn-info btn-circle btn-sm" to={`/EncuestasAreas/${row.id_area}/${empresa}`}>
            <i className="fas fa-clipboard"></i>
          </Link>
          &nbsp;
          <Link className="btn btn-danger btn-circle btn-sm">
            <i className="fas fa-trash"></i>
          </Link>
          &nbsp;
          </div>
          <Link to={`/PuestosU/${row.id_area}/${empresa}`} className="btn btn-secondary btn-circle btn-sm">
            <i className="fas fa-eye"></i>
          </Link>
        </>
      ),
    },
  ];

  const customStyles = {
    headCells: {
      style: {
        backgroundColor: "rgba(249, 250, 251, 1)",
        textTransform: "uppercase",
        color: "rgba(107, 114, 128, 1))",
        letterSpacing: "0.05em",
        fontSize: "0.85rem",
        fontWeight: "1000",
        lineHeight: "1rem",
      },
    },
    cells: {
      style: {
        paddingTop: "0.5rem",
        paddingBottom: "0.5rem",
        overflowX: "unset",
      },
    },
    subHeader: {
      style: {
        backgroundColor: "rgba(249, 250, 251, 1)",
        textTransform: "uppercase",
        color: "rgba(107, 114, 128, 1))",
      },
    },
  };

  useEffect(() => {
    if(widgets == ""){
      peticionPost();
    }
  }, []);

  useEffect(()=>{
    const result= widgets.filter((item)=>{
     return item.nombre_area.toLowerCase().match(search.toLocaleLowerCase());
    });
    setFilter(result);
},[search]);

  let empresa = l.getItem("id_empresa");
  
  if (l.getItem("id_privilegios") != undefined && l.getItem("id_privilegios") == 4 || l.getItem("id_privilegios") == 5 ){
    peticionPost();

  return (
    <div id="wrapper">
      <SidebarU />
      <div id="content-wrapper" className="d-flex flex-column">
        <div id="content">
          <TopbarU />
          <div className="container-fluid">
            <div className="d-sm-flex align-items-center justify-content-between mb-4">
              <h1 className="h3 mb-0 text-gray-800">Áreas de trabajo</h1>
            </div>

            <div className="card shadow mb-4">
              <div className="card-header py-3">
              <div className="row">
                  <div className="col-sm-3">
                  <h6 className="m-0 font-weight-bold text-primary">
                  Listado de Áreas de Trabajo
                </h6>
                  </div>
                  <div className="col-sm-9 text-right">
                  <CSVLink data={widgets}><button style={{backgroundColor: "#380079", border: "#380079"}} className="btn btn-primary">Exportar a CSV</button></CSVLink>
                  </div>
                </div>
              </div>
              <div className="card-body">
              <div className="text-center" style={{display : aiVisible ? 'flex' : 'none'}}>
              <ClipLoader
                    
                    size={150}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                    className="mx-auto"
                  />
              </div>
              <div className="card-body" style={{display : tableEmp ? 'flex' : 'none'}}>
                <div className="table-responsive">
                <DataTable
                noDataComponent="Sin Resultados..."
                    responsive
                    striped
                    columns={columns}
                    data={filter}
                    pagination
                    customStyles={customStyles}
                    fixedHeader
                    subHeader
                    subHeaderComponent={
                      <input type="text"
                      className="w-25 form-control"
                      placeholder="Buscar..."
                      value={ search}
                      onChange={(e)=>SetSearch(e.target.value)}
                      
                      />
                    }
                    subHeaderAlign="right"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
} else {
  return(
    <NotFound />
  )
}
}

export default Areas;
