import React,{ useState, useEffect } from "react";
import Sidebar from "./componentes/Sidebar";
import Topbar from "./componentes/Topbar";
import { Link } from "react-router-dom";
import axios, {formToJSON} from 'axios';
import { useParams } from 'react-router-dom';
import NotFound from "./NotFound";


function DetalleEmpresa() {

  let {id} =useParams();
  const url = 'https://ms.bluehand.com.mx/backend/api/v1/api/' + 'get-company-by-id';
  const [master] = useState(
    '$2a$12$n1ifj4zomb1OS0sn/lW8COTSd5cV7lKGsgcdbC.ZBtVnAFFGt0.ie'
  );
  const [widgets, setWidgets] = useState('');

  const peticionPost = async () => {
    var f = new FormData();
    f.append('master_key', master);
    f.append('id_empresa', id);
    var dataa = formToJSON(f);
    await axios
      .post(url, dataa)
      .then((response) => {
        const data = response.data.data;
        setWidgets(data);
        //navigate("/Reporte");
        console.log(widgets);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if(widgets == ""){
      peticionPost();
    }
  }, []);
  let l = localStorage;

  if (l.getItem("id_privilegios") != undefined && l.getItem("id_privilegios") == 1) {
  return (
    <div id="wrapper">
      <Sidebar />
      <div id="content-wrapper" className="d-flex flex-column">
        <div id="content">
          <Topbar />
          <div className="container-fluid">
            <div className="d-sm-flex align-items-center justify-content-between mb-4">
            <Link
                to={`/Empresas`}
                className="text-white d-sm-inline-block btn btn-sm shadow-sm"
                style={{backgroundColor: "#380079", border: "#380079"}}
              >
                <i className="fas fa-arrow-left fa-sm text-white-50"></i> Regresar
              </Link>
            </div>
            <h1 className="h3 mb-0 text-gray-800">Detalle la Empresa</h1>

            <br />

            <div className="container-fluid">
              <form className="user">
                <div className="row">
                  <div className="form-group col-lg-6">
                    <label>Nombre Comercial</label>
                    <input
                        type="text"
                        className="form-control form-control-user"
                        id="nombreUsuario"
                        value={widgets.nombre_comercial}
                    />
                  </div>
                  <div className="form-group col-lg-6">
                    <label>Razón Social</label>
                    <input
                        type="text"
                        className="form-control form-control-user"
                        id="apellidoPaterno"
                        value={widgets.razon_social}
                    />
                  </div>
                  <div className="form-group col-lg-6">
                    <label>RFC</label>
                    <input
                        type="text"
                        className="form-control form-control-user"
                        id="apellidoPaterno"
                        value={widgets.rfc}
                    />
                  </div>
                  <br/>
                  <div className="form-group col-lg-6">
                    <label>Calle y Número</label>
                    <input
                        type="text"
                        className="form-control form-control-user"
                        id="exampleInputEmail"
                        aria-describedby="emailHelp"
                        value={widgets.calle_numero}
                    />
                  </div>
                  <div className="form-group col-lg-6">
                    <label>Colonia</label>
                    <input
                        type="text"
                        className="form-control form-control-user"
                        id="exampleInputPassword"
                        value={widgets.colonia}
                    />
                  </div>
                  <div className="form-group col-lg-6">
                    <label>Estado </label>
                    <input
                        type="text"
                        className="form-control form-control-user"
                        id="exampleInputPassword"
                        value={widgets.estado}
                    />
                  </div>
                  <div className="form-group col-lg-4">
                    <label>Municipio</label>
                    <input
                        type="text"
                        className="form-control form-control-user"
                        id="exampleInputPassword"
                        value={widgets.municipio}
                    />
                  </div>
                  <div className="form-group col-lg-4">
                    <label>Código Postal</label>
                    <input
                        type="text"
                        className="form-control form-control-user"
                        id="exampleInputPassword"
                        value={widgets.codigo_postal}
                    />
                  </div>
                  <div className="form-group col-lg-4">
                    <label>Cantidad de Empleados</label>
                    <input
                        type="number"
                        className="form-control form-control-user"
                        id="exampleInputPassword"
                        value={widgets.cantidad_empleados}
                    />
                  </div>
                  <div className="form-group col-lg-12 text-center">
                    <Link
                        to="/Empresas"
                        className="text-white btn btn-p btn-user btn-sm"
                        style={{border: "#380079"}}
                    >
                      Guardar
                    </Link>
                  </div>
                </div>

              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
} else {
  return(
    <NotFound />
  )
}
}

export default DetalleEmpresa;
