import React, { useState, useEffect } from "react";
import SidebarE from "../componentes/SidebarE";
import { Link,useParams } from "react-router-dom";
import TopbarE from "../componentes/TopbarE";
import { useNavigate } from "react-router-dom";
import axios, { formToJSON } from "axios";
import NotFound from "../NotFound";
import gracias from "../../estilos/img/done.png"

function SinCuestionario() {

  let l = localStorage;

  if (
    l.getItem("id_privilegios") != undefined &&
    l.getItem("id_privilegios") == 6 &&
    l.getItem("sincuestionario") == 1
  ) {
    return (
      <div id="wrapper">
        <SidebarE />
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <TopbarE />
            <div className="container-fluid">
              <div className="d-sm-flex align-items-center justify-content-between mb-4">
                <h4>Bienvenido</h4>
              </div>
              <div className="row mx-auto">
                <div className="col-sm-3"></div>
              <div className="col-sm-6 card shadow mb-4">
                  <div className="card-header py-3">
                    <h6 className="m-1 font-weight-bold text-primary">
                      Sin Cuestionarios
                    </h6>
                  </div>
                  <div className="text-center card-body">
                    <p className="mx-auto"> Usted no tiene ningun cuestionario por responder, está al corriente, gracias.</p>
                    <img className="text-left" width={"80%"} src={gracias}/>
                  </div>
                </div>
                <div className="col-sm-3"></div>
                
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return <NotFound />;
    }
  }

export default SinCuestionario;
