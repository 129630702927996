import React from "react";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import axios, { formToJSON } from "axios";
import "../../estilos/css/reporte.css";
import ClipLoader from "react-spinners/ClipLoader";
import SidebarU from "../componentes/SidebarU";
import TopbarU from "../componentes/TopbarU";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";

function ResultadosNOM035() {

  let l = localStorage;

  const empresa = 220;
  const periodo = 90707;

  console.log(empresa);
  const [loading, setLoading] = useState(true)
  const [html, sethtml] = useState(false)
  const [tableEmp, setTablaEmp] = useState(false);

  const url = "https://ms.bluehand.com.mx/backend/api/v1/api/resultados-cuestionario1-empresa";
  const [master] = useState(
    "$2a$12$n1ifj4zomb1OS0sn/lW8COTSd5cV7lKGsgcdbC.ZBtVnAFFGt0.ie"
  );
  const [widgets, setWidgets] = useState("");  

  const peticionPost = async () => {
    var f = new FormData();
    f.append("master_key", master);
    f.append("id_periodo", periodo);
    f.append("id_empresa", empresa);
    var dataa = formToJSON(f);
    await axios
      .post(url, dataa)
      .then((response) => {
        const data = response.data.data.resultados;
        debugger
        if(data.cuidado_clinico = true) {

        }
        setWidgets(data);
        setLoading(false)
        setTablaEmp(true)
        sethtml(true)
        //navigate("/Reporte");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if(widgets == ""){
      peticionPost();
    }
  }, []);

  const columns = [
    {
      name: "No. Empleado",
      selector: (row) => row.numero_empleado,
    },
    {
      name: "Nombre",
      selector: (row) => row.nombre_empleado,
    },
    {
        name: "Cuidado Clínico",
        selector: (row) => (row.cuidado_clinico ? "Si" : "No")
      },
      
  ];
  

  const customStyles = {
    headCells: {
      style: {
        backgroundColor: "rgba(249, 250, 251, 1)",
        textTransform: "uppercase",
        color: "rgba(107, 114, 128, 1))",
        letterSpacing: "0.05em",
        fontSize: "0.85rem",
        fontWeight: "1000",
        lineHeight: "1rem",
      },
    },
    cells: {
      style: {
        paddingTop: "0.5rem",
        paddingBottom: "0.5rem",
        overflowX: "unset",
      },
    },
    subHeader: {
      style: {
        backgroundColor: "rgba(249, 250, 251, 1)",
        textTransform: "uppercase",
        color: "rgba(107, 114, 128, 1))",
        padding: 16,
      },
    },
  };
  return (
    <div id="wrapper">
      <SidebarU />
      <div id="content-wrapper" className="d-flex flex-column">
        <div id="content">
          <TopbarU />
          <div className="container-fluid">
            <div className="d-sm-flex align-items-center justify-content-between mb-4">
            
            </div>

            <div className="card shadow mb-4">
              <div className="card-header py-3">
                <h6 className="m-0 font-weight-bold text-primary">
                  Cuestionario 1 
                </h6>
              </div>
              <div className="card-body">
              <div className="" style={{display : loading ? 'flex' : 'none'}}>
              <ClipLoader
                    
                    size={150}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                    className="mx-auto"
                  />
              </div>
              <div className="card-body" style={{display : tableEmp ? 'flex' : 'none'}}>
              <div className="table-responsive">
              <DataTable
                noDataComponent="Sin Resultados..."
                    responsive
                    striped
                    columns={columns}
                    data={widgets}
                    pagination
                    customStyles={customStyles}
                  />
                  </div>
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ResultadosNOM035;
