import React,{ useState, useEffect } from "react";
import Sidebar from "./componentes/Sidebar.js";
import Topbar from "./componentes/Topbar.js";
import { Link } from "react-router-dom";
import { useParams, useNavigate } from 'react-router-dom';
import axios, {formToJSON} from 'axios';
import SweetAlert2 from 'react-sweetalert2';
import NotFound from "./NotFound.js";


function EncuestasEmpresa() {

    const cuestionarios = useParams();
    
    const navigate = useNavigate();

    const url2 =
    "https://ms.bluehand.com.mx/backend/api/v1/api/porcentaje-respondido-por-empresa";
  const [master] = useState(
    "$2a$12$n1ifj4zomb1OS0sn/lW8COTSd5cV7lKGsgcdbC.ZBtVnAFFGt0.ie"
  );
  const [listas, setLista] = useState([]);

  const listaPost = async () => {
    var f = new FormData();
    f.append("master_key", master);
    f.append("id_empresa", cuestionarios.id);
    f.append("id_periodo", cuestionarios.periodo);
    var dataa = formToJSON(f);
    await axios
      .post(url2, dataa)
      .then((response) => {
        const data = response.data.data;

        setLista(data);
        //navigate("/Reporte");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const pasar = async () =>{
    navigate(`/BuscadorEncuestas/${cuestionarios.id}`)
  }

  useEffect(() => {
    if(listas == ""){
      listaPost();
    }
  }, []);

  let l = localStorage;

  if (l.getItem("id_privilegios") != undefined && l.getItem("id_privilegios") == 1) {

  return (
    <div id="wrapper">
      <Sidebar />
      <div id="content-wrapper" className="d-flex flex-column">
        <div id="content">
          <Topbar />

          <div className="container-fluid">
            <div className="d-sm-flex align-items-center justify-content-between mb-4">
            <Link
                to={`/BuscadorEncuestas/${cuestionarios.id}`}
                className="d-sm-inline-block btn btn-sm btn-primary shadow-sm"
              >
                <i className="fas fa-arrow-left fa-sm text-white-50"></i> Regresar
              </Link>
            </div>

            <div className="card shadow mb-4">
              <div className="card-header py-3">
                <h6 className="m-0 font-weight-bold text-primary">
                  Información sobre los cuestionarios 
                </h6>
              </div>
              <div className="card-body">
              <form className="user">
                <div className="row">
                  <div className="form-group col-lg-3">
                    <label>Cuestionarios Solicitados</label>
                    <p>{listas.cuestionarios_solicitados}</p>
                  </div>
                  <div className="form-group col-lg-3">
                  <label>Cuestionarios Respondidos</label>
                    <p>{listas.cuestionarios_respondidos}</p>
                  </div>
                  <br />
                  <div className="form-group col-lg-3">
                  <label>Porcuentaje de respondidos</label>
                    <p>{listas.porcentaje_respondidos}%</p>
                  </div>
                  <div className="form-group col-lg-3">
                  <label>Porcentaje de no respondidos</label>
                    <p>{listas.porcentaje_no_respondidos}%</p>
                  </div>
                  <div className="form-group col-lg-3">
                  <label>Fin del Periodo</label>
                    <p>{listas.fecha_fin_periodo}</p>
                  </div>
                  <div className="form-group col-lg-3">
                  <label>Cantidad Total de Cuestionarios</label>
                    <p>{listas.cantidad_cuestionarios_1}</p>
                  </div>
                </div>
                
              </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );}
    else {
      return(
        <NotFound />
      )
    }
}

export default EncuestasEmpresa;
