import React, { useState, useEffect } from "react";
import "../estilos/css/sb-admin-2.css";
import "../estilos/vendor/fontawesome-free/css/all.min.css";
import { useAuthContext } from "./context/authContext";
import axios, { formToJSON } from "axios";
import { useNavigate } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import md5 from "md5";
import { Link } from "react-router-dom";
import logo from "../estilos/img/docc.png"

function Login() {
  let l = localStorage;

  console.log(l.getItem('id_usuario'));
  console.log(l.getItem('email'));
  console.log(l.getItem('estado'));
  const { login } = useAuthContext();
  const [aiVisible, setAiVisible] = useState(false);
  const [but, setBut] = useState(true);
  const [username, setUsername] = useState([]);
  const [password, setPassword] = useState([]);
  const navigate = useNavigate();
  const [message, setMessage] = useState(false);
  const url2 = "https://ms.bluehand.com.mx/backend/api/v1/password";
  const [master] = useState(
    "$2a$12$n1ifj4zomb1OS0sn/lW8COTSd5cV7lKGsgcdbC.ZBtVnAFFGt0.ie"
  );

  const peticionPost = async () => {
    var f = new FormData();
    f.append("master_key", master);
    f.append("email", l.getItem('email'));    
    f.append("password", md5(password));
    f.append("id_usuario", l.getItem('id_usuario'));
    var dataa = formToJSON(f);
    await axios
      .post(url2, dataa)
      .then((response) => {
        const data = response.data.data;
        const r = response.data.result_code;
        debugger;
        if (r == "1") {
          navigate('/');
        } else {
          if (r == "2") {
            setMessage(true);
            setAiVisible(false);
            setBut(true);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  function handleInputPChange(event) {
    setPassword(event.target.value);
  }

  function handleSubmit(event) {
    event.preventDefault();
    setBut(false);
    setAiVisible(true);
    peticionPost();
  }

  return (
    <div
      className="container col-lg-12"
      style={{ height: 749 }}
    >
      <br />
      <br />
      <br />
      <div className="row justify-content-center">
        <div className="col-xl-3 col-lg-6 col-md-9">
          <div className="card o-hidden border-0 shadow-lg my-5">
            <div className="card-body p-0">
              <div className="row">
                <div className="col-lg-12">
                  <div className="p-5">
                    <div className="text-center">
                    <div className="text-center">
                      <img width={250} src={logo}/>
                    </div>
                    <br />
                      <p style={{fontSize: 20}} className="text-center h4 text-gray-900 mb-4">
                        Restablecer Contraseña
                      </p>
                    </div>
                    <form className="user" onSubmit={handleSubmit}>
                      <div className="form-group">
                        
                      </div>
                      <div className="form-group">
                        <input
                          type="password"
                          className="form-control form-control-user"
                          id="exampleInputPassword"
                          placeholder="Nueva Contraseña"
                          value={password}
                          onChange={handleInputPChange}
                        />
                      </div>
                      <br/>
                      <br/>
                      <div
                        className=""
                        style={{ display: aiVisible ? "flex" : "none" }}
                      >
                        <ClipLoader
                          size={20}
                          aria-label="Loading Spinner"
                          data-testid="loader"
                          className="mx-auto"
                        />
                      </div>
                      <div style={{ display: but ? "flex" : "none" }}>
                        <button
                          type="submit"
                          className="btn btn-p btn-user btn-block"
                          style={{border: "#380079"}}
                        >
                          Restablecer
                        </button>
                      </div>
                    </form>
                    <hr />
                    <div style={{ display: message ? "flex" : "none" }}>
                      <p className="mx-auto text-danger">
                        Usuario o Contraseña Incorrectos
                      </p>
                    </div>
                    <div className="text-center">
                      <a className="small" href="forgot-password.html">
                        {" "}
                        &nbsp;
                      </a>
                    </div>
                    <div className="text-center">
                      <a className="small" href="register.html">
                        {" "}
                        &nbsp;
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
