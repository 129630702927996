import React, { useState, useEffect } from "react";
import SidebarE from "../componentes/SidebarE.js";
import { Link, useParams, useLocation, useNavigate } from "react-router-dom";
import TopbarE from "../componentes/TopbarE.js";
import axios, { formToJSON } from "axios";
import { Navigate } from "react-router-dom";
import SweetAlert2 from "react-sweetalert2";
import Swal from "sweetalert2";
import NotFound from "../NotFound.js";
import mg from "../../estilos/img/icono1-bg.png";

function NOM036() {
  let l = localStorage;

  let { id } = useParams();
  let id_cuestionario = 1;
  let usu = l.getItem("id_encuestado");

  //let {estado} =useParams();
  let state = useLocation();

  const url = "https://ms.bluehand.com.mx/backend2/api/v1/traernom36";
  const url2 = "https://ms.bluehand.com.mx/backend2/api/v1/contestarnom36";
  const [master] = useState(
    "$2a$12$n1ifj4zomb1OS0sn/lW8COTSd5cV7lKGsgcdbC.ZBtVnAFFGt0.ie"
  );
  const [widgets, setWidgets] = useState([]);
  const [aiVisible, setAiVisible] = useState(true);
  const [terminado, setTerminado] = useState(false);
  const [ayuda, setAyuda] = useState(false);
  const estado = l.getItem("esto");
  const [resp, setResp] = useState();
  const [mensaje, setMensaje] = useState(false);
  const [array, setArray] = useState([]);
  const [swalProps, setSwalProps] = useState({});
  const [prueba, setPrueba] = useState([]);

  const navigate = useNavigate();

  const peticionPost = async () => {
    debugger;
    var f = new FormData();
    f.append("master_key", master);
    f.append("seccion", id);
    var dataa = formToJSON(f);
    await axios
      .post(url, dataa)
      .then((response) => {
        debugger;
        const data = response.data.data[0];
        const q = response.data.data[0].questions;

        setWidgets(data);
        setPrueba(q);
        debugger;
        //navigate("/Reporte");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (widgets == "") {
      peticionPost();
    }
  }, []);

  const respuestas = async () => {
    debugger;
    await axios
      .post(url2, array)
      .then((response) => {
        debugger;
        const r = response.result_code;
        console.log(r);
        l.removeItem("id_encuestado");
        showSwal();
        //navigate("/Reporte");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  console.log(widgets);
  console.log(prueba);

  const [imagenActual, setImagenActual] = React.useState(0);
  const cantidad = widgets?.length;

  const pasar = async () => {
    navigate(`/Cuestionarios`);
    respuestas();
  };

  function showSwal() {
    setSwalProps({
      show: true,
      icon: "success",
      title: "Completado",
      text: "Gracias por participar en la encuesta es muy valioso para poder ayudarte y mejorar nuestro ambiente de trabajo",
      confirmButtonText: "Salir",
      preConfirm: () => {
        pasar();
      },
    });
  }

  const arr = [];

  const formRef = React.useRef(null);

  const siguienteImagen = (e) => {
    debugger;
    e.preventDefault();

    if (formRef.current.seccion.value == 17) {
      if (resp == "20" || resp == undefined) {
        setMensaje(true);
      } else {
        if (formRef.current.hiddenInput2.value == 7) {
          setAiVisible(false);
          setTerminado(true);
        }

        if (formRef.current.hiddenInput2.value != 8) {
          const obj = {
            id_pregunta: formRef.current.hiddenInput.value,
            num_pregunta: formRef.current.hiddenInput2.value,
            respuesta: resp,
            id_seccion: formRef.current.seccion.value,
            id_usuario: usu
          };
          setMensaje(false);
          setResp();
          array.push(obj);
          setImagenActual(imagenActual === cantidad - 1 ? 0 : imagenActual + 1);
        } else {
          if (formRef.current.hiddenInput2.value == 8) {
            const obj = {
              id_pregunta: formRef.current.hiddenInput.value,
              num_pregunta: formRef.current.hiddenInput2.value,
              respuesta: resp,
              id_seccion: formRef.current.seccion.value,
              id_usuario: usu
            };
            setMensaje(false);
            setResp();
            array.push(obj);
            respuestas();
          }
        }
      }
    }

    if (formRef.current.seccion.value == 18) {
      if (resp == "20" || resp == undefined) {
        setMensaje(true);
      } else {
        if (formRef.current.hiddenInput2.value == 7) {
          setAiVisible(false);
          setTerminado(true);
        }

        if (formRef.current.hiddenInput2.value != 8) {
          const obj = {
            id_pregunta: formRef.current.hiddenInput.value,
            num_pregunta: formRef.current.hiddenInput2.value,
            respuesta: resp,
            id_seccion: formRef.current.seccion.value,
            id_usuario: usu
          };
          setMensaje(false);
          setResp();
          array.push(obj);
          setImagenActual(imagenActual === cantidad - 1 ? 0 : imagenActual + 1);
        } else {
          if (formRef.current.hiddenInput2.value == 8) {
            const obj = {
              id_pregunta: formRef.current.hiddenInput.value,
              num_pregunta: formRef.current.hiddenInput2.value,
              respuesta: resp,
              id_seccion: formRef.current.seccion.value,
              id_usuario: usu
            };
            setMensaje(false);
            setResp();
            array.push(obj);
            respuestas();
          }
        }
      }
    } else {
      if (formRef.current.seccion.value == 18) {
        if (resp == "20" || resp == undefined) {
          setMensaje(true);
        } else {
          if (formRef.current.hiddenInput2.value == 8) {
            setAiVisible(false);
            setTerminado(true);
          }

          if (formRef.current.hiddenInput2.value != 9) {
            const obj = {
              id_pregunta: formRef.current.hiddenInput.value,
              num_pregunta: formRef.current.hiddenInput2.value,
              respuesta: resp,
              id_seccion: formRef.current.seccion.value,
              id_usuario: usu
            };
            setMensaje(false);
            setResp();
            array.push(obj);
            setImagenActual(
              imagenActual === cantidad - 1 ? 0 : imagenActual + 1
            );
          } else {
            if (formRef.current.hiddenInput2.value == 9) {
              const obj = {
                id_pregunta: formRef.current.hiddenInput.value,
                num_pregunta: formRef.current.hiddenInput2.value,
                respuesta: resp,
                id_seccion: formRef.current.seccion.value,
                id_usuario: usu
              };
              setMensaje(false);
              setResp();
              array.push(obj);
              respuestas();
            }
          }
        }
      } else {
        if (formRef.current.seccion.value == 19) {
          if (resp == "20" || resp == undefined) {
            setMensaje(true);
          } else {
            if (formRef.current.hiddenInput2.value == 8) {
              setAiVisible(false);
              setTerminado(true);
            }
  
            if (formRef.current.hiddenInput2.value != 9) {
              const obj = {
                id_pregunta: formRef.current.hiddenInput.value,
                num_pregunta: formRef.current.hiddenInput2.value,
                respuesta: resp,
                id_seccion: formRef.current.seccion.value,
                id_usuario: usu
              };
              setMensaje(false);
              setResp();
              array.push(obj);
              setImagenActual(
                imagenActual === cantidad - 1 ? 0 : imagenActual + 1
              );
            } else {
              if (formRef.current.hiddenInput2.value == 9) {
                const obj = {
                  id_pregunta: formRef.current.hiddenInput.value,
                  num_pregunta: formRef.current.hiddenInput2.value,
                  respuesta: resp,
                  id_seccion: formRef.current.seccion.value,
                  id_usuario: usu
                };
                setMensaje(false);
                setResp();
                array.push(obj);
                respuestas();
              }
            }
          }
        }
        else{
          if (formRef.current.seccion.value == 20) {
            if (resp == "20" || resp == undefined) {
              setMensaje(true);
            } else {
              if (formRef.current.hiddenInput2.value == 7) {
                setAiVisible(false);
                setTerminado(true);
              }
    
              if (formRef.current.hiddenInput2.value != 8) {
                const obj = {
                  id_pregunta: formRef.current.hiddenInput.value,
                  num_pregunta: formRef.current.hiddenInput2.value,
                  respuesta: resp,
                  id_seccion: formRef.current.seccion.value,
                  id_usuario: usu
                };
                setMensaje(false);
                setResp();
                array.push(obj);
                setImagenActual(
                  imagenActual === cantidad - 1 ? 0 : imagenActual + 1
                );
              } else {
                if (formRef.current.hiddenInput2.value == 8) {
                  const obj = {
                    id_pregunta: formRef.current.hiddenInput.value,
                    num_pregunta: formRef.current.hiddenInput2.value,
                    respuesta: resp,
                    id_seccion: formRef.current.seccion.value,
                    id_usuario: usu
                  };
                  setMensaje(false);
                  setResp();
                  array.push(obj);
                  respuestas();
                }
              }
            }
          } else{
            if (formRef.current.seccion.value == 21) {
              if (resp == "20" || resp == undefined) {
                setMensaje(true);
              } else {
                if (formRef.current.hiddenInput2.value == 8) {
                  setAiVisible(false);
                  setTerminado(true);
                }
      
                if (formRef.current.hiddenInput2.value != 9) {
                  const obj = {
                    id_pregunta: formRef.current.hiddenInput.value,
                    num_pregunta: formRef.current.hiddenInput2.value,
                    respuesta: resp,
                    id_seccion: formRef.current.seccion.value,
                    id_usuario: usu
                  };
                  setMensaje(false);
                  setResp();
                  array.push(obj);
                  setImagenActual(
                    imagenActual === cantidad - 1 ? 0 : imagenActual + 1
                  );
                } else {
                  if (formRef.current.hiddenInput2.value == 9) {
                    const obj = {
                      id_pregunta: formRef.current.hiddenInput.value,
                      num_pregunta: formRef.current.hiddenInput2.value,
                      respuesta: resp,
                      id_seccion: formRef.current.seccion.value,
                      id_usuario: usu
                    };
                    setMensaje(false);
                    setResp();
                    array.push(obj);
                    respuestas();
                  }
                }
              }
            }
          }
        }
      }
    }
  };

  const anteriorImagen = () => {
    setImagenActual(imagenActual === 0 ? cantidad - 1 : imagenActual - 1);
  };

  debugger

  if (
    l.getItem("id_privilegios") != undefined &&
    l.getItem("id_privilegios") == 6
  ) {
    return (
      <div id="wrapper">
        <SidebarE />
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <TopbarE />
            <div className="container-fluid">
              <div className="d-sm-flex align-items-center justify-content-between mb-4">
                <h4>NOM 036</h4>
              </div>
              <div className="row">
                <div className="col-sm-6">
                  {Array.isArray(prueba)
                    ? prueba.map((element, index) => {
                        return (
                          <div
                            className={
                              imagenActual === index ? `slide active` : "slide"
                            }
                          >
                            {imagenActual === index && (
                              <>
                                <div
                                  key={index}
                                  className="col-sm-12 card shadow mb-4"
                                >
                                  <div className="card-header py-3">
                                    <h6 className="m-1 font-weight-bold text-primary">
                                      NOM 036 - {widgets.name}
                                      <br />
                                      {widgets.description}
                                    </h6>
                                  </div>
                                  <div className="card-body">
                                    {element.question_number}.-{" "}
                                    {element.question}

                                    <div className="row">
                                      <form
                                        onSubmit={siguienteImagen}
                                        ref={formRef}
                                      >
                                        <div className="col-lg-6">
                                          <input
                                            name="hiddenInput"
                                            type="hidden"
                                            value={element.question_id}
                                          />
                                          <input
                                            name="hiddenInput2"
                                            type="hidden"
                                            value={element.question_number}
                                          />
                                          <input
                                            name="seccion"
                                            type="hidden"
                                            value={widgets.id_seccion}
                                          />
                                          <br />
                                          <select
                                            id="comboEmpresa"
                                            name="empresaId"
                                            className="form-control text-black"
                                            onChange={(e) =>
                                              setResp(e.target.value)
                                            }
                                          >
                                            <option value="20">
                                              --- Selecciona una opcion ---
                                            </option>
                                            {element.options.map((op) => {
                                              return (
                                                <option value={op.option_id}>
                                                  {op.question_option}
                                                </option>
                                              );
                                            })}
                                          </select>
                                        </div>
                                        <span>
                                          <br />
                                          <div className="row mx-auto">
                                            <div
                                              className="col-sm-6 text-left"
                                              style={{
                                                display: aiVisible
                                                  ? "flex"
                                                  : "none",
                                              }}
                                            >
                                              <button
                                                type="submit"
                                                className="d-sm-inline-block btn btn-sm btn-primary shadow-sm"
                                              >
                                                Siguiente{" "}
                                                <i className="fas fa-arrow-right fa-sm text-white-50"></i>
                                              </button>
                                            </div>
                                            <div
                                            className="col-sm-6 text-left"
                                            style={{
                                              display: terminado
                                                ? "flex"
                                                : "none",
                                            }}
                                          >
                                            <button
                                              type="submit"
                                              className="d-sm-inline-block btn btn-sm btn-primary shadow-sm"
                                            >
                                              Finalizar{" "}
                                              <i className="fas fa-check fa-sm text-white-50"></i>
                                            </button>
                                          </div>
                                            <div className=" col-sm-6 text-right">
                                              <a
                                            type="link"
                                              className="btn btn-sm btn-link"
                                              onClick={() => {
                                                Swal.fire({
                                                  text: element.help_text ? element.help_text : "Sin Comentarios"
                                                });
                                              }}
                                            >
                                              Ayuda{" "}
                                              <i className="fas fa-check fa-sm text-white-50"></i>
                                            </a>
                                              </div>
                                          </div>
                                          <pre>
                                            {JSON.stringify(array, null, 2)}
                                          </pre>
                                          <div
                                            className="col-sm-12 mx-auto text-danger"
                                            style={{
                                              display: mensaje
                                                ? "flex"
                                                : "none",
                                            }}
                                          >
                                            Favor de seleccionar una respuesta.
                                          </div>
                                        </span>
                                      </form>
                                      <div className="col-lg-6"></div>
                                    </div>
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        );
                      })
                    : null}
                </div>

                <div className="col-sm-6">
                  {Array.isArray(prueba)
                    ? prueba.map((element, index) => {
                        return (
                          <div
                            className={
                              imagenActual === index ? `slide active` : "slide"
                            }
                          >
                            {imagenActual === index && (
                              <>
                                <div
                                  key={index}
                                  className="col-sm-12 card shadow mb-4"
                                >
                                  <div className="card-header py-3">
                                    <h6 className="m-1 font-weight-bold text-primary">
                                      Ejemplo
                                      <br />
                                    </h6>
                                  </div>
                                  <div
                                    className="card-body"
                                    style={{ textAlign: "justify" }}
                                  >
                                    <div className="row">
                                      <img
                                        onClick={() => {
                                          Swal.fire({
                                            imageUrl: element.upload_photo,
                                            imageWidth: 900,
                                            imageHeight: 300,
                                            width: 580,
                                          });
                                        }}
                                        src={element.upload_photo}
                                        border="0"
                                        style={{ width: 472 }}
                                      />
                                      <span>
                                        <br />
                                      </span>

                                      <div className="col-lg-6"></div>
                                    </div>
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        );
                      })
                    : null}
                </div>
              </div>
              <SweetAlert2 {...swalProps} />
              &nbsp;&nbsp;&nbsp;&nbsp;
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return <NotFound />;
  }
}

export default NOM036;
