import React, { useState, useEffect } from "react";
import SidebarE from "../componentes/SidebarE.js";
import TopbarE from "../componentes/TopbarE.js";
import { Link } from "react-router-dom";
import { useParams } from 'react-router-dom';
import axios, {formToJSON} from 'axios';
import DataTable from "react-data-table-component";
import ClipLoader from "react-spinners/ClipLoader";
import NotFound from "../NotFound";

function UsuariosPuesto() {
  let {id} = useParams();

  const url = 'https://ms.bluehand.com.mx/backend/api/v1/usuariopuesto';
  const [master] = useState(
    '$2a$12$n1ifj4zomb1OS0sn/lW8COTSd5cV7lKGsgcdbC.ZBtVnAFFGt0.ie'
  );
  const [widgets, setWidgets] = useState([]);
  const [aiVisible, setAiVisible] = useState(true);
  const [tableEmp, setTablaEmp] = useState(false);

  const peticionPost = async () => {
    var f = new FormData();
    f.append('master_key', master);
    f.append('id_puesto', id);
    var dataa = formToJSON(f);
    await axios
      .post(url, dataa)
      .then((response) => {
        const data = response.data.data;
        const r = response.data.result_code;
        //mensaje 
        const mess = response.data.message;
        if (r == 1) {
          setWidgets(data);
          setAiVisible(false);
          
          setTablaEmp(true)
        }
        if (r === 0) {
          alert(mess);
          return;
        }
        //navigate("/Reporte");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  let l = localStorage;

  const columns = [
    {
      name: "ID",
      selector: (row) => row.id_usuario,
    },
    {
      name: "Nombre",
      selector: (row) => row.nombre,
    },
    {
        name: "Apellido Paterno",
        selector: (row) => row.nombre,
      },
      {
        name: "Apellido Materno",
        selector: (row) => row.nombre,
      },
      {
        name: "Teléfono",
        selector: (row) => row.telefono,
      },
    {
      name: "Acciones",
      button: true,
      cell: (row) => (
        <>
        <div>
        { row.estado == 0  ? <Link to={`/Apendices036/${row.id_usuario}`} className="btn text-white btn-circle btn-sm" style={{backgroundColor: "#5bc3d0"}}>
            <i className="fas fa-eye"></i>
          </Link>  : "Ya respondió" }
        </div>
        </>
      ),
    },
  ];

  const customStyles = {
    headCells: {
      style: {
        backgroundColor: "rgba(249, 250, 251, 1)",
        textTransform: "uppercase",
        color: "rgba(107, 114, 128, 1))",
        letterSpacing: "0.05em",
        fontSize: "0.85rem",
        fontWeight: "1000",
        lineHeight: "1rem",
      },
    },
    cells: {
      style: {
        paddingTop: "0.5rem",
        paddingBottom: "0.5rem",
        overflowX: "unset",
      },
    },
    subHeader: {
      style: {
        backgroundColor: "rgba(249, 250, 251, 1)",
        textTransform: "uppercase",
        color: "rgba(107, 114, 128, 1))",
        padding: 16,
      },
    },
  };

  useEffect(() => {
    if(widgets == ""){
      peticionPost();
      console.log("sijaja");
    }
  }, []);

  if (l.getItem("id_privilegios") != undefined && l.getItem("id_privilegios") == 6 ) {
    return (
      <div id="wrapper">
        <SidebarE />
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <TopbarE />
            <div className="container-fluid">
              <div className="d-sm-flex align-items-center justify-content-between mb-4">
              <Link to={`/Cuestionarios`} style={{backgroundColor: '#f05514'}} className="text-white d-sm-inline-block btn btn-sm shadow-sm">
                  <i className="fas fa-arrow-left fa-sm text-white-50"></i> Regresar
                </Link>
                <p></p>
              </div>
  
              <div className="card shadow mb-4">
                <div className="card-header py-3">
                  <h6 className="m-0 font-weight-bold text-primary">
                    Lista de Usuarios
                  </h6>
                </div>
                <div className="card-body">
                <div className="text-center" style={{display : aiVisible ? 'flex' : 'none'}}>
                <ClipLoader
                      
                      size={150}
                      aria-label="Loading Spinner"
                      data-testid="loader"
                      className="mx-auto"
                    />
                </div>
                <div className="card-body" style={{display : tableEmp ? 'flex' : 'none'}}>
                  <div className="table-responsive">
                  <DataTable
                  noDataComponent="Sin Resultados..."
                      responsive
                      striped
                      columns={columns}
                      data={widgets}
                      pagination
                      customStyles={customStyles}
                    />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  else{
    return(
    <NotFound />
    )
  }
  
}

export default UsuariosPuesto;
