import React,{ useState, useEffect } from "react";
import SidebarU from "../componentes/SidebarU";
import TopbarU from "../componentes/TopbarU.js";
import { Link, useNavigate } from "react-router-dom";
import axios, {formToJSON} from 'axios';
import { useParams } from 'react-router-dom';
import NotFound from "../NotFound";
import SweetAlert2 from "react-sweetalert2";
import md5 from "md5";

function CrearUsuario() {

    let {id} =useParams();

    let l = localStorage;

    const url = 'https://ms.bluehand.com.mx/backend/api/v1/api/get-all-areas-by-id';
  const url2 = 'https://ms.bluehand.com.mx/backend/api/v1/crearusuario';
  const url3 = 'https://ms.bluehand.com.mx/backend/api/v1/api/get-all-positions-by-id';
  const url4 = 'https://ms.bluehand.com.mx/backend/api/v1/getprivilegios';
  const [master] = useState(
    '$2a$12$n1ifj4zomb1OS0sn/lW8COTSd5cV7lKGsgcdbC.ZBtVnAFFGt0.ie'
  );
  const [listas, setLista] = useState([]);
  const [widgets, setWidgets] = useState([]);
  const [swalProps, setSwalProps] = useState({});
  const [nombre, setNombre] = useState('');
  const [apellidoP, setApellidoP] = useState('');
  const [apellidoM, setApellidoM] = useState('');
  const [area, setArea] = useState('');
  const [puesto, setPuesto] = useState('');
  const [fechaN, setFechaN] = useState('');
  const [estadoCivil, setEstadoCivil] = useState('');
  const [tipoContrato, setTipoContrato] = useState('');
  const [experienciaL, setExperienciaL] = useState('');
  const [tiempoPuesto, setTiempoPuesto] = useState('');
  const [tiempoEmpresa, setTiempoEmpresa] = useState('');
  const [tipoJornada, setTipoJornada] = useState('');
  const [rotacionTurno, setRotacionTurno] = useState('');
  const [curp, setCurp] = useState('');
  const [telefono, setTelefono] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [genero, setGenero] = useState('');
  const [numEmpleado, setNumEmpleado] = useState('');
  const [tipoUsuario, setTipoUsuario] = useState('');
  const [privilegios, setPrivilegios] = useState([]);

  const navigate = useNavigate();

  const listaPost = async () => {
debugger
    var f = new FormData();
    f.append('master_key', master);
    f.append('id_empresa', id);
    var dataa = formToJSON(f);
    await axios
      .post(url, dataa)
      .then((response) => {
        const data = response.data.data;

        setLista(data);
        //navigate("/Reporte");
      })
      .catch((error) => {
        console.log(error);
      });
  };  

  const usuariosGet = async () => {
    debugger
        await axios
          .get(url4,)
          .then((response) => {
            const data = response.data.data;
    
            setPrivilegios(data);
            //navigate("/Reporte");
          })
          .catch((error) => {
            console.log(error);
          });
      };  

  function showSwal() {
    setSwalProps({
      show: true,
      icon: "warning",
      title: "No cuenta con puestos",
      text: "Favor de registrar por lo menos un puesto de trabajo antes de crear un usuario",
      confirmButtonText: "Salir",
      preConfirm: () => {
        pasar();
      },
    });
  }

  const pasar = async () => {
    navigate(`/Usuarios/${id}`);
  };

  let arr = [];

  const peticionPost = async (e) => {
    var f = new FormData();
    f.append('master_key', master);
    f.append('id_area', e);
    var dataa = formToJSON(f);
    await axios
      .post(url3, dataa)
      .then((response) => {
        const data = response.data.data;
        const r = response.data.result_code;
        //mensaje
        const mess = response.data.message;

        setWidgets(data);
        debugger
        if (mess == "El area no tiene ningun puesto asignado") {
          setWidgets([{"id_puesto": -1, "nombre_puesto": "-- No cuenta con Puestos"}]);
          showSwal()
        }else{
          setWidgets(data)
        }
        if (r === 0) {
          alert(mess);
          return;
        }
        //navigate("/Reporte");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  console.log(arr)

  useEffect(() => {
    if(listas == "" && privilegios == ""){
      listaPost();
      usuariosGet();
    }
  }, []);

  function handleArea(event) {
    const area = event.target.value;

    peticionPost(area);
    setArea(area);
  }

  function handleTipU(event) {
    const tipoU = event.target.value;

    setTipoUsuario(tipoU);
  }

  console.log(tipoUsuario)

  const Registrar = async () => {
    debugger
    var f = new FormData();
    f.append("master_key", master);
    f.append("id_empresa", id);
    f.append("id_puesto", puesto);
    f.append("id_area", area);
    f.append("num_empleado", numEmpleado);
    f.append("nombre", nombre);
    f.append("apellido_paterno", apellidoP);
    f.append("apellido_materno", apellidoM);
    f.append("genero", genero);
    f.append("fecha_nacimiento", fechaN);
    f.append("estado_civil", estadoCivil);
    f.append("tipo_contrato", tipoContrato);
    f.append("experiencia_laboral", experienciaL);
    f.append("tiempo_en_empresa", tiempoEmpresa);
    f.append("tiempo_en_puesto", tiempoPuesto);
    f.append("tipo_jornada", tipoJornada);
    f.append("rotacion_turno", rotacionTurno);
    f.append("curp", curp);
    f.append("telefono", telefono);
    f.append("correo_electronico", email);
    f.append("password", md5(password));
    f.append("id_privilegios", tipoUsuario);
    var dataa = formToJSON(f);
    
    await axios
      .post(url2, dataa)
      .then((response) => {
        debugger
        const r = response.data.result_code;
        if (r == "1") {
          navigate(`/Usuarios/${id}`)
        } else {
          if (r == "2") {
            console.log("nono")
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  function handleSubmit(event) {
    event.preventDefault();
  Registrar();
  }

    if (l.getItem("id_privilegios") != undefined && l.getItem("id_privilegios") == 4 || l.getItem("id_privilegios") == 5) {

  return (
    <div id="wrapper">
      <SidebarU />
      <div id="content-wrapper" className="d-flex flex-column">
        <div id="content">
          <TopbarU />
          <div className="container-fluid">
            <div className="d-sm-flex align-items-center justify-content-between mb-4">
            <Link
            style={{backgroundColor: "#d6248c"}}
                to={`/Usuarios/${id}`} className="d-sm-inline-block btn btn-sm text-white shadow-sm"
              >
                <i className="fas fa-arrow-left fa-sm text-white-50"></i> Regresar
              </Link>
            </div>
            <h1 className="h3 mb-0 text-gray-800">Alta de Usuarios</h1>

            <br />

            <div className="container-fluid">
            <form className="user" onSubmit={handleSubmit}>
                <div className="row">
                  <div className="form-group col-lg-4">
                    <label>Nombre</label>
                    <input
                      type="text"
                      className="form-control form-control-user"
                      id="nombreUsuario"
                      onChange={(e) =>
                        setNombre(e.target.value)}
                    />
                  </div>
                  <div className="form-group col-lg-4">
                  <label>Apellido Paterno</label>
                    <input
                      type="text"
                      className="form-control form-control-user"
                      id="apellidoPaterno"
                      onChange={(e) =>
                        setApellidoP(e.target.value)}
                    />
                  </div>
                  <br />
                  <div className="form-group col-lg-4">
                  <label>Apellido Materno</label>
                    <input
                      type="text"
                      className="form-control form-control-user"
                      id="exampleInputEmail"
                      aria-describedby="emailHelp"
                      onChange={(e) =>
                        setApellidoM(e.target.value)}
                    />
                  </div>

                  <div className="form-group col-lg-4">
                  <label>Área de trabajo</label>
                  <br />
                  <select
                         id="comboEmpresa"
                         name="empresaId"
                         className="form-control text-black"
                         style={{borderRadius: 40, height: 49}}
                         onChange={handleArea}>
                          <option value="-1">--- Selecciona una opcion ---</option>
                          {listas.map((lista) => {
                            return (
                              
                            <option key={lista.id_area} value={lista.id_area}>
                              {lista.nombre_area}
                              </option>
                              );
                              })}
                              </select>
                  </div>
                  <div className="form-group col-lg-4">
                  <label>Puesto de Trabajo</label>
                  <br />
                    <select className="form-control"
                    style={{borderRadius: 40, height: 49}}
                    onChange={(e) =>
                      setPuesto(e.target.value)}>
                        <option value="-1">--- Selecciona una opcion ---</option>
                    {widgets.map((puesto) => {
                            return (
                              
                            <option key={puesto.id_puesto} value={puesto.id_puesto}>
                              {puesto.nombre_puesto}
                              </option>
                              );
                              })}
                    </select>
                  </div>
                  <div className="form-group col-lg-4">
                  <label>Fecha Nacimiento</label>
                    <input
                      type="date"
                      className="form-control form-control-user"
                      id="exampleInputPassword"
                      onChange={(e) =>
                        setFechaN(e.target.value)}
                    />
                  </div>
                  <div className="form-group col-lg-6">
                  <label>Estado Civil</label>
                    <input
                      type="text"
                      className="form-control form-control-user"
                      id="exampleInputPassword"
                      onChange={(e) =>
                        setEstadoCivil(e.target.value)}
                    />
                  </div>
                  <div className="form-group col-lg-3">
                  <label>Tipo Contrato</label>
                    <input
                      type="text"
                      className="form-control form-control-user"
                      id="exampleInputPassword"
                      onChange={(e) =>
                        setTipoContrato(e.target.value)}
                    />
                  </div>
                  <div className="form-group col-lg-3">
                  <label>Tipo Usuario</label>
                  <select className="form-control"
                    style={{borderRadius: 40, height: 49}}
                    onChange={handleTipU}>
                    {privilegios.map((privilegio) => {
                            return (
                              
                            <option key={privilegio.id_privilegio} value={privilegio.id_privilegio}>
                              {privilegio.privilegio}
                              </option>
                              );
                              })}
                    </select>
                  </div>
                  <div className="form-group col-lg-3">
                  <label>Experiencia Laboral</label>
                    <input
                      type="text"
                      className="form-control form-control-user"
                      id="exampleInputPassword"
                      onChange={(e) =>
                        setExperienciaL(e.target.value)}
                    />
                  </div>
                  <div className="form-group col-lg-3">
                  <label>Tiempo en el Puesto</label>
                    <input
                      type="date"
                      className="form-control form-control-user"
                      id="exampleInputPassword"
                      onChange={(e) =>
                        setTiempoPuesto(e.target.value)}
                    />
                  </div>
                  <div className="form-group col-lg-3">
                  <label>Tiempo en la Empresa</label>
                    <input
                      type="date"
                      className="form-control form-control-user"
                      id="exampleInputPassword"
                      onChange={(e) =>
                        setTiempoEmpresa(e.target.value)}
                    />
                  </div>
                  <div className="form-group col-lg-3">
                  <label>Tipo de Jornada</label>
                    <input
                      type="text"
                      className="form-control form-control-user"
                      id="exampleInputPassword"
                      onChange={(e) =>
                        setTipoJornada(e.target.value)}
                    />
                  </div>
                  <div className="form-group col-lg-2">
                  <label>Rotación de Turno</label>
                    <input
                      type="text"
                      className="form-control form-control-user"
                      id="exampleInputPassword"
                      onChange={(e) =>
                        setRotacionTurno(e.target.value)}
                    />
                  </div>                  
                  <div className="form-group col-lg-5">
                  <label>CURP</label>
                    <input
                      type="text"
                      className="form-control form-control-user"
                      id="exampleInputPassword"
                      onChange={(e) =>
                        setCurp(e.target.value)}
                    />
                  </div>
                  <div className="form-group col-lg-5">
                  <label>Teléfono</label>
                    <input
                      type="text"
                      className="form-control form-control-user"
                      id="exampleInputPassword"
                      onChange={(e) =>
                        setTelefono(e.target.value)}
                    />
                  </div>
                  <div className="form-group col-lg-4">
                  <label>Email</label>
                    <input
                      type="email"
                      className="form-control form-control-user"
                      id="exampleInputPassword"
                      onChange={(e) =>
                        setEmail(e.target.value)}
                    />
                  </div>
                  <div className="form-group col-lg-4">
                  <label>Contraseña</label>
                    <input
                      type="password"
                      className="form-control form-control-user"
                      id="exampleInputPassword"
                      onChange={(e) =>
                        setPassword(e.target.value)}
                    />
                  </div>
                  <div className="form-group col-lg-2">
                  <label>Género</label>
                  <br />
                    <select className="form-control" style={{borderRadius: 40, height: 49}} onChange={(e) =>
                        setGenero(e.target.value)}>
                      <option value={"x"}>-- Seleccionar --</option>
                      <option value={"M"}>Masculino</option>
                      <option value={"F"}>Femenino</option>
                    </select>
                  </div>
                  <div className="form-group col-lg-2">
                  <label>Num. Empleado</label>
                    <input
                      type="text"
                      className="form-control form-control-user"
                      id="exampleInputPassword"
                      onChange={(e) =>
                        setNumEmpleado(e.target.value)}
                    />
                  </div>
                  <div className="form-group col-lg-12 text-center">
                  <button
                    type="submit"
                    className="btn btn-u btn-user btn-sm"
                    style={{border: "#380079"}}
                  >
                    Guardar
                  </button>
                  <SweetAlert2 {...swalProps} />
                  </div>
                </div>
                
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );}
  else {
    return(
      <NotFound />
    )
  }
}

export default CrearUsuario;
