import React from 'react';
import {Route, Routes} from 'react-router-dom'
import Login from './pages/Login.js';
import Home from './pages/Home.js';
import Prueba from './pages/Prueba.js';
import UltimoReporte from './pages/UltimoReporte.js';
import Empresas from './pages/Empresas.js';
import AreasTrabajo from './pages/AreasTrabajo.js';
import Puestos from './pages/Puestos.js';
import AltaEmpresa from './pages/AltaEmpresa.js';
import PorPeriodo from './pages/PorPeriodo.js';
import AltaAreas from './pages/AltaAreas.js';
import AltaPuestos from './pages/AltaPuestos.js';
import Tabla from './pages/tabla.js'
import UsuariosEmpresa from './pages/UsuariosEmpresa.js';
import DetalleUsuario from './pages/DetalleUsuario.js';
import DetalleEmpresa from './pages/DetalleEmpresa.js';
import AltaUsuarios from './pages/AltaUsuarios.js';
import BuscadorEncuestas from './pages/BuscadorEncuestas.js'
import EncuestasEmpresa from './pages/EncuestasEmpresa.js'
import EncuestasAreas from './pages/EncuestasAreas.js'
import EncuestasPuestos from './pages/EncuestasPuestos.js'
import Cuestionarios from './pages/Encuestas/Cuestionarios.js';
import NOM035 from './pages/Encuestas/NOM035.js';
import NOM037 from './pages/Encuestas/NOM037.js';
import Cuestionario1 from './pages/Encuestas/Cuestionario1.js';
import AuthContextProvider from './pages/context/authContext';
import NotFound from './pages/NotFound.js';
import HomeCliente from './pages/Cliente/HomeCliente.js';
import HomeUsuario from './pages/Usuario/HomeUsuario.js';
import Areas from './pages/Usuario/Areas.js';
import RestablecerC from './pages/RestablecerC.js'
import MisEmpresas from './pages/Cliente/MisEmpresas.js';
import AreasEmpresa from './pages/Cliente/AreasEmpresa.js';
import MisPuestos from './pages/Cliente/MisPuestos.js';
import MisUsuarios from './pages/Cliente/MisUsuarios.js';
import Perfil from './pages/Cliente/Perfil.js';
import ResultadosNOM035 from './pages/Usuario/ResultadosNOM035.js';
import ReporteEmpresa from './pages/Cliente/ReporteEmpresa.js';
import Reporte from './pages/Cliente/Reporte.js';
import DetalleEmpresaC from './pages/Cliente/DetalleEmpresaC.js';
import AgregarEmpresa from './pages/Cliente/AgregarEmpresa.js';
import AgregarArea from './pages/Cliente/AgregarArea.js';
import AgregarPuestos from './pages/Cliente/AgregarPuestos.js';
import Usuarios from './pages/Usuario/Usuarios.js';
import PuestosU from './pages/Usuario/PuestosU.js';
import PerfilU from './pages/Usuario/PerfilU.js';
import PerfilE from './pages/Encuestas/PerfilE.js';
import ResultadosC3 from './pages/Usuario/ResultadosC3.js';
import AgregarUsuarios from './pages/Cliente/AgregarUsuarios.js';
import AsignarEncuestas from './pages/Cliente/AsignarEncuestas.js';
import CuestionarioNom36 from './pages/Encuestas/CuestionarioNom36.js';
import NOM036 from './pages/Encuestas/NOM036.js';
import AreasdeEmpresa from './pages/Encuestas/NOM036.js';
import Apendices036 from './pages/Encuestas/Apendices036.js';
import PuestosdeEmpresa from './pages/Encuestas/PuestosdeEmpresa.js';
import BuzonQuejas from './pages/Encuestas/BuzonQuejas.js';
import UsuariosPuesto from './pages/Encuestas/UsuariosPuesto.js';
import Secciones036 from './pages/Encuestas/Secciones036.js';
import CrearUsuario from './pages/Usuario/CrearUsuario.js';
import MisPeriodos from './pages/Cliente/MisPeriodos.js';
import CuestionariosNom035 from './pages/Encuestas/CuestionariosNom035.js';
import Seccion2 from './pages/Encuestas/Seccion2.js';
import Seccion3 from './pages/Encuestas/Seccion3.js';
import Seccion4 from './pages/Encuestas/Seccion4.js';
import Seccion5 from './pages/Encuestas/Seccion5.js';
import AsignarEncuestaArea from './pages/Cliente/AsignarEncuestaArea.js';
import Periodos from './pages/Cliente/Periodos.js';
import Asignar from './pages/Cliente/Asignar.js';
import DetallePeriodo from './pages/Cliente/DetallePeriodo.js';


function App() {

  return (
    <AuthContextProvider>
    <Routes>
      <Route path="/" element={<Login/>} />
      <Route path="/Home" element={<Home/>} />
      <Route path="/Cuestionarios" element={<Cuestionarios/>} />
      <Route path="/UltimoReporte" element={<UltimoReporte/>} />
      <Route path="/PorPeriodo" element={<PorPeriodo />} />
      <Route path="/PorPeriodo/Prueba/:id" element={<Prueba />} />
      <Route path="UltimoReporte/Prueba/:id" element={<Prueba/>} />

      <Route path="/Empresas" element={<Empresas />} />
      <Route path="/UsuariosEmpresa/:id" element={<UsuariosEmpresa />} />
      <Route path="/DetalleEmpresa/:id" element={<DetalleEmpresa />} />
      <Route path="/DetalleUsuario/:id" element={<DetalleUsuario />} />
      <Route path="/AltaUsuarios/:id" element={<AltaUsuarios />} />
      <Route path="/AltaEmpresa" element={<AltaEmpresa />} />
      <Route path="/AreasTrabajo/:id" element={<AreasTrabajo />} />
      <Route path="/AltaAreas/:id" element={<AltaAreas />} />
      <Route path="/Puestos/:id/:id_emp" element={<Puestos />} />
      <Route path="/AltaPuestos/:id/:id_emp" element={<AltaPuestos />} />

      <Route path="/BuscadorEncuestas/:id" element={<BuscadorEncuestas />} />
      <Route path="/EncuestasEmpresa/:id/:periodo" element={<EncuestasEmpresa />} />
      <Route path="/EncuestasAreas/:id/:id_emp" element={<EncuestasAreas />} />
      <Route path="/EncuestasPuestos/:id/:id_emp" element={<EncuestasPuestos />} />

      <Route path="/NOM035/:id" element={<NOM035 />} />
      <Route path="/NOM036/:id" element={<NOM036 />} />
      <Route path="/Cuestionario1/:id" element={<Cuestionario1 />} />
      <Route path="/NOM037/:id" element={<NOM037 />} />

      <Route path="/HomeCliente" element={<HomeCliente />} />
      <Route path="/HomeUsuario" element={<HomeUsuario />} />

      <Route path="/Areas" element={<Areas />} />
      <Route path="/MisEmpresas" element={<MisEmpresas />} />
      <Route path="/MisPuestos/:id/:id_emp" element={<MisPuestos />} />
      <Route path="/MisUsuarios/:id" element={<MisUsuarios />} />
      <Route path="/Perfil/:id" element={<Perfil />} />
      <Route path="/DetalleEmpresaC/:id" element={<DetalleEmpresaC />} />
      <Route path="/AgregarEmpresa" element={<AgregarEmpresa />} />
      <Route path="/AgregarArea/:id" element={<AgregarArea />} />
      <Route path="/AgregarPuestos/:id/:id_emp" element={<AgregarPuestos />} />
      <Route path="/AgregarUsuarios/:id" element={<AgregarUsuarios />} />
      <Route path="/MisPeriodos" element={<MisPeriodos />} />
      <Route path="/Periodos/:id" element={<Periodos />} />
      <Route path="/Asignar/:id/:periodo" element={<Asignar />} />
      <Route path="/DetallePeriodo/:id" element={<DetallePeriodo />} />

      <Route path="/AreasEmpresa/:id" element={<AreasEmpresa />} />

      <Route path="/tabla" element={<Tabla />} />
      <Route path="/404" element={<NotFound />} />
      <Route path="/Restablecer" element={<RestablecerC />} />
      <Route path="/ResultadosNOM035" element={<ResultadosNOM035 />} />

      <Route path="/ReporteEmpresa" element={<ReporteEmpresa />} />

      <Route path="/Reporte/:id" element={<Reporte/>} />
      <Route path="/Usuarios/:id" element={<Usuarios />} />
      <Route path="/CrearUsuario/:id" element={<CrearUsuario />} />
      <Route path="/PuestosU/:id/:id_emp" element={<PuestosU />} />
      <Route path="/PerfilU/:id" element={<PerfilU />} />
      <Route path="/ResultadosC3" element={<ResultadosC3 />} />

      <Route path="/PerfilE/:id" element={<PerfilE />} />
      <Route path="/AsignarEncuestas/:id" element={<AsignarEncuestas />} />
      <Route path="/AsignarEncuestaArea/:id" element={<AsignarEncuestaArea />} />
      <Route path="/CuestionarioNom36" element={<CuestionarioNom36 />} />
      <Route path="/AreasdeEmpresa" element={<AreasdeEmpresa />} />
      <Route path="/Apendices036/:id" element={<Apendices036 />} />
      <Route path="/PuestosdeEmpresa/:id" element={<PuestosdeEmpresa />} />
      <Route path="/UsuariosPuesto/:id" element={<UsuariosPuesto />} />
      <Route path="/BuzonQuejas" element={<BuzonQuejas />} />
      <Route path="/Secciones036/:id" element={<Secciones036 />} />
      <Route path="/CuestionariosNom035/:id" element={<CuestionariosNom035 />} />
      <Route path="/Seccion2/:id" element={<Seccion2 />} />
      <Route path="/Seccion3/:id" element={<Seccion3 />} />
      <Route path="/Seccion4/:id" element={<Seccion4 />} />
      <Route path="/Seccion5/:id" element={<Seccion5 />} />

    </Routes>
    </AuthContextProvider>
    
  )
}

export default App
