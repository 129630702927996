import React, { useState, useEffect } from "react";
import axios, { formToJSON } from "axios";
import { Link } from "react-router-dom";
import Sidebar from "./componentes/Sidebar";
import Topbar from "./componentes/Topbar";
import { useParams, useNavigate } from "react-router-dom";
import SweetAlert2 from "react-sweetalert2";
import NotFound from "./NotFound";

function BuscadorEncuestas() {
  let { id } = useParams();

  const url =
    "https://ms.bluehand.com.mx/backend/api/v1/api/get-periodos-por-empresa";
  const [master] = useState(
    "$2a$12$n1ifj4zomb1OS0sn/lW8COTSd5cV7lKGsgcdbC.ZBtVnAFFGt0.ie"
  );
  const [listas, setLista] = useState([]);
  const [periodo, setPeriodo] = useState([]);
  const [sweet, setSweet] = useState(false);
  const [cues, setCuest] = useState(false);
  const [search, setSearch] = useState(true);
  const [swalProps, setSwalProps] = useState({});
  const [s, setS] = useState({});
  const navigate = useNavigate();

  const listaPost = async () => {
    var f = new FormData();
    f.append("master_key", master);
    f.append("id_empresa", id);
    var dataa = formToJSON(f);
    await axios
      .post(url, dataa)
      .then((response) => {
        const data = response.data.data;
        const m = response.data.message

        if (m === "La empresa no tiene ningun periodo") {
          setSweet(true);
          setCuest(false);
          setSearch(false);
          debugger;
          showSwal();
        }
        if (m != "La empresa no tiene ningun periodo") {
          setLista(data);
          setSweet(false);
          setCuest(true);
          setSearch(false);
          console.log(listas);
          return;
        }
        console.log(data);
        //navigate("/Reporte");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if(listas == ""){
      listaPost();
    }
  }, []);

  const pasar = async () => {
    navigate(`/Empresas`);
  };
  const [inputValue, setInputValue] = useState('')

  function showSwal() {
    setSwalProps({
      show: true,
      icon: "error",
      title: "Oops...",
      text: "No se tiene Periodos",
      preConfirm: () => {
        pasar();
      },
    });
  }

  let l = localStorage;
  

  if (l.getItem("id_privilegios") != undefined && l.getItem("id_privilegios") == 1) {

  return (
    <div id="wrapper">
      <Sidebar />
      <div id="content-wrapper" className="d-flex flex-column">
        <div id="content">
          <Topbar />
          <div className="container-fluid">
            <div className="d-sm-flex align-items-center justify-content-between mb-4">
              <Link
                to={`/Empresas`}
                className="d-sm-inline-block btn btn-sm btn-primary shadow-sm"
              >
                <i className="fas fa-arrow-left fa-sm text-white-50"></i>{" "}
                Regresar
              </Link>
            </div>

            <div className="row col-lg-6">
              <div>
                <div className="form">
                  <div id="">
                    <h4>Selecciona un periodo</h4>
                  </div>
                  <br />
                  <div style={{ display: search ? "flex" : "none" }}>
                    Buscando Periodos...
                  </div>
                  <div style={{ display: cues ? "flex" : "none" }}>
                  <select
                    id="comboEmpresa"
                    name="empresaId"
                    className="form-control text-black"
                    onChange={(e) => setPeriodo(e.target.value)}
                  >
                    <option value="value1">
                      --- Selecciona una opcion ---
                    </option>
                    {listas.map((lista) => {
                      return (
                        <option key={lista.id_periodo} value={lista.id_periodo}>
                          {lista.nombre_periodo}
                        </option>
                      );
                    })}
                  </select>
                  </div>
                  <br />
                  <Link
                    className="d-sm-inline-block btn btn-sm btn-primary shadow-sm"
                    to={`/EncuestasEmpresa/${id}/${periodo}`}
                  >
                    <i className="fas fa-search fa-sm"></i>
                    &nbsp; Buscar
                  </Link>

                  <div style={{ display: sweet ? "flex" : "none" }}>
                    <SweetAlert2 {...swalProps} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );}
  else {
    return(
      <NotFound />
    )
  }
}

export default BuscadorEncuestas;
