import React, { useState, useEffect } from "react";
import SidebarE from "../componentes/SidebarE";
import { Link,useParams } from "react-router-dom";
import TopbarE from "../componentes/TopbarE";
import { useNavigate } from "react-router-dom";
import axios, { formToJSON } from "axios";
import NotFound from "../NotFound";
import carga from "../../estilos/img/a1.jpg"
import transporte from "../../estilos/img/icono1-bg.png"
import transporte2 from "../../estilos/img/icono2-bg.png"
import seccion2 from "../../estilos/img/cargae.jpg"
import seccion2_2 from "../../estilos/img/cargae2.jpg"

function Secciones036() {
    let { id } = useParams();

  let id_usuario = 653397;
  let id_periodo = 90707;
  let id_puesto = 792;
  let id_empresa = 197;
  const [aiVisible, setAiVisible] = useState(false);
  const [but, setBut] = useState(true);
  const navigate = useNavigate();
  const [username, setUsername] = useState([]);
  const [message, setMessage] = useState(false);
  const url = "https://ms.bluehand.com.mx/backend2/api/v1/api/clavecuestionario";
  const [master] = useState(
    "$2a$12$n1ifj4zomb1OS0sn/lW8COTSd5cV7lKGsgcdbC.ZBtVnAFFGt0.ie"
  );

  let l = localStorage;

  const peticionPost = async () => {
    var f = new FormData();
    f.append("master_key", master);
    f.append("clave_nom", username);
    f.append("id_usuario", id_usuario);
    f.append("id_empresa", id_empresa);
    var dataa = formToJSON(f);
    debugger;
    await axios
      .post(url, dataa)
      .then((response) => {
        const data = response.data.data;
        const r = response.data.result_code;
        if (r == "1") {
          debugger
          if (r === "1") {
            navigate('/NOM037')
          }
        } else {
          if (r == "0") {
            setMessage(true);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  function handleInputChange(event) {
    setUsername(event.target.value);
  }

  function handleSubmit(event) {
    event.preventDefault();
    peticionPost();
  }

  if (
    l.getItem("id_privilegios") != undefined &&
    l.getItem("id_privilegios") == 6 &&
    id == 1
  ) {
    return (
      <div id="wrapper">
        <SidebarE />
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <TopbarE />
            <div className="container-fluid">
              <div className="d-sm-flex align-items-center justify-content-between mb-4">
                <h4>Bienvenido</h4>
              </div>
              <div className="row mx-auto">
                
              <div className="col-sm-3 card shadow mb-4">
                  <div className="card-header py-3">
                    <h6 className="m-1 font-weight-bold text-primary">
                      NOM 036
                    </h6>
                  </div>
                  <div className="text-center card-body">
                    <p> Estimación del riesgo de actividades que impliquen levantamiento/descenso de cargas</p>
                    <img width={220} src={carga}/>
                    <br/><br/>
                    <div className="text-center">
                  <Link to="/NOM036/17" className="btn text-white" style={{backgroundColor: '#f05514'}}>Responder</Link>
                  </div>
                  </div>
                </div>

                <div className="col-sm-1"></div>
                
                <div className="col-sm-3 card shadow mb-4">
                  <div className="card-header py-3">
                    <h6 className="m-1 font-weight-bold text-primary">
                      NOM 036
                    </h6>
                  </div>
                  <div className="text-center card-body">
                    <p>Estimación del riesgo de operaciones de transporte de cargas</p>
                    <img width={180} src={transporte}/>
                    <br/><br/>
                    <div className="text-center">
                  <Link to="/NOM036/18" className="btn text-white" style={{backgroundColor: '#f05514'}}>Responder</Link>
                  </div>
                  </div>
                </div>

                <div className="col-sm-1"></div>

                <div className="col-sm-3 card shadow mb-4">
                  <div className="card-header py-3">
                    <h6 className="m-1 font-weight-bold text-primary">
                      NOM 036
                    </h6>
                  </div>
                  <div className="text-center card-body">
                    <p>Evaluación del riesgo de operaciones de manejo manual de cargas en equipo
                    </p>
                    <img width={200} src={transporte2}/>
                    <br/><br/>
                    <div className="text-center">
                  <Link to="/NOM036/19" className="btn text-white" style={{backgroundColor: '#f05514'}}>Responder</Link>
                  </div>
                  </div>
                </div>
                
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    if(l.getItem("id_privilegios") != undefined &&
    l.getItem("id_privilegios") == 6 &&
    id == 2){
        return(
            <div id="wrapper">
        <SidebarE />
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <TopbarE />
            <div className="container-fluid">
              <div className="d-sm-flex align-items-center justify-content-between mb-4">
                <h4>Bienvenido</h4>
              </div>
              <div className="row mx-auto">
                
              <div className="col-sm-5 card shadow mb-4">
                  <div className="card-header py-3">
                    <h6 className="m-1 font-weight-bold text-primary">
                      NOM 036
                    </h6>
                  </div>
                  <div className="text-center card-body">
                    <p>Evaluación del riesgo de actividades que impliquen empuje o arrastre de cargas sin uso de equipo auxiliar</p>
                    <img width={200} src={seccion2_2}/>
                    <br/><br/>
                    <div className="text-center">
                  <Link to="/NOM036/20" className="btn text-white" style={{backgroundColor: '#f05514'}}>Responder</Link>
                  </div>
                  </div>
                </div>
                
                <div className="col-sm-2"></div>

                <div className="col-sm-5 card shadow mb-4">
                  <div className="card-header py-3">
                    <h6 className="m-1 font-weight-bold text-primary">
                      NOM 036
                    </h6>
                  </div>
                  <div className="text-center card-body">
                    <p>Evaluación del riesgo de actividades que impliquen empujar o jalar cargas con el uso de equipo auxiliar</p>
                    <img width={200} src={seccion2}/>
                    <br/><br/>
                    <div className="text-center">
                  <Link to="/NOM036/21" className="btn text-white" style={{backgroundColor: '#f05514'}}>Responder</Link>
                  </div>
                  </div>
                </div>
                
              </div>
            </div>
          </div>
        </div>
      </div>
        )
    }else {
    return <NotFound />;
    }
  }
}

export default Secciones036;
