import React, { useState, useEffect } from "react";
import SidebarE from "../componentes/SidebarE.js";
import { Link, useNavigate } from "react-router-dom";
import TopbarE from "../componentes/TopbarE.js";
import axios, { formToJSON } from "axios";
import SweetAlert2 from "react-sweetalert2";

function Cuestionario1() {
  let id_usuario = 654802;
  let id_periodo = 90707;

  const url =
    "https://ms.bluehand.com.mx/backend/api/v1/api/resultados-cuestionario1-individual";
  const [master] = useState(
    "$2a$12$n1ifj4zomb1OS0sn/lW8COTSd5cV7lKGsgcdbC.ZBtVnAFFGt0.ie"
  );
  const [widgets, setWidgets] = useState("");
  const [seccion2, setSeccion2] = useState("");
  const [seccion3, setSeccion3] = useState("");
  const [seccion4, setSeccion4] = useState("");
  const [preguntasS1, setPreguntasS1] = useState([]);
  const [preguntasS2, setPreguntasS2] = useState([]);
  const [preguntasS3, setPreguntasS3] = useState([]);
  const [preguntasS4, setPreguntasS4] = useState([]);
  const [message2, setMessage2] = useState(false);
  const [swalProps, setSwalProps] = useState({});
  const navigate = useNavigate();

  let l = localStorage;

  const peticionPost = async () => {
    var f = new FormData();
    f.append("master_key", master);
    f.append("id_usuario", id_usuario);
    f.append("id_periodo", id_periodo);
    var dataa = formToJSON(f);
    await axios
      .post(url, dataa)
      .then((response) => {
        const seccion1arr = response.data.data.detalles_de_seccion[0];
        const p1s1 = seccion1arr.preguntas;
        const seccion2arr = response.data.data.detalles_de_seccion[1];
        const p2s2 = seccion2arr.preguntas;
        const seccion3arr = response.data.data.detalles_de_seccion[2];
        const p3s3 = seccion3arr.preguntas;
        const seccion4arr = response.data.data.detalles_de_seccion[3];
        const p4s4 = seccion4arr.preguntas;

        setWidgets(seccion1arr);
        setPreguntasS1(p1s1);
        setSeccion2(seccion2arr);
        setPreguntasS2(p2s2);
        setSeccion3(seccion3arr);
        setPreguntasS3(p3s3);
        setSeccion4(seccion4arr);
        setPreguntasS4(p4s4);
      
        console.log(p1s1);

        //navigate("/Reporte");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const pasar = async () => {
    navigate(`/NOM035`);
    l.setItem("est", true);

  };
  const [inputValue, setInputValue] = useState("");

  function showSwal() {
    setSwalProps({
      show: true,
      icon: "success",
      title: "Completado",
      text: "Se completo 20/20",
      preConfirm: () => {
        pasar();
      },
    });
  }

  useEffect(() => {
    if(widgets == ""){
      peticionPost();
      console.log(l);
    }
  }, []);

  return (
    <div id="wrapper">
      <SidebarE />
      <div id="content-wrapper" className="d-flex flex-column">
        <div id="content">
          <TopbarE />
          <div className="container-fluid">
            <div className="d-sm-flex align-items-center justify-content-between mb-4">
              <h4>Información General</h4>
            </div>
           
            <div className="row">
              
                <div className="col-sm-12 card shadow mb-4">
                <div className="card-header py-3">
                  <h6 className="m-1 font-weight-bold text-primary">
                    Sección 1 - {widgets.nombre_seccion}
                  </h6>
                </div>
                {preguntasS1.map((secc, index) =>(
                <div className="card-body" key={{index}}>
                  {secc.num_pregunta} . {secc.pregunta}
                  <br />
                  <br />
                  <div className="row">
                    <div className="col-sm-4">
                  <select className="form-control" name="select">
                   <option value="1">Selecciona la opción</option>
                    <option value="1" selected>SI</option>
                    <option value="0">NO</option>
                  </select>
                  </div>
                  </div>
                 
                </div>
                 ))}
              </div>
             
              


              <div className="col-sm-12 card shadow mb-4">
                <div className="card-header py-3">
                  <h6 className="m-1 font-weight-bold text-primary">
                    Sección 2 - {seccion2.nombre_seccion}
                  </h6>
                </div>
                {preguntasS2.map((secc, index) =>(
                <div className="card-body" key={{index}}>
                  {secc.num_pregunta} . {secc.pregunta}
                  <br />
                  <br />
                  <div className="row">
                    <div className="col-sm-4">
                    <select className="form-control" name="select">
                   <option value="1">Selecciona la opción</option>
                    <option value="1" selected>SI</option>
                    <option value="0">NO</option>
                  </select>
                    </div>
                  </div>
                 
                </div>
                 ))}
              </div>

              <div className="col-sm-12 card shadow mb-4">
                <div className="card-header py-3">
                  <h6 className="m-1 font-weight-bold text-primary">
                    Sección 3 - {seccion3.nombre_seccion}
                  </h6>
                </div>
                {preguntasS3.map((secc, index) =>(
                <div className="card-body" key={{index}}>
                  {secc.num_pregunta} . {secc.pregunta}
                  <br />
                  <br />
                  <div className="row">
                    <div className="col-sm-4">
                    <select className="form-control" name="select">
                   <option value="1">Selecciona la opción</option>
                    <option value="1" selected>SI</option>
                    <option value="0">NO</option>
                  </select>
                    </div>
                  </div>
                 
                </div>
                 ))}
              </div>

              <div className="col-sm-12 card shadow mb-4">
                <div className="card-header py-3">
                  <h6 className="m-1 font-weight-bold text-primary">
                    Sección 4 - {seccion4.nombre_seccion}
                  </h6>
                </div>
                {preguntasS4.map((secc, index) =>(
                <div className="card-body" key={{index}}>
                  {secc.num_pregunta} . {secc.pregunta}
                  <br />
                  <br />
                  <div className="row">
                    <div className="col-sm-4">
                    <select className="form-control" name="select">
                   <option value="1">Selecciona la opción</option>
                    <option value="1" selected>SI</option>
                    <option value="0">NO</option>
                  </select>
                    </div>
                  </div>
                 
                </div>
                 ))}
              </div>
              <div className="text-center">
                <button
                  onClick={showSwal}
                  className="mx-auto d-sm-inline-block btn btn-sm btn-primary shadow-sm"
                >
                  <i className="fas fa-check fa-sm text-white-50"></i> Finalizar
                </button>
                <SweetAlert2 {...swalProps} />
              </div>
              &nbsp;&nbsp;&nbsp;&nbsp;
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Cuestionario1;
