import React, { useState, useEffect } from "react";
import SidebarE from "../componentes/SidebarE";
import { Link, useParams } from "react-router-dom";
import TopbarE from "../componentes/TopbarE";
import { useNavigate } from "react-router-dom";
import axios, { formToJSON } from "axios";
import NotFound from "../NotFound";
import trauma from "../../estilos/img/trauma1.png"
import trauma2 from "../../estilos/img/trauma2.jpg"
import trauma3 from "../../estilos/img/trauma3.png"
import Swal from "sweetalert2";

function CuestionariosNom035() {
  let l = localStorage;

  let {id} = useParams();

  l.setItem("id_encuestado", id)

  const usuario = l.getItem("id_usuario");
  let id_periodo = 90707;
  let id_puesto = 792;
  const [aiVisible, setAiVisible] = useState(false);
  const [but, setBut] = useState(true);
  const navigate = useNavigate();
  const [username, setUsername] = useState([]);
  const [message, setMessage] = useState(false);
  const url = "https://ms.bluehand.com.mx/backend/api/v1/validarnom35";
  const [master] = useState(
    "$2a$12$n1ifj4zomb1OS0sn/lW8COTSd5cV7lKGsgcdbC.ZBtVnAFFGt0.ie"
  );

  const pasar = async () => {
    navigate("/NOM035/1");
  };

  function showSwal() {
    Swal.fire({
      icon: "info",
        title: "Completado",
        text: "Cuestionario 1 pendiente, favor de realizarlo",
        showCancelButton: true,
  confirmButtonText: "Responder",
  preConfirm: () => {
    debugger
      pasar();
  },
    });
  }

  function showSwal2() {
    Swal.fire({
      icon: "info",
        title: "Completado",
        text: "Cuestionario ya realizado",
        confirmButtonText: "Gracias",
    });
  }

  function showSwal3() {
    Swal.fire({
      icon: "info",
        title: "Completado",
        text: "Cuestionario ya realizado",
        confirmButtonText: "Gracias",
    });
  }

  function showSwalC() {
    Swal.fire({
      icon: "info",
        title: "Completado",
        text: "Cuestionario ya realizado, favor de responder el siguiente.",
        confirmButtonText: "Gracias",
    });
  }

  function showSwalE() {
    Swal.fire({
      icon: "warning",
        title: "Sin cuestionario",
        text: "No tiene asignado el cuestionario.",
        confirmButtonText: "Gracias",
    });
  }

  const peticionPost = async () => {
    debugger
    var f = new FormData();
    f.append("master_key", master);
    f.append("id_usuario", usuario);
    f.append("cuestionario", 0);
    var dataa = formToJSON(f);
    await axios
      .post(url, dataa)
      .then((response) => {
        debugger;
        const r = response.data.result_code;
        const m = response.data.message;

        setMessage(m);

        if(r == 2){
          if(m == "Cuestionario 2 realizado anteriormente, favor de avisar a sus superiores"){
            showSwal2()
          }else{
          showSwal();
          }
        }else{
          if(m == "ERORR, no tiene asignado el cuestionario 2"){
            showSwalE();
          }else{
          if(m == "Cuestionario 1 pendiente, favor de realizarlo"){
            showSwal();
          }else{
            if(m == "Cuestionario 2 pendiente, favor de realizarlo ya que el cuestionario 1 ya se ha realizado"){
              navigate("/NOM035/2");
            }
          }
        }
        }
        debugger;
        //navigate("/Reporte");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const peticionPost3 = async () => {
    debugger
    var f = new FormData();
    f.append("master_key", master);
    f.append("id_usuario", usuario);
    f.append("cuestionario", 3);
    var dataa = formToJSON(f);
    await axios
      .post(url, dataa)
      .then((response) => {
        debugger;
        const r = response.data.result_code;
        const m = response.data.message;

        setMessage(m);

        if(r == 2){
          if(m == "Cuestionario 3 realizado anteriormente, favor de avisar a sus superiores"){
            showSwal3();
          }
        }else{
          if(m == "ERORR, no tiene asignado el cuestionario 3"){
            showSwalE();
          }
          else{
            if(m == "ERORR, no tiene asignado el cuestionario 2"){
              showSwal();
            }else{
              if(m == "Cuestionario 3 pendiente, favor de realizarlo ya que el cuestionario 1 ya se ha realizado"){
                navigate("/NOM035/3");
              }
            }
          }
        }
        debugger;
        //navigate("/Reporte");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const peticionPost1 = async () => {
    debugger
    var f = new FormData();
    f.append("master_key", master);
    f.append("id_usuario", usuario);
    f.append("cuestionario", 1);
    var dataa = formToJSON(f);
    await axios
      .post(url, dataa)
      .then((response) => { 
        debugger;
        const r = response.data.result_code;
        const m = response.data.message;

        setMessage(m);

        if(r == 2){
          setMessage(m);
          showSwal();
        }else{
          if(m == "Favor de realizar el cuestionario correspondiente"){
            navigate("/NOM035/1")
          }
          else{
            if(m == "Cuestionario ya realizado, gracias"){
              showSwalC()
            }
            else{
              navigate("/NOM035/1")
            }
          }
        }
        debugger;
        //navigate("/Reporte");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  function handleInputChange(event) {
    setUsername(event.target.value);
  }

  function handleSubmit(event) {
    event.preventDefault();
    peticionPost();
  }

  if (
    l.getItem("id_privilegios") != undefined &&
    l.getItem("id_privilegios") == 6
  ) {
    return (
      <div id="wrapper">
        <SidebarE />
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <TopbarE />
            <div className="container-fluid">
              <div className="d-sm-flex align-items-center justify-content-between mb-4">
                <h4>Bienvenido</h4>
              </div>
              <div className="row mx-auto">
                
              <div className="col-sm-3 card shadow mb-4">
                  <div className="card-header py-3">
                    <h6 className="m-1 font-weight-bold text-primary">
                      NOM 035
                    </h6>
                  </div>
                  <div className="text-center card-body">
                    <p>Cuestionario para identificar a los trabajadores que fueron sujetos a acontecimientos traumáticos severos</p>
                    <img width={"75%"} src={trauma}/>
                    <br/><br/>
                    <div className="text-center">
                  <button onClick={peticionPost1} className="btn text-white" style={{backgroundColor: '#f05514'}}>Responder</button>
                  </div>
                  </div>
                </div>

                <div className="col-sm-1"></div>
                
                <div className="col-sm-3 card shadow mb-4">
                  <div className="card-header py-3">
                    <h6 className="m-1 font-weight-bold text-primary">
                      NOM 035
                    </h6>
                  </div>
                  <div className="text-center card-body">
                    <p>Cuestionario para identificar los factores de riesgo psicosocial en los centros de trabajo</p>
                    <img width={"100%"} src={trauma2}/>
                    <br/><br/>
                    <div className="text-center">
                  <button onClick={peticionPost} className="btn text-white" style={{backgroundColor: '#f05514'}}>Responder</button>
                  </div>
                  </div>
                </div>

                <div className="col-sm-1"></div>

                <div className="col-sm-3 card shadow mb-4">
                  <div className="card-header py-3">
                    <h6 className="m-1 font-weight-bold text-primary">
                      NOM 035
                    </h6>
                  </div>
                  <div className="text-center card-body">
                    <p>Cuestionario para identificar los factores de riesgo psicosocial y evaluar el entorno organizacional en los centros de trabajo</p>
                    <img width={"75%"} src={trauma3}/>
                    <br/><br/>
                    <div className="text-center">
                  <button onClick={peticionPost3} className="btn text-white" style={{backgroundColor: '#f05514'}}>Responder</button>
                  <br/><br/>
                  </div>
                  </div>
                </div>
                
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return <NotFound />;
  }
}

export default CuestionariosNom035;
