import React, { useState, useEffect } from "react";
import "../estilos/css/sb-admin-2.css";
import "../estilos/vendor/fontawesome-free/css/all.min.css";
import { useAuthContext } from "./context/authContext";
import axios, { formToJSON } from "axios";
import { useNavigate } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import md5 from "md5";
import SweetAlert2 from "react-sweetalert2";
import Swal from "sweetalert2";
import logo from "../estilos/img/docc.png"

function Login() {
  let l = localStorage;
  const { login } = useAuthContext();
  const [aiVisible, setAiVisible] = useState(false);
  const [but, setBut] = useState(true);
  const [username, setUsername] = useState([]);
  const [password, setPassword] = useState([]);
  const navigate = useNavigate();
  const [message, setMessage] = useState(false);
  const [message2, setMessage2] = useState(false);
  const [swalProps, setSwalProps] = useState({});
  const [peticion, setPeticion] = useState({});
  const url2 = "https://ms.bluehand.com.mx/backend/api/v1/loginuser";
  const url3 = "https://ms.bluehand.com.mx/backend/api/v1/estadouser";
  const [master] = useState(
    "$2a$12$n1ifj4zomb1OS0sn/lW8COTSd5cV7lKGsgcdbC.ZBtVnAFFGt0.ie"
  );

  const peticionPost = async () => {
    debugger
    var f = new FormData();
    f.append("master_key", master);
    f.append("email", username);
    f.append("password", md5(password));
    var dataa = formToJSON(f);
    
    await axios
      .post(url2, dataa)
      .then((response) => {
        debugger
        const data = response.data.data;
        const sincuestionario = response.data;
        const cuest = response.data.cuestionario;
        const r = response.data.result_code;
        const m = response.data.message;
        if(sincuestionario.respuesta == 1){
          setPeticion(peticion);
            l.setItem("id_privilegios", data.id_privilegios);
            l.setItem("id_empresa", data.id_empresa);

            l.setItem("nombre", data.nombre);
            l.setItem("apellido_paterno", data.apellido_paterno);
            l.setItem("apellido_materno", data.apellido_materno);
            l.setItem("id_usuario", data.id_usuario);
            l.setItem("cuestionario035", 0);
            l.setItem("cuestionario036", 0);
            l.setItem("cuestionario037", 0);
            l.setItem("sincuestionario", sincuestionario.respuesta);
            login();
        }else{
        if (r == "1") {
          if (data.estado === "1") {
            setPeticion(peticion);
            l.setItem("id_privilegios", data.id_privilegios);
            l.setItem("id_empresa", data.id_empresa);

            l.setItem("nombre", data.nombre);
            l.setItem("apellido_paterno", data.apellido_paterno);
            l.setItem("apellido_materno", data.apellido_materno);
            l.setItem("id_usuario", data.id_usuario);
            
            if(cuest != undefined){
              l.setItem("cuestionario036", cuest.nom36);
              l.setItem("cuestionario037", cuest.nom37);
              l.setItem("cuestionario035", cuest.dass);
            l.setItem("periodo_inicio", cuest.periodo_inicio);
            l.setItem("periodo_fin", cuest.periodo_fin);
            }
            login();
          }
        }
        else {
          if (r == "2") {
            setMessage(true);
            setAiVisible(false);
            setBut(true);
          }
          else{
            if(r == 0 && m == "Favor de agregar bien sus datos"){
              setAiVisible(false);
            setBut(true);
            setMessage(true);
            }
          }
        }
      }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  function handleInputChange(event) {
    setUsername(event.target.value);
  }

  function handleInputPChange(event) {
    setPassword(event.target.value);
  }

  function handleSubmit(event) {
    event.preventDefault();
    setBut(false);
    setAiVisible(true);
  peticionPost();
  }

  function showError() {
    Swal.fire({
      icon: "error",
        title: "Sin Acceso",
        text: "Favor de ingresas los datos correctos",
        confirmButtonText: "Salir"
    });
  }

  function showSwal() {
    Swal.fire({
      icon: "info",
      title: "Confirmar correo electrónico",
      input: "text",
      confirmButtonText: "Verificar",
      cancelButtonText: "Cancelar",
      preConfirm: async (reset) => {
        var f = new FormData();
    f.append("master_key", master);
    f.append("email", reset);
    var dataa = formToJSON(f);
    await axios
      .post(url3, dataa)
      .then((response) => {
        const data = response.data.data;
        const r = response.data.result_code;
        const email = response.data.email;
        if (data != false) {
          if (data.estado === "1") {
            l.setItem("estado", data.estado);
            l.setItem("id_usuario", data.id_usuario);
            l.setItem("email", email);
            navigate('/Restablecer');
          }
        } else {
          if(data == false) {
          showError();
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
    }
    });
  }

  return (
    <div
      className=" col-lg-12"
    >
      <div className="row justify-content-center">
        <div className="col-xl-3 col-lg-6 col-md-9 login">
          <div className="card o-hidden border-0 shadow-lg my-5">
            <div className="card-body p-0">
              <div className="row">
                <div className="col-lg-12">
                  <div className="p-5">
                    
                    <div className="text-center">
                      <img width={250} src={logo}/>
                    </div>
                    <div className="text-center">
                      <a className="small" href="register.html">
                        {" "}
                        &nbsp;
                      </a>
                    </div>
                      <h1 className="text-center h4 text-gray-900 mb-4">
                        Inicio de Sesión
                      </h1>
                    
                    <form className="user" onSubmit={handleSubmit}>
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control form-control-user"
                          id="prueba"
                          aria-describedby="emailHelp"
                          placeholder="Usuario"
                          value={username}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="form-group">
                        <input
                          type="password"
                          className="form-control form-control-user"
                          id="exampleInputPassword"
                          placeholder="Contraseña"
                          value={password}
                          onChange={handleInputPChange}
                        />
                      </div>
                      <div
                        className=""
                        style={{ display: aiVisible ? "flex" : "none" }}
                      >
                        <ClipLoader
                          size={20}
                          aria-label="Loading Spinner"
                          data-testid="loader"
                          className="mx-auto"
                        />
                      </div>
                      <div style={{ display: but ? "flex" : "none" }}>
                        <button
                          type="submit"
                          className="btn btn-p btn-user btn-block"
                          style={{border: "#380079"}}
                        >
                          Iniciar Sesión
                        </button>
                      </div>
                    </form>
                    <div className="form-group">
                        
                          <button onClick={showSwal} className="small btn btn-link">
                            ¿Olvidaste tu Contraseña?
                          </button>
                          <SweetAlert2 {...swalProps} />
                      </div>
                    <hr />
                    <div style={{ display: message ? "flex" : "none" }}>
                      <p className="mx-auto text-danger">
                        Usuario o Contraseña Incorrectos
                      </p>
                    </div>
                    <div style={{ display: message2 ? "flex" : "none" }}>
                      <p className="mx-auto text-danger">
                        No existe un correo electrónico
                      </p>
                    </div>
                    <div className="text-center">
                      <a className="small" href="forgot-password.html">
                        {" "}
                        &nbsp;
                      </a>
                    </div>
                    <div className="text-center">
                      <a className="small" href="register.html">
                        {" "}
                        &nbsp;
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
