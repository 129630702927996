import React, {useState} from "react";
import Sidebar from "./componentes/Sidebar";
import Topbar from "./componentes/Topbar";
import { Link } from "react-router-dom";
import NotFound from "./NotFound";
import { useNavigate } from "react-router-dom";
import axios, { formToJSON } from "axios";

function AltaEmpresa() {

  const navigate = useNavigate();

  const [nombre, setNombre] = useState([]);
  const [razon_social, setRazon] = useState([]);
  const [telefono, setTelefono] = useState([]);
  const [calle, setCalle] = useState([]);
  const [colonia, setColonia] = useState([]);
  const [municipio, setMunicipio] = useState([]);
  const [estado, setEstado] = useState([]);
  const [codigop, setCodigoP] = useState([]);
  const [cantidade, setCantidadE] = useState([]);
  const [rfc, setRFC] = useState([]);

  const url = "https://ms.bluehand.com.mx/backend/api/v1/api/createCompany";
  const [master] = useState(
    "$2a$12$n1ifj4zomb1OS0sn/lW8COTSd5cV7lKGsgcdbC.ZBtVnAFFGt0.ie"
  );

  const peticionPost = async () => {

    debugger
    var f = new FormData();
    f.append("master_key", master);
    f.append("nombre_comercial", nombre);
    f.append("razon_social", razon_social);
    f.append("telefono", telefono);
    f.append("calle_numero", calle);
    f.append("colonia", colonia);
    f.append("municipio", municipio);
    f.append("estado", estado);
    f.append("codigo_postal", codigop);
    f.append("cantidad_empleados", cantidade);
    f.append("rfc", rfc);
    var dataa = formToJSON(f);
    
    await axios
      .post(url, dataa)
      .then((response) => {
        const r = response.data.result_code;
        if (r == "1") {
          navigate("/Empresas")
        } else {
          if (r == "2") {
            console.log("nono")
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  function handleInputChange1(event) {
    setNombre(event.target.value);
  }

  function handleInputChange2(event) {
    setRazon(event.target.value);
  }
  function handleInputChange3(event) {
    setTelefono(event.target.value);
  }

  function handleInputChange4(event) {
    setCalle(event.target.value);
  }
  function handleInputChange5(event) {
    setColonia(event.target.value);
  }

  function handleInputChange6(event) {
    setMunicipio(event.target.value);
  }
  function handleInputChange7(event) {
    setEstado(event.target.value);
  }

  function handleInputChange8(event) {
    setCodigoP(event.target.value);
  }
  function handleInputChange9(event) {
    setCantidadE(event.target.value);
  }

  function handleInputChange10(event) {
    setRFC(event.target.value);
  }

  function handleSubmit(event) {
    event.preventDefault();
  peticionPost();
  }

  let l = localStorage;

  if (l.getItem("id_privilegios") != undefined && l.getItem("id_privilegios") == 1) {

  return (
    <div id="wrapper">
      <Sidebar />
      <div id="content-wrapper" className="d-flex flex-column">
        <div id="content">
          <Topbar />
          <div className="container-fluid">
            <div className="d-sm-flex align-items-center justify-content-between mb-4">
              <Link
                to="/Empresas"
                className="d-sm-inline-block btn btn-sm text-white shadow-sm"
                style={{backgroundColor: "#380079", border: "#380079"}}
              >
                <i className="fas fa-arrow-left fa-sm text-white-50"></i> Regresar
              </Link>
            </div>
            <h1 className="h3 mb-0 text-gray-800">Alta Empresas</h1>

            <br />

            <div className="container-fluid">
              <form className="user" onSubmit={handleSubmit}>
                <div className="row">
                <div className="form-group col-lg-6">
                    <input
                        type="text"
                        className="form-control form-control-user"
                        id="razon_social"
                        placeholder="Nombre Comercial"
                        onChange={handleInputChange1}
                    />
                  </div>
                  <div className="form-group col-lg-6">
                    <input
                        type="text"
                        className="form-control form-control-user"
                        id="razon_social"
                        placeholder="Razón Social"
                        onChange={handleInputChange2}
                    />
                  </div>
                  <div className="form-group col-lg-6">
                    <input
                        type="text"
                        className="form-control form-control-user"
                        id="exampleInputPassword"
                        placeholder="Telefono"
                        onChange={handleInputChange3}
                    />
                  </div>
                  <br/>
                  <div className="form-group col-lg-6">
                    <input
                        type="text"
                        className="form-control form-control-user"
                        id="exampleInputEmail"
                        aria-describedby="emailHelp"
                        placeholder="Calle y Numero"
                        onChange={handleInputChange4}
                    />
                  </div>
                  <div className="form-group col-lg-6">
                    <input
                        type="text"
                        className="form-control form-control-user"
                        id="exampleInputPassword"
                        placeholder="Colonia"
                        onChange={handleInputChange5}
                    />
                  </div>
                  <div className="form-group col-lg-6">
                    <input
                        type="text"
                        className="form-control form-control-user"
                        id="exampleInputPassword"
                        placeholder="Muncipio"
                        onChange={handleInputChange6}
                    />
                  </div>
                  <div className="form-group col-lg-6">
                    <input
                        type="text"
                        className="form-control form-control-user"
                        id="exampleInputPassword"
                        placeholder="Estado"
                        onChange={handleInputChange7}
                    />
                  </div>
                  <div className="form-group col-lg-6">
                    <input
                        type="text"
                        className="form-control form-control-user"
                        id="exampleInputPassword"
                        placeholder="Codigo Postal"
                        onChange={handleInputChange8}
                    />
                  </div>
                  <div className="form-group col-lg-6">
                    <input
                        type="number"
                        className="form-control form-control-user"
                        id="exampleInputPassword"
                        placeholder="Cantidad de empleados"
                        onChange={handleInputChange9}
                    />
                  </div>
                  <div className="form-group col-lg-6">
                    <input
                        type="text"
                        className="form-control form-control-user"
                        id="exampleInputPassword"
                        placeholder="RFC"
                        onChange={handleInputChange10}
                    />
                  </div>
                  <div className="form-group col-lg-12 text-center">
                    <button
                        type="submit"
                        className="btn btn-p btn-user btn-sm"
                        style={{border: "#380079"}}
                    >
                      Guardar
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );}
  else {
    return(
      <NotFound />
    )
  }
}

export default AltaEmpresa;
