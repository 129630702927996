import React from "react";
import "../../estilos/css/reporte.css";
import { useParams } from "react-router-dom";
import { useState } from "react";
import axios, { formToJSON } from "axios";
import ClipLoader from "react-spinners/ClipLoader";

function Reporte() {
  let { id } = useParams();

  console.log(id);
  const [loading, setLoading] = useState(true)
  const [html, sethtml] = useState(false)

  const url = "https://ms.bluehand.com.mx/backend/api/v1/api/docglobalv2";
  const [master] = useState(
    "$2a$12$n1ifj4zomb1OS0sn/lW8COTSd5cV7lKGsgcdbC.ZBtVnAFFGt0.ie"
  );
  const [widgets, setWidgets] = useState("");
  const [estilos, setEstilos] = useState("");

  const peticionPost = async () => {
    var f = new FormData();
    debugger;
    f.append("master_key", master);
    f.append("id_empresa", id);
    var dataa = formToJSON(f);
    await axios
      .post(url, dataa)
      .then((response) => {
        debugger
        const data = response.data.data.html;
        const d = response.data.data.style;
        setWidgets(data);
        setEstilos(d);
        setLoading(false)
        sethtml(true)
        //navigate("/Reporte");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  peticionPost();

  return (
    <div>
      <div className="" style={{display : loading ? 'flex' : 'none'}}>
              <ClipLoader
                    
                    size={150}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                    className="mx-auto"
                  />
              </div>
      <p
        dangerouslySetInnerHTML={{ __html: `${widgets}` }}
        className="text-black"
        style={{display : html ? 'flex' : 'none'}}
      ></p>
    </div>
  );
}

export default Reporte;
