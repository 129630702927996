import React, { useState, useEffect, CSSProperties } from "react";
import SidebarE from "../componentes/SidebarE.js";
import TopbarE from "../componentes/TopbarE.js";
import { Link } from "react-router-dom";
import axios, { formToJSON } from "axios";
import DataTable from "react-data-table-component";
import ClipLoader from "react-spinners/ClipLoader";
import NotFound from "../NotFound";
import { useParams } from 'react-router-dom';

function BuzonQuejas() {

  const url = "https://ms.bluehand.com.mx/backend/api/v1/traercuestionario";

  const url2 =
    "https://ms.bluehand.com.mx/backend/api/v1/denuncias";
  const [master] = useState(
    "$2a$12$n1ifj4zomb1OS0sn/lW8COTSd5cV7lKGsgcdbC.ZBtVnAFFGt0.ie"
  );

  const [listas, setLista] = useState([]);
  const [periodos, setPeriodo] = useState([]);
  const [comentario, setComentario] = useState([]);
  const [fechaI, setFechaI] = useState([]);
  const [aiVisible, setAiVisible] = useState(true);
  const [tableEmp, setTablaEmp] = useState(false);
  

  /*const override: CSSProperties = {
    display: "block",
    margin: "10 auto",
    borderColor: "blue",
  };*/

  let l = localStorage;

  //console.log(l.getItem("id_empresa"));

  //const empresa = l.getItem("id_empresa");

  //DE MOMENTO HARDCODEADO PARA QUE NO TARDE TANTO LA INFO Y SEA DE MEDISMART
  const empresa = l.getItem("id_empresa");
  const username = l.getItem("id_usuario");

  const listaPost = async () => {
    await axios
      .get(url)
      .then((response) => {
        const data = response.data;

        setLista(data);
        
        //navigate("/Reporte");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if(listas == ""){
      listaPost();
    }
  }, []);

  const peticionPost = async () => {
    var f = new FormData();
    debugger
    f.append("master_key", master);
    f.append("id_usuario", parseInt(username));
    f.append("id_empresa", parseInt(empresa));
    f.append("texto", comentario);
    var dataa = formToJSON(f);
    
    await axios
      .post(url2, dataa)
      .then((response) => {
        const data = response.data.data;
        const cuest = response.data.cuestionario;
        const r = response.data.result_code;
        
      })
      .catch((error) => {
        console.log(error);
      });
  };

  function handleComentario(event) {
    setComentario(event.target.value);
    console.log(comentario)
  }

  function handleSubmit(event) {
    debugger
    event.preventDefault();
  peticionPost();
  }


  if (l.getItem("id_privilegios") != undefined && l.getItem("id_privilegios") == 6 ) {
  return (
    <div id="wrapper">
      <SidebarE />
      <div id="content-wrapper" className="d-flex flex-column">
        <div id="content">
          <TopbarE />
          <div className="container-fluid">
            <div className="d-sm-flex align-items-center justify-content-between mb-4">
              <Link
                to="/Cuestionarios"
                className="text-white d-sm-inline-block btn btn-sm shadow-sm"
                style={{backgroundColor: '#f05514'}}
              >
                <i className="fas fa-arrow-left fa-sm text-white-50"></i> Regresar
              </Link>
            </div>
            <form className="user" onSubmit={handleSubmit}>
            <div className="card shadow mb-4">
                <div className="card-header py-3">
                <h6 className="m-0 font-weight-bold text-primary">Buzón Anónimo</h6>
              </div>
              <div className="card-body">

                <div className="row">
                  <div className="form-group col-lg-12">
                  <label>Comentario:</label>
                    <textarea style={{height: 160}} placeholder="Agregar Comentario" className="form-control" value={comentario}
                          onChange={handleComentario}></textarea>
                  </div>
                  <br/>
                  <div className="form-group col-lg-12 text-center">
                  <button
                          type="submit"
                          className="text-white btn btn-user"
                          style={{backgroundColor: '#f05514'}}
                        >
                          Enviar
                        </button>
                  </div>
                  
                </div>
                </div>
                </div>
              </form>
            
          </div>
        </div>
      </div>
    </div>
  );
  } else{
    return(
    <NotFound />
    )
  }
}

export default BuzonQuejas;
