import React,{ useState, useEffect } from "react";
import SidebarC from "../componentes/SiderbarC.js";
import TopbarC from "../componentes/TopbarC.js";
import { Link } from "react-router-dom";
import { useParams } from 'react-router-dom';
import axios, {formToJSON} from 'axios';
import DataTable from "react-data-table-component";
import ClipLoader from "react-spinners/ClipLoader";
import NotFound from "../NotFound.js";
import AsignarEncuestaArea from "./AsignarEncuestaArea.js";

function Asignar() {
  let l = localStorage;

  let info = useParams();

  const url = 'https://ms.bluehand.com.mx/backend2/api/v1/api/get-all-areas-by-id';
  const url2 = 'https://ms.bluehand.com.mx/backend/api/v1/api/get-all-positions-by-id';
  const url3 = 'https://ms.bluehand.com.mx/backend2/api/v1/api/get-all-users-by-company';
  const url4 = 'https://ms.bluehand.com.mx/backend2/api/v1/usuarioarea';
  const url5 = 'https://ms.bluehand.com.mx/backend2/api/v1/usuariopuesto';
  const url6 = 'https://ms.bluehand.com.mx/backend2/api/v1/asignarcues';
  const url7 = 'https://ms.bluehand.com.mx/backend2/api/v1/asignarcuesarea';
  const url8 = 'https://ms.bluehand.com.mx/backend2/api/v1/asignarcuespuesto';
  const [master] = useState(
    '$2a$12$n1ifj4zomb1OS0sn/lW8COTSd5cV7lKGsgcdbC.ZBtVnAFFGt0.ie'
  );
  const [widgets, setWidgets] = useState([]);
  const [listas, setListas] = useState([]);
  const [usuarios, setUsuarios] = useState('');
  const [aiVisible, setAiVisible] = useState(false);
  const [tableEmp, setTablaEmp] = useState(false);
  const [tableEmp2, setTablaEmp2] = useState(false);
  const [tableEmp3, setTablaEmp3] = useState(false);
  const [tableEmp4, setTablaEmp4] = useState(false);
  const [aiVisible2, setAiVisible2] = useState(false);
  const [aiVisible3, setAiVisible3] = useState(false);
  const [aiVisible4, setAiVisible4] = useState(false);
  const [aiVisible5, setAiVisible5] = useState(false);

  const [search, SetSearch]= useState('');
  const [filter, setFilter]= useState([]);

  const [search2, SetSearch2]= useState('');
  const [filter2, setFilter2]= useState([]);

  const privilegio = l.getItem("id_privilegios");

  const peticionPost = async () => {
    debugger
    var f = new FormData();
    f.append('master_key', master);
    f.append('id_empresa', info.id);
    var dataa = formToJSON(f);
    await axios
      .post(url, dataa)
      .then((response) => {
        debugger
        const data = response.data.data;
        const r = response.data.result_code;
        //mensaje
        const mess = response.data.message;

        if (r == 1) {
          setWidgets(data);
          setFilter(data);
          setAiVisible(true);
          setAiVisible2(false)
          setTablaEmp(true)
        }
        if (r === 0) {
          alert(mess);
          return;
        }
        //navigate("/Reporte");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const listaPost = async (puesto) => {
    debugger
    var f = new FormData();
    f.append('master_key', master);
    f.append('id_area', puesto);
    var dataa = formToJSON(f);
    await axios
      .post(url2, dataa)
      .then((response) => {
        debugger
        const data = response.data.data;
        const r = response.data.result_code;
        //mensaje
        const mess = response.data.message;

        if (r == 1) {
          setListas(data);
          setFilter2(data);
          setAiVisible3(true);
          setTablaEmp2(true)
        }
        if (r === 0) {
          alert(mess);
          return;
        }
        //navigate("/Reporte");
      })
      .catch((error) => {
        console.log(error);
      });
  };


  const UsuarioPost = async () => {
    debugger
    var f = new FormData();
    f.append('master_key', master);
    f.append('id_empresa', info.id);
    var dataa = formToJSON(f);
    await axios
      .post(url3, dataa)
      .then((response) => {
        debugger
        const data = response.data.data;
        const r = response.data.result_code;
        //mensaje
        const mess = response.data.message;

        if (r == 1) {
          setUsuarios(data);
          setAiVisible4(true);
          setTablaEmp3(true)
        }
        if (r === 0) {
          alert(mess);
          return;
        }
        //navigate("/Reporte");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const AreaPost = async (area) => {
    debugger
    var f = new FormData();
    f.append('master_key', master);
    f.append('id_area_trabajo', area);
    var dataa = formToJSON(f);
    await axios
      .post(url4, dataa)
      .then((response) => {
        debugger
        const data = response.data.data;
        const r = response.data.result_code;
        //mensaje
        const mess = response.data.message;

        if (r == 1) {
          setUsuarios(data);
          setAiVisible5(true);
          setTablaEmp4(true)
        }
        if (r === 0) {
          alert(mess);
          return;
        }
        //navigate("/Reporte");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const PuestoPost = async (p) => {
    debugger
    var f = new FormData();
    f.append('master_key', master);
    f.append('id_puesto', p);
    var dataa = formToJSON(f);
    await axios
      .post(url5, dataa)
      .then((response) => {
        debugger
        const data = response.data.data;
        const r = response.data.result_code;
        //mensaje
        const mess = response.data.message;

        if (r == 1) {
          setUsuarios(data);
          setAiVisible4(true);
          setTablaEmp3(true)
        }
        if (r === 0) {
          alert(mess);
          return;
        }
        //navigate("/Reporte");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const AsignarGeneral = async () => {
    debugger
    var f = new FormData();
    f.append('master_key', master);
    f.append('id_empresa', info.id);
    f.append('id_periodo', info.periodo);
    var dataa = formToJSON(f);
    await axios
      .post(url6, dataa)
      .then((response) => {
        debugger
        const data = response.data.data;
        const r = response.data.result_code;
        //mensaje
        const mess = response.data.message;

        if (r == 1) {
          alert(mess);
        }
        if (r === 0) {
          alert(mess);
          return;
        }
        //navigate("/Reporte");
      })
      .catch((error) => {
        console.log(error);
      });
  };


  const AsignarArea = async (a) => {
    debugger
    var f = new FormData();
    f.append('master_key', master);
    f.append('id_area', a);
    f.append('id_periodo', info.periodo);
    var dataa = formToJSON(f);
    await axios
      .post(url7, dataa)
      .then((response) => {
        debugger
        const data = response.data.data;
        const r = response.data.result_code;
        //mensaje
        const mess = response.data.message;

        if (r == 1) {
          alert(mess);
        }
        if (r === 0) {
          alert(mess);
          return;
        }
        //navigate("/Reporte");
      })
      .catch((error) => {
        console.log(error);
      });
  };


  const AsignarPuesto = async (pu) => {
    debugger
    var f = new FormData();
    f.append('master_key', master);
    f.append('id_puesto', pu);
    f.append('id_periodo', info.periodo);
    var dataa = formToJSON(f);
    await axios
      .post(url8, dataa)
      .then((response) => {
        debugger
        const data = response.data.data;
        const r = response.data.result_code;
        //mensaje
        const mess = response.data.message;

        if (r == 1) {
          alert(mess);
        }
        if (r === 0) {
          alert(mess);
          return;
        }
        //navigate("/Reporte");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(()=>{
    debugger
    const result= widgets.filter((item)=>{
     return item.nombre_area.toLowerCase().match(search.toLocaleLowerCase());
    });
    setFilter(result);
},[search]);


useEffect(()=>{
  const result= listas.filter((item)=>{
   return item.nombre_puesto.toLowerCase().match(search2.toLocaleLowerCase());
  });
  setFilter2(result);
},[search2]);

  console.log(listas)

  const columns = [
    {
      name: "Área",
      grow: 1.5,
      selector: (row) => row.nombre_area,
    },
    {
      name: "Asignar",
      button: true,
      cell: (row) => (
      <>
        <div>
        <button onClick={() => AsignarArea(row.id_area)} style={{backgroundColor: "#7B53E0", border: "#7B53E0"}} className="btn btn-info btn-circle btn-sm">
            <i className="fas fa-file-signature"></i>
          </button>
          </div>
        </>
      ),
    },
    {
        name: "Puestos",
        button: true,
        cell: (row) => (
        <>
            <button onClick={() => listaPost(row.id_area)} style={{backgroundColor: "#d6248c" , border: "#d6248c"}} className="btn btn-secondary btn-circle btn-sm">
              <i className="fas fa-eye"></i>
            </button>
          </>
        ),
      },
      {
        name: "Usuarios",
        button: true,
        cell: (row) => (
        <>
          <button onClick={() => AreaPost(row.id_area)} style={{backgroundColor: "#d6248c" , border: "#d6248c"}} className="btn btn-secondary btn-circle btn-sm">
              <i className="fas fa-eye"></i>
            </button>
          </>
        ),
      },
  ];

  const columns2 = [
    {
      name: "Puesto",
      grow: 1.5,
      selector: (row) => row.nombre_puesto,
    },
    {
      name: "Asignar",
      button: true,
      cell: (row) => (
      <>
        <div>
           <button onClick={() => AsignarPuesto(row.id_puesto)} style={{backgroundColor: "#7B53E0", border: "#7B53E0"}} className="btn btn-info btn-circle btn-sm">
            <i className="fas fa-file-signature"></i>
          </button>
          </div>
        </>
      ),
    },
      {
        name: "Usuarios",
        button: true,
        cell: (row) => (
        <>
            <button onClick={() => PuestoPost(row.id_puesto)} style={{backgroundColor: "#d6248c" , border: "#d6248c"}} className="btn btn-secondary btn-circle btn-sm">
              <i className="fas fa-eye"></i>
            </button>
          </>
        ),
      },
  ];

  const columns3 = [
    {
      name: "CURP",
      grow: 1.5,
      selector: (row) => row.curp,
    },
    {
      name: "Nombre",
      grow: 1.5,
      selector: (row) => <>{row.nombre} {row.apellido_paterno} {row.apellido_materno}</>,
    },
    {
      name: "Asignar",
      button: true,
      cell: (row) => (
      <>
        <div>
            {row.cuestionario_35 == 1 || row.cuestionario_36 == 1 || row.cuestionario_37 == 1
            ? "Cuestionario Asignado" : <Link style={{backgroundColor: "#7B53E0", border: "#7B53E0"}} className="btn btn-info btn-circle btn-sm" to={`/Asignar/${info.id}`}>
            <i className="fas fa-file-signature"></i>
          </Link>}
          </div>
        </>
      ),
    },
  ];

  const customStyles = {
    headCells: {
      style: {
        backgroundColor: "rgba(249, 250, 251, 1)",
        textTransform: "uppercase",
        color: "rgba(107, 114, 128, 1))",
        letterSpacing: "0.05em",
        fontSize: "0.85rem",
        fontWeight: "1000",
        lineHeight: "1rem",
      },
    },
    cells: {
      style: {
        paddingTop: "0.5rem",
        paddingBottom: "0.5rem",
        overflowX: "unset",
      },
    },
    subHeader: {
      style: {
        backgroundColor: "rgba(249, 250, 251, 1)",
        textTransform: "uppercase",
        color: "rgba(107, 114, 128, 1))",
        padding: 16,
      },
    },
  };

  const empresa = l.getItem("id_empresa");
  if (l.getItem("id_privilegios") != undefined && l.getItem("id_privilegios") == 2 || l.getItem("id_privilegios") == 3 ){

  return (
    <div id="wrapper">
      <SidebarC />
      <div id="content-wrapper" className="d-flex flex-column">
        <div id="content">
          <TopbarC />
          <div className="container-fluid">
            <div className="d-sm-flex align-items-center justify-content-between mb-4">
            <Link to={`/MisEmpresas`} className="text-white d-sm-inline-block btn btn-sm shadow-sm" style={{backgroundColor: "#1fad7a"}}>
                  <i className="fas fa-arrow-left fa-sm text-white-50"></i> Regresar
                </Link>

              <Link to={`/MisPeriodos`} className="text-white d-sm-inline-block btn btn-sm shadow-sm" style={{backgroundColor: "#1fad7a"}}>
                <i className="fas fa-plus fa-sm text-white-50"></i> Agregar Periodo
              </Link>
            </div>
            <div className="card shadow mb-4">
                <div className="card-header py-3">
                <h6 className="m-0 font-weight-bold text-primary">Elegir la forma en que se asignarán las encuestas</h6>
              </div>
            <div className="card-body">

                <div className="row">
                  <div className="form-group text-center col-lg-4">
                  <button onClick={AsignarGeneral} className="text-white d-sm-inline-block btn btn-sm shadow-sm" style={{backgroundColor: "#1fad7a"}}>
                <i className="fas fa-plus fa-sm text-white-50"></i> General
              </button>
                  </div>
                  <div className="form-group text-center col-lg-4">
                  <button onClick={peticionPost} className="text-white d-sm-inline-block btn btn-sm shadow-sm" style={{backgroundColor: "#1fad7a"}}>
                <i className="fas fa-plus fa-sm text-white-50"></i> Por Área
              </button>
                  </div>
                  <div className="form-group  text-center col-lg-4">
                  <button onClick={UsuarioPost} className="text-white d-sm-inline-block btn btn-sm shadow-sm" style={{backgroundColor: "#1fad7a"}}>
                <i className="fas fa-plus fa-sm text-white-50"></i> Por Usuario
              </button>
                  </div>

                </div>
                </div>
                </div>
                
                <div className="row">
                <div className="col-lg-6" style={{display : aiVisible ? 'flex' : 'none'}}>
                <div className="card shadow mb-6">
              <div className="card-header py-3">
                <div className="row">
                  <div className="col-sm-6">
                  <h6 className="m-0 font-weight-bold text-primary">Áreas de trabajo</h6>
                  </div>
                  <div className="col-sm-6 text-right">
                  <button style={{backgroundColor: "#7B53E0", border: "#7B53E0"}} className="btn btn-info btn-circle btn-sm" onClick={() => setAiVisible(false)}>X</button>
                  </div>
                </div>
              </div>
              <div className="" style={{display : aiVisible2 ? 'flex' : 'none'}}>
              <ClipLoader
                    
                    size={150}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                    className="mx-auto"
                  />
              </div>
                  
              <div className="card-body" style={{display : tableEmp ? 'flex' : 'none'}}>
              
              
                <div className="table-responsive">
                  <DataTable
                  noDataComponent="Sin Resultados..."
                    responsive
                    striped
                    columns={columns}
                    data={filter}
                    pagination
                    customStyles={customStyles}
                    fixedHeader
                    subHeader
             subHeaderComponent={
                <input type="text"
                className="w-20 form-control"
                placeholder="Buscar..."
                value={ search}
                onChange={(e)=>SetSearch(e.target.value)}
                
                />
             }
             subHeaderAlign="right"
                  />
                </div>
              </div>
            </div>
                </div>


                <div className="col-sm-6" style={{display : aiVisible3 ? 'flex' : 'none'}}>
                <div className="card shadow mb-4">
              <div className="card-header py-3">
                <div className="row">
                  <div className="col-sm-6">
                  <h6 className="m-0 font-weight-bold text-primary">Puestos</h6>
                  </div>
                  <div className="col-sm-6 text-right">
                  <button style={{backgroundColor: "#7B53E0", border: "#7B53E0"}} className="btn btn-info btn-circle btn-sm" onClick={() => setAiVisible3(false)}>X</button>
                  </div>
                </div>
              </div>
              <div className="" style={{display : aiVisible2 ? 'flex' : 'none'}}>
              <ClipLoader
                    
                    size={150}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                    className="mx-auto"
                  />
              </div>
                  
              <div className="card-body" style={{display : tableEmp2 ? 'flex' : 'none'}}>
              
              
                <div className="table-responsive">
                  <DataTable
                  noDataComponent="Sin Resultados..."
                    responsive
                    striped
                    columns={columns2}
                    data={filter2}
                    pagination
                    customStyles={customStyles}
                    subHeader
             subHeaderComponent={
                <input type="text"
                className="w-20 form-control"
                placeholder="Buscar..."
                value={ search2}
                onChange={(e)=>SetSearch2(e.target.value)}
                
                />
             }
             subHeaderAlign="right"
                  />
                </div>
              </div>
            </div>
                </div>


                <div className="card shadow mb-4" style={{display : aiVisible4 ? 'flex' : 'none'}}>
              <div className="card-header py-3">
                <div className="row">
                  <div className="col-sm-6">
                  <h6 className="m-0 font-weight-bold text-primary">Usuarios</h6>
                  </div>
                  <div className="col-sm-6 text-right">
                  <button style={{backgroundColor: "#7B53E0", border: "#7B53E0"}} className="btn btn-info btn-circle btn-sm" onClick={() => setAiVisible4(false)}>X</button>
                  </div>
                </div>
              </div>
              <div className="" style={{display : aiVisible2 ? 'flex' : 'none'}}>
              <ClipLoader
                    
                    size={150}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                    className="mx-auto"
                  />
              </div>
                  
              <div className="card-body" style={{display : tableEmp3 ? 'flex' : 'none'}}>
              
              
                <div className="table-responsive">
                  <DataTable
                  noDataComponent="Sin Resultados..."
                    responsive
                    striped
                    columns={columns3}
                    data={usuarios}
                    pagination
                    customStyles={customStyles}
                  />
                </div>
              </div>
                </div>


                <div className="card shadow mb-4" style={{display : aiVisible5 ? 'flex' : 'none'}}>
              <div className="card-header py-3">
                <div className="row">
                  <div className="col-sm-6">
                  <h6 className="m-0 font-weight-bold text-primary">Usuarios</h6>
                  </div>
                  <div className="col-sm-6 text-right">
                  <button style={{backgroundColor: "#7B53E0", border: "#7B53E0"}} className="btn btn-info btn-circle btn-sm" onClick={() => setAiVisible5(false)}>X</button>
                  </div>
                </div>
              </div>
              <div className="" style={{display : aiVisible2 ? 'flex' : 'none'}}>
              <ClipLoader
                    
                    size={150}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                    className="mx-auto"
                  />
              </div>
                  
              <div className="card-body" style={{display : tableEmp4 ? 'flex' : 'none'}}>
              
              
                <div className="table-responsive">
                  <DataTable
                  noDataComponent="Sin Resultados..."
                    responsive
                    striped
                    columns={columns3}
                    data={usuarios}
                    pagination
                    customStyles={customStyles}
                  />
                </div>
              </div>
                </div>

                </div>
                <br />
          </div>
        </div>
      </div>
    </div>
  );
} else {
  return(
    <NotFound />
  )
}
}

export default Asignar;
