import React, { useState, useEffect } from "react";
import SidebarE from "../componentes/SidebarE";
import { Link } from "react-router-dom";
import TopbarE from "../componentes/TopbarE";
import { useNavigate } from "react-router-dom";
import axios, { formToJSON } from "axios";
import NotFound from "../NotFound";
import carga from "../../estilos/img/a1.jpg"
import transporte from "../../estilos/img/a2.jpg"

function AreasdeEmpresa() {
    let l = localStorage;
  let id_usuario = 653397;
  let id_periodo = 90707;
  let id_puesto = 792;
  let id_empresa = l.getItem("id_empresa");
  const [aiVisible, setAiVisible] = useState(false);
  const [but, setBut] = useState(true);
  const navigate = useNavigate();
  const [username, setUsername] = useState([]);
  const [listas, setListas] = useState([]);
  const [message, setMessage] = useState(false);
  const url = "https://ms.bluehand.com.mx/backend/api/v1/api/get-all-areas-by-id";
  const [master] = useState(
    "$2a$12$n1ifj4zomb1OS0sn/lW8COTSd5cV7lKGsgcdbC.ZBtVnAFFGt0.ie"
  );

  const listaPost = async () => {
    var f = new FormData();
    debugger
    f.append("master_key", master);
    f.append("id_empresa", id_empresa);
    var dataa = formToJSON(f);
    debugger;
    await axios
      .post(url, dataa)
      .then((response) => {
        const data = response.data.data;
        const r = response.data.result_code;

        setListas(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if(listas == ""){
      listaPost();
    }
  }, []);



  if (
    l.getItem("id_privilegios") != undefined &&
    l.getItem("id_privilegios") == 6
  ) {
    return (
      <div id="wrapper">
        <SidebarE />
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <TopbarE />
            <div className="container-fluid">
              <div className="d-sm-flex align-items-center justify-content-between mb-4">
                <h4>Áreas de Trabajo</h4>
              </div>
              <div className="row mx-auto">
              {listas.map((lista) => {
                      return (
                        <>
                        <div className="col-sm-5 card shadow mb-4">
                  <div className="card-header py-3">
                    <h6 className="m-1 font-weight-bold text-primary">
                      {lista.nombre_area}
                      
                    </h6>
                  </div>
                  <div className="text-center card-body">
                    <h1 className="text-center"><i className="fas fa-building"></i></h1>
                  <Link to={`/PuestosdeEmpresa/${lista.id_area}`} className="btn text-white" style={{backgroundColor: '#f05514'}}><i className="fas fa-eye"></i> Gestionar</Link>
                    <div className="text-center">
                  </div>
                  </div>
                </div>
                <div className="col-sm-1"></div>
                </>
                      );
                    })}
                
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return <NotFound />;
  }
}

export default AreasdeEmpresa;
