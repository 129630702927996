import React, {useState} from 'react';
import '../estilos/css/sb-admin-2.css';
import { Link } from 'react-router-dom';
import logo from "../estilos/img/docc.png"
import noAccess from "../estilos/img/no_access.jpg"

function NotFound() {

  const [but, setBut] = useState(true);

  return (
    <div
      className=" col-lg-12"
    >
      <br />
      <br />
      <br />
      <div className="row justify-content-center">
        <div className="col-xl-3 col-lg-6 col-md-9 login">
          <div className="card o-hidden border-0 shadow-lg my-5">
            <div className="card-body p-0">
              <div className="row">
                <div className="col-lg-12">
                  <div className="p-5">
                    <div className="text-center">
                    <div className="text-center">
                      <img width={250} src={logo}/>
                    </div>
                    <div className="text-center">
                      <a className="small" href="register.html">
                        {" "}
                        &nbsp;
                      </a>
                    </div>
                      <h1 className="text-center h4 text-gray-900 mb-4">
                        Sin Autorización
                      </h1>
                    </div>
                    
                   
                    <hr />

                    <img width={250} src={noAccess}/>

                    <br />
                    <br />
                    <Link
                          to="/"
                          className="btn btn-p btn-user btn-block"
                          style={{border: "#380079"}}
                        >
                          Volver a Inicio de Sesión
                        </Link>
                   
                   
                    <div className="text-center">
                      <a className="small" href="forgot-password.html">
                        {" "}
                        &nbsp;
                      </a>
                    </div>
                    <div className="text-center">
                      <a className="small" href="register.html">
                        {" "}
                        &nbsp;
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NotFound