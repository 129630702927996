import React from "react"
import { Link } from "react-router-dom"
import logo from '../../estilos/img/Logo.jpeg'
import { useNavigate } from 'react-router-dom';

function TopbarE() {
    const l = localStorage;
    const navigate = useNavigate();

    const empresa = l.getItem("id_usuario");

    const salir = async () => {
        l.removeItem("id_privilegios");
        l.removeItem("id_empresa");

        l.removeItem("nombre");
        l.removeItem("apellido_materno");
        l.removeItem("apellido_paterno");
        l.removeItem("cuestionario");
        l.removeItem("periodo_inicio");
        l.removeItem("periodo_fin");
        l.removeItem("respuestas");

        navigate('/');
        
    };

    return (
              <div id="content">                
  
                  <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
  
                      <button id="sidebarToggleTop" className="btn btn-link d-md-none rounded-circle mr-3">
                          <i className="fa fa-bars"></i>
                      </button>
  
                      <form
                          className="d-none d-sm-inline-block form-inline mr-auto ml-md-3 my-2 my-md-0 mw-100 navbar-search">
                            <div className="row">
                                <div className="col-sm-2">
                                <img src={logo} width={90} height={60}/>
                                </div>
                            </div>
                      </form>
                      <ul className="navbar-nav ml-auto">
                      <form
                          className="d-none d-sm-inline-block form-inline mr-auto ml-md-3 my-2 my-md-0 mw-100 navbar-search">
                            <div className="row">

                                <div className="col-lg-12 col-m-4">
                                    <br />
                                <p className="text-right">{l.getItem("nombre")} {l.getItem("apellido_paterno")} {l.getItem("apellido_materno")}</p>
                                </div>

                                <div className="col-lg-12 col-m-4">
                                <p className="small text-right">
                                    <Link to={`/PerfilE/${empresa}`}>
                                    <i className="fas fa-user"></i> &nbsp;
                                    Mi Perfil
                                    </Link>
                                    </p>
                                </div>
                            </div>
                      </form>
                          <div className="topbar-divider d-none d-sm-block"></div>
  
                          <li className="nav-item dropdown no-arrow">
                              <br/>
                          <button className="dropdown-item" onClick={salir}>
                                      <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray"></i>
                                      Cerrar Sesión
                              </button>
  
                          </li>
  
                      </ul>
  
                  </nav>
                  </div>
    )
  }
  
  export default TopbarE