import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import medi from "../../estilos/img/medd.png"

function Sidebar() {

    
  return (
    <div>
        <div className="sticky-top bg-gradient-primary">
        <ul className="navbar-nav sidebar sidebar-dark accordion" id="accordionSidebar">

        
            <Link className="sidebar-brand d-flex align-items-center justify-content-center" to="/Home">
                <div className="sidebar-brand-icon">
                    <img width={50} src={medi}/>
                </div>
                <div className="sidebar-brand-text mx-3">wedoctors</div>
            </Link>

            <li className="nav-item active">
                <Link to="/Home" className="nav-link">
                <i className="fas fa-fw fa-tachometer-alt"></i>
                    <span>Dashboard</span>
                </Link>
            </li>

            <div className="sidebar-heading">
                Empresas
            </div>

            <li className="nav-item">
                <Link to="/Empresas" className="nav-link">
                <i className="fas fa-fw fa-cog"></i>
                    <span>Consultar Empresas</span>
                    </Link>
            </li>

            <div className="sidebar-heading">
                Usuarios
            </div>

            <li className="nav-item">
                <Link to="/Usuarios" className="nav-link">
                    <i className="fas fa-fw fa-chart-area"></i>
                    <span>Consultar Usuarios</span></Link>
            </li>

            <div className="sidebar-heading">
                Reportes
            </div>

            <li className="nav-item">
                <Link to="/UltimoReporte" className="nav-link" href="tables.html">
                <i className="fas fa-fw fa-table"></i>
                    <span>Último Generado</span>
                </Link>
            </li>

            <li className="nav-item">
                <Link to="/" className="nav-link" href="tables.html">
                <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2"></i>
                    <span>Cerrar Sesión</span>
                </Link>
            </li>

            

        </ul>
        </div>
        </div>

  )
}

export default Sidebar;