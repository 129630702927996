import React, { useState, useEffect, CSSProperties } from "react";
import Sidebar from "./componentes/Sidebar.js";
import Topbar from "./componentes/Topbar.js";
import { Link } from "react-router-dom";
import axios, { formToJSON } from "axios";
import DataTable from "react-data-table-component";
import ClipLoader from "react-spinners/ClipLoader";
import NotFound from "./NotFound";
import SweetAlert2 from "react-sweetalert2";
import Export from "react-data-table-component";
import { CSVLink } from "react-csv";


function Empresas() {

  /*const override: CSSProperties = {
    display: "block",
    margin: "10 auto",
    borderColor: "blue",
  };*/

  let l = localStorage;
  console.log(l.getItem("email"));
  const url2 =
    "https://ms.bluehand.com.mx/backend/api/v1/api/get-all-companies";
  const [master] = useState(
    "$2a$12$n1ifj4zomb1OS0sn/lW8COTSd5cV7lKGsgcdbC.ZBtVnAFFGt0.ie"
  );
  const [listas, setLista] = useState([]);
  const [aiVisible, setAiVisible] = useState(true);
  const [tableEmp, setTablaEmp] = useState(false);
  const [swalProps, setSwalProps] = useState({});

  const [search, SetSearch]= useState('');
  const [filter, setFilter]= useState([]);

  const listaPost = async () => {
    var f = new FormData();
    f.append("master_key", master);
    var dataa = formToJSON(f);
    await axios
      .post(url2, dataa)
      .then((response) => {
        const data = response.data.data;
        const r = response.data.result_code;
        //mensaje
        const mess = response.data.message;

        if (r === 1) {
          setLista(data);
          setFilter(data)
          setAiVisible(false);
          setTablaEmp(true);
          console.log(listas)
        }
        if (r === 0) {
          alert(mess);
          return;
        }
      })
      .catch((error) => { 
        console.log(error);
      });
  };

  useEffect(() => {
    if(listas == ""){
      listaPost();
    }
  }, []);

  useEffect(()=>{
    const result= listas.filter((item)=>{
     return item.trade_name.toLowerCase().match(search.toLocaleLowerCase());
    });
    setFilter(result);
},[search]);

  function showSwal() {
    setSwalProps({
      show: true,
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      confirmButtonText: "Eliminar",
      title: "Eliminar Empresa",
      text: "¿Está seguro que desea borrar la empresa?",
    });
  }


  const columns = [
    {
      name: "ID",
      selector: (row) => row.id,
      grow: 1,
      center: true
    },
    {
      name: "Nombre Empresa",
      selector: (row) => row.trade_name,
      grow: 1,
      center: true
    },
    {
      name: "Cantidad de Empleados",
      selector: (row) => row.employees,
      sortable: true,
      grow: 1.5,
      center: true
    },
    {
      name: "Teléfono",
      selector: (row) => row.phone,
      grow: 0.8,
      center: true
    },
    {
      name: "Acciones",
      button: true,
      cell: (row) => (
        <>
          <Link to={`/DetalleEmpresa/${row.id}`} className="btn btn-circle btn-sm text-white" style={{backgroundColor: "#0B9DE0"}}>
            <i className="fas fa-pen"></i>
          </Link>
          &nbsp;
          <button style={{backgroundColor: "#f05514",border: "#f05514"}} onClick={showSwal} className="btn btn-danger btn-circle btn-sm">
            <i className="fas fa-trash"></i>
                          </button>
                          
        </>
      ),
    },
    {
      name: "Detalles",
      button: true,
      cell: (row) => (
        <>
          <Link style={{backgroundColor: "#7B53E0", border: "#7B53E0"}} to={`/BuscadorEncuestas/${row.id}`} className="btn btn-info btn-circle btn-sm">
            <i className="fas fa-clipboard"></i>
          </Link>
          &nbsp;
          <Link to={`/UsuariosEmpresa/${row.id}`} className="btn btn-success btn-circle btn-sm">
            <i className="fas fa-user"></i>
          </Link>
          &nbsp;
          <Link to={`/AreasTrabajo/${row.id}`} className="btn btn-secondary btn-circle btn-sm" style={{backgroundColor: "#d6248c" , border: "#d6248c"}}>
            <i className="fas fa-eye"></i>
          </Link>
        </>
      ),
    },
  ];

  const customStyles = {
    headCells: {
      style: {
        backgroundColor: "rgba(249, 250, 251, 1)",
        textTransform: "uppercase",
        color: "rgba(107, 114, 128, 1))",
        letterSpacing: "0.05em",
        fontSize: "0.85rem",
        fontWeight: "1000",
        lineHeight: "1rem",
        padding: "0.5rem"
      },
    },
    cells: {
      style: {
        paddingTop: "0.5rem",
        paddingBottom: "0.5rem",
        overflowX: "unset",
        
      },
    },
  };

  console.log(listas);
  
  if (l.getItem("id_privilegios") != undefined && l.getItem("id_privilegios") == 1 ) {  
  return (
    <div id="wrapper">
      <Sidebar />
      <div id="content-wrapper" className="d-flex flex-column">
        <div id="content">
          <Topbar />
          <div className="container-fluid">
            <div className="d-sm-flex align-items-center justify-content-between mb-4">
              <Link
                to="/AltaEmpresa"
                className="text-white d-sm-inline-block btn btn-sm shadow-sm"
                style={{backgroundColor: "#380079", border: "#380079"}}
              >
                <i className="fas fa-plus fa-sm text-white-50"></i> Agregar
                Empresa
              </Link>
            </div>

            <div className="card shadow mb-4">
              <div className="card-header py-3">
                <div className="row">
                  <div className="col-sm-3">
                  <h6 className="m-0 font-weight-bold text-primary">
                  Listado de Empresas
                </h6>
                  </div>
                  <div className="col-sm-9 text-right">
                  <CSVLink data={listas}><button style={{backgroundColor: "#380079", border: "#380079"}} className="btn btn-primary">Exportar a CSV</button></CSVLink>
                  </div>
                </div>
              </div>
              <div className="" style={{display : aiVisible ? 'flex' : 'none'}}>
              <ClipLoader
                    
                    size={150}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                    className="mx-auto"
                  />
              </div>
              <div className="card-body" style={{display : tableEmp ? 'flex' : 'none'}}>
              
                <div className="table-responsive">
                  <DataTable
                  noDataComponent="Sin Resultados..."
                    responsive
                    striped
                    columns={columns}
                    data={filter}
                    pagination
                    customStyles={customStyles}
                    subHeader
                    subHeaderComponent={
                      <input type="text"
                      className="w-25 form-control"
                      placeholder="Buscar..."
                      value={ search}
                      onChange={(e)=>SetSearch(e.target.value)}
                      
                      />
                    }
                    subHeaderAlign="right"
                    />
                </div>
              </div>
            </div>
            <SweetAlert2 {...swalProps} />
          </div>
        </div>
      </div>
    </div>
  );
  } else{
    return(
    <NotFound />
    )
  }
}

export default Empresas;
